export const lineForPoints = (call?: Record<string, any>, hand?: Record<string, any>): string | undefined => {
    const maxHCP: string | undefined =
        call && 'maxhcp' in call ? `${call['maxhcp']}` : hand && 'rmaxhcp' in hand ? `${hand['rmaxhcp']}` : undefined;
    const minHCP: string | undefined =
        call && 'minhcp' in call ? `${call['minhcp']}` : hand && 'rminhcp' in hand ? `${hand['rminhcp']}` : undefined;

    const maxp: string | undefined =
        call && 'maxp' in call ? `${call['maxp']}` : hand && 'maxhcp' in hand ? `${hand['maxhcp']}` : undefined;
    const minp: string | undefined =
        call && 'minp' in call ? `${call['minp']}` : hand && 'minhcp' in hand ? `${hand['minhcp']}` : undefined;

    const maxTotalHCP: string | undefined =
        call && 'maxtp' in call ? `${call['maxtp']}` : hand && 'tmaxhcp' in hand ? `${hand['tmaxhcp']}` : undefined;
    const minTotalHCP: string | undefined =
        call && 'mintp' in call ? `${call['mintp']}` : hand && 'tminhcp' in hand ? `${hand['tminhcp']}` : undefined;

    let line: string | undefined =
        maxHCP || minHCP ? 'HCP: ' : minp || maxp ? 'Points: ' : minTotalHCP || maxTotalHCP ? 'Total Points: ' : undefined;
    if (!line) {
        return '';
    }
    const minValue: string | undefined = minHCP ?? minp ?? minTotalHCP ?? undefined;
    const maxValue: string | undefined = maxHCP ?? maxp ?? maxTotalHCP ?? undefined;
    if (minValue && maxValue && parseInt(maxValue) < 26 && minValue !== '0') {
        if (minValue === maxValue) {
            line += minValue;
        } else {
            line += minValue + ' - ' + maxValue;
        }
    } else if (minValue && minValue !== '0') {
        line += `${minValue} +`;
    } else if (maxValue && parseInt(maxValue) < 26) {
        line += `${maxValue} -`;
    } else {
        return undefined;
    }
    return line + '</br>';
};

export const lineForMinMaxValue = (
    min: string,
    max: string,
    label: string,
    callObj?: Record<string, any>,
    handObj?: Record<string, any>
): string => {
    let line: string | undefined = callObj && (min in callObj || max in callObj) ? label : undefined;
    if (!line) {
        return '';
    }
    const minValue: string | undefined = callObj && min in callObj ? (callObj[min] as string) : undefined;
    const maxValue: string | undefined = callObj && max in callObj ? (callObj[max] as string) : undefined;
    if (minValue && maxValue) {
        if (minValue === maxValue) {
            line += minValue;
        } else {
            line += minValue + ' - ' + maxValue + '';
        }
    } else if (minValue) {
        line += minValue + ' +';
    } else if (maxValue) {
        line += maxValue + ' -';
    }
    return line + '</br>';
};

export const lineForSimpleString = (property: string, objCall?: Record<string, any>): string => {
    const line: string | undefined = objCall && property in objCall ? (objCall[property] as string) : undefined;
    if (!line) {
        return '';
    }
    return line + '</br>';
};

export const hasPropertyEndingWith = (obj: Record<string, any>, suit: string): boolean => {
    return Object.keys(obj).some(key => key.endsWith(suit));
};

export const lineForSuitEndingWith = (suit: string, callObj?: Record<string, any>, handObj?: Record<string, any>): string | undefined => {
    let line: string | undefined = '';
    const suitFromHand: Record<string, any> | undefined = handObj ? (handObj[suit] as object) : undefined;

    const handMinValue: string | undefined = suitFromHand && 'minl' in suitFromHand ? (suitFromHand['minl'] as string) : undefined;
    const handMaxValue: string | undefined = suitFromHand && 'maxl' in suitFromHand ? (suitFromHand['maxl'] as string) : undefined;

    const minValue: string | undefined = callObj && 'min_' + suit in callObj ? (callObj['min_' + suit] as string) : handMinValue;
    const maxValue: string | undefined = callObj && 'max_' + suit in callObj ? (callObj['max_' + suit] as string) : handMaxValue;
    if (minValue && minValue !== '0' && maxValue && parseInt(maxValue) < 8) {
        if (minValue === maxValue) {
            line += minValue + '&nbsp&nbsp';
        } else {
            line += minValue + ' - ' + maxValue + '&nbsp&nbsp';
        }
    } else if (minValue && minValue !== '0') {
        line += minValue + ' +';
    } else if (maxValue && parseInt(maxValue) < 8) {
        line += maxValue + ' -';
    } else {
        line = undefined;
    }

    if (line) {
        const suits: string = suit === 'club' ? '/C' : suit === 'diamond' ? '/D' : suit === 'heart' ? '/H' : suit === 'spade' ? '/S' : ''; // suit.charAt(0).toUpperCase() + suit.slice(1) + 's: ';
        line = `${suits}: ${line}`;
    } else {
        line = '';
    }

    const stopper: string | undefined =
        callObj && 'stp_' + suit in callObj
            ? (callObj['stp_' + suit] as string)
            : handObj && suit in handObj
            ? (handObj['stp_' + suit] as string)
            : undefined;
    if (stopper) {
        const v: string | undefined =
            stopper === 'nostop'
                ? ' No Stopper'
                : stopper === 'single'
                ? ' Single Stopper'
                : stopper === 'onlysingle'
                ? ' Only Single Stopper'
                : stopper === 'double'
                ? ' Double Stopper'
                : undefined;
        if (v) {
            line += v + '<br>';
        }
    }

    const control: string | undefined =
        callObj && 'ctrl_' + suit in callObj
            ? (callObj['ctrl_' + suit] as string)
            : handObj && suit in handObj
            ? (handObj['ctrl_' + suit] as string)
            : undefined;
    if (control) {
        const v: string | undefined =
            stopper === 'noctrl'
                ? ' No Control'
                : stopper === 'ctrl'
                ? ' Control'
                : stopper === 'secctrl'
                ? ' Second Round Control'
                : stopper === 'firstctrl'
                ? ' First Round Control'
                : undefined;
        if (v) {
            line += v + '&nbsp';
        }
    }

    if (line && line.length > 1) {
        return line + '<br>';
    } else {
        return undefined;
    }
};

export const lineForMinMaxValueFromHand = (obj: Record<string, any>, min: string, max: string): string | undefined => {
    const minValue: string | undefined = min in obj ? (obj[min] as string) : undefined;
    const maxValue: string | undefined = max in obj ? (obj[max] as string) : undefined;
    if (minValue && minValue !== '0' && maxValue && parseInt(maxValue) < 8) {
        if (minValue === maxValue) {
            return minValue + '';
        } else {
            return minValue + ' - ' + maxValue + '';
        }
    } else if (minValue && minValue !== '0') {
        return minValue + ' +';
    } else if (maxValue && parseInt(maxValue) < 8) {
        return maxValue + ' -';
    }
    return undefined;
};
