import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

interface II18nextBackendOptions {
    // path where resources get loaded from, or a function
    // returning a path:
    // function(lngs, namespaces) { return customPath; }
    // the returned path will interpolate lng, ns if provided like giving a static path
    loadPath: string;

    // path to post missing resources
    addPath?: string;

    // your backend server supports multiloading
    // /locales/resources.json?lng=de+en&ns=ns1+ns2
    // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
    // Returned JSON structure in this case is
    // {
    //  lang : {
    //   namespaceA: {},
    //   namespaceB: {},
    //   ...etc
    //  }
    // }
    allowMultiLoading: boolean; // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

    // parse data after it has been fetched
    parse?: (data: string) => string;

    //parse data before it has been sent by addPath
    // types just an assumption
    parsePayload?: (namespace: string, key: string, fallbackValue: string) => { key: string };

    // allow cross domain requests
    crossDomain: boolean;

    // allow credentials on cross domain requests
    withCredentials: boolean;

    // overrideMimeType sets request.overrideMimeType("application/json")
    overrideMimeType: boolean;

    // custom request headers sets request.setRequestHeader(key, value)
    customHeaders?: {
        // authorization: 'foo',
        // ...
    };

    requestOptions: {
        // used for fetch
        mode: 'cors'; // and?
        credentials: 'same-origin'; // and?
        cache: 'default'; // and?
    };

    // define a custom request function
    // can be used to support XDomainRequest in IE 8 and 9
    //
    // 'url' will be passed the value of 'loadPath'
    // 'options' will be this entire options object
    // 'callback' is a function that takes two parameters, 'data' and 'HttpApi'.
    //            'data' should be the key:value translation pairs for the
    //            requested language and namespace, or null in case of an error.
    //            'HttpApi' should be a status object, e.g. { status: 200 }
    // 'payload' will be a key:value object used when saving missing translations
    request?: (
        options: II18nextBackendOptions,
        url: string,
        payload: { [key: string]: string },
        callback: (data: { [key: string]: string } | null, HttpApi: any) => void
    ) => void;

    // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
    queryStringParams?: { [key: string]: string };

    // can be used to reload resources in a specific interval (useful in server environments)
    reloadInterval: boolean;
}

interface II18nextOptions extends InitOptions {
    // backend: II18nextBackendOptions;
}

const i18nextOptions: InitOptions = {
    fallbackLng: 'en',
    debug: true,

    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    },

    backend: {
        loadPath: (lang: string[], ns: string[]) => {
            return `/locales/${lang[0].substr(0, 2)}/{{ns}}.json`;
        }
    }
};

i18next
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(i18nextOptions);

export default i18next;
