import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { SoundFiles } from '../../_basics/audioPlayer';

import { BridgePosition, GamePhase, ICard, ICards, Rank, Suit } from '../../_models/models';
import { PossibleButton } from '../../settings/options';
import { IRootState } from '../../store/_root.reducer';
import {addModal, removeModal, requestLogin, setLoggedIn, setPoll, setSoundPlaying} from '../../store/app.reducer';
import {
    appendAuction,
    closeTrick,
    dealHands,
    displayActiveSeat,
    enableDummy,
    hideButtons,
    makePlay,
    raiseCards,
    setActiveSeat,
    setContract,
    setGamePhase,
    setMyBridgePosition,
    showButtons,
    showTrickHistory,
    unRaiseCards
} from '../../store/game.reducer';
import { makeClaim, requestUndo } from '../../store/output.reducer';
import { getAlert, getCardsOfSeat, getClaimNotificationConfig, getUndoNotificationConfig } from '../helper';
import { enumToArray } from '../initialHelper';

export const shuffleCards = () => {
    const suits = enumToArray(Suit);
    const ranks = enumToArray(Rank);

    const cardinals = [BridgePosition.north, BridgePosition.east, BridgePosition.south, BridgePosition.west];
    const cardinalsLength = cardinals.length;

    const cards: ICards = {};
    const hands: { [bridgePosition: string]: Array<ICard['id']> } = {
        [BridgePosition.north]: [],
        [BridgePosition.east]: [],
        [BridgePosition.south]: [],
        [BridgePosition.west]: []
    };

    suits.forEach(suit => {
        ranks.forEach(rank => {
            let index = Math.floor(Math.random() * Math.floor(cardinals.length));
            // let index = 0;
            const bridgePosition = cardinals[index];
            const cardsOfSeat = getCardsOfSeat(cards, bridgePosition, 'hand', 0).length;
            if (cardsOfSeat === (suits.length * ranks.length) / cardinalsLength - 1) {
                cardinals.splice(index, 1);
            }
            hands[bridgePosition].push(`${suit}.${rank}`);
            cards[`${suit}.${rank}`] = {
                id: `${suit}.${rank}`,
                suit: suit as Suit,
                rank: rank as Rank,
                bridgePosition,
                position: 'hand'
            };
        });
    });

    return hands;
    // return cards;
};

export interface IMockProps extends StateProps, DispatchProps {}

const Mock: FunctionComponent<IMockProps> = props => {
    const { game } = props;
    const { cards } = game;
    const [curr, setCurr] = useState(0);

    const fakeGame = () => {
        props.requestLogin({ user: 'mwoy', token: 'f5d1b6663f7246bb8199df60487abac9', uuid: uuidv4(), rememberMe: false });
        props.setLoggedIn();
        props.dealHands(shuffleCards());
        props.setMyBridgePosition(BridgePosition.south);
        props.setGamePhase(GamePhase.BID);
        props.setActiveSeat(BridgePosition.south);
    };

    const dealCards = () => {
        props.requestLogin({
            token: 'f5d1b6663f7246bb8199df60487abac9',
            rememberMe: false,
            user: 'mwoy',
            uuid: uuidv4()
        });
        props.setLoggedIn();
        props.setMyBridgePosition(BridgePosition.south);
        props.setGamePhase(GamePhase.DEAL);
    };

    const playCard = () => {
        const rotate = [1, 2, 3, 0];
        const positions = [BridgePosition.north, BridgePosition.east, BridgePosition.south, BridgePosition.west];
        const hand = getCardsOfSeat(cards, positions[curr], 'hand', 1);
        props.makePlay(hand[Math.floor(Math.random() * Math.floor(hand.length))].id);
        setCurr(rotate[curr]);
        props.setGamePhase(GamePhase.PLAY);
        props.setContract({
            declarer: BridgePosition.south,
            contract: {
                strain: Suit.spades,
                level: 'one'
            },
            stake: {
                level: 'zero',
                strain: 'double'
            }
        });
        props.setActiveSeat(positions[rotate[curr]]);
        props.displayActiveSeat(positions[rotate[curr]]);
        props.enableDummy(true);
    };

    const makeBid = () => {
        props.appendAuction({
            level: 'one',
            strain: Suit.clubs
        });
    };

    const closeTrick = () => {
        props.closeTrick(BridgePosition.west);
    };

    const notifyClaim = () => {
        props.addModal(getClaimNotificationConfig(BridgePosition.north, 13));
    };

    const notifyUndo = () => {
        props.addModal(getUndoNotificationConfig(BridgePosition.north));
    };

    const alert = () => {
        props.addModal(getAlert([<div>This is a testMessage</div>]));
    };

    const showButton = (type: PossibleButton, show: boolean) => () => {
        if (show) {
            props.showButtons([type]);
        } else {
            props.hideButtons([type]);
        }
    };

    const toggleTrickHistory = () => {
        if (game.showTrickHistory) {
            props.showTrickHistory(false);
        } else {
            props.showTrickHistory(true);
        }
    };

    const playSound = (sound?: keyof SoundFiles) => () => {
        props.setSoundPlaying(sound);
    };

    return (
        <section className="Mock">
            <button onClick={dealCards} type="button">
                Deal Cards
            </button>
            <button onClick={fakeGame} type="button">
                Fake Game
            </button>
            <button onClick={makeBid} type="button">
                make bid
            </button>
            <button onClick={playCard} type="button">
                play Card
            </button>
            <button onClick={closeTrick} type="button">
                close Trick
            </button>
            <button onClick={notifyUndo} type="button">
                Notify Undo
            </button>
            <button onClick={notifyClaim} type="button">
                Notify Claim
            </button>
            <button onClick={alert} type="button">
                Alert
            </button>
            <button onClick={showButton('claim', true)} type="button">
                Show Claim
            </button>
            <button onClick={showButton('claim', false)} type="button">
                Hide Claim
            </button>
            <button onClick={showButton('undo', true)} type="button">
                Show undo
            </button>
            <button onClick={showButton('undo', false)} type="button">
                Hide undo
            </button>
            <button onClick={toggleTrickHistory} type="button">
                trick history
            </button>
            <button onClick={playSound('tournamentStart')} type="button">
                Tournament
            </button>
            <button onClick={playSound('yourTurn')} type="button">
                Turn
            </button>
            <button onClick={playSound()} type="button">
                StopSound
            </button>
            <button onClick={() => props.raiseCards(['spades.king', 'spades.queen'])} type="button">
                Raise SK & SQ
            </button>
            <button onClick={() => props.unRaiseCards(['spades.king'])} type="button">
                UnRaise SK
            </button>
            <button onClick={() => props.unRaiseCards()} type="button">
                UnRaise All
            </button>
            <button onClick={() => props.setPoll({
                id: '1223434541341254',
                question: 'Is this a question or is this a question?',
                answers: ['yes', 'no', 'i don\'t know i don\'t know i don\'t know']
            })} type="button">
                Poll
            </button>
            <button onClick={() => props.setPoll()} type="button">
                Poll
            </button>
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    app,
    game
});

const mapDispatchToProps = {
    setLoggedIn,
    requestLogin,
    dealHands,
    setMyBridgePosition,
    setGamePhase,
    makePlay,
    appendAuction,
    closeTrick,
    addModal,
    removeModal,
    makeClaim,
    requestUndo,
    setContract,
    setActiveSeat,
    displayActiveSeat,
    showButtons,
    hideButtons,
    enableDummy,
    showTrickHistory,
    setSoundPlaying,
    raiseCards,
    unRaiseCards,
    setPoll
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
