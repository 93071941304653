import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { IRootState } from '../../store/_root.reducer';
import { IGameState, setJitsi, setVideo } from '../../store/game.reducer';

import './jitsi.scss';

const Window: any = window;

declare const JitsiMeetExternalAPI: any;

export interface IJitsiProps extends StateProps, DispatchProps {}

const Jitsi: FunctionComponent<IJitsiProps> = props => {
    const { app, game } = props;
    const { settings } = app;
    const { jitsi, video } = game;
    const {
        roomName,
        domain,
        password,
        fullSize,
        breakOut,
        goToBreakout,
        configOverwrite,
        interfaceConfigOverwrite,
        userName,
        jwt
    } = jitsi;
    const [jitsiApi, setJitsiApi] = useState<any>(undefined);
    const [currentRoomName, setCurrentRoomName] = useState<IGameState['jitsi']['roomName']>(undefined);
    const [isTileView, setIsTileView] = useState<any>(undefined);

    const videoContainer = useRef(null);
    let passwordRequired = false;
    useEffect(() => {
        // console.log("CreateJitSi JitSiAPI:");
        // console.log("CreateJitSi domain:" + domain);
        // console.log("CreateJitSi roomName:" + roomName);
        if (!jitsiApi && domain && roomName) {
            //  console.log("CreateJitSi justcreated 0");
            createJitsi();
        }
    }, [jitsi]);

    useEffect(() => {
        if (jitsiApi) {
            jitsiApi.isAudioMuted().then((muted: boolean) => {
                if (video.micro === muted) {
                    jitsiApi.executeCommand('toggleAudio');
                }
            });
            jitsiApi.isVideoMuted().then((muted: boolean) => {
                if (video.camera === muted) {
                    jitsiApi.executeCommand('toggleVideo');
                }
            });
        }
    }, [video]);

    const createJitsi = () => {
        console.log('SharkJitsi Starting New JitSi API ', roomName);
        const options = {
            roomName,
            userInfo: {
                displayName: userName
            },
            width: '100%',
            height: '100%',
            parentNode: videoContainer.current,
            interfaceConfigOverwrite: {
                OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron', 'safari'],
                MOBILE_APP_PROMO: false,
                DISPLAY_WELCOME_FOOTER: false,
                SHOW_CHROME_EXTENSION_BANNER: false,
                SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                RECENT_LIST_ENABLED: false,
                DISPLAY_WELCOME_PAGE_CONTENT: false,
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                DISABLE_RINGING: true,
                ENABLE_DIAL_OUT: false,
                //filmStripOnly: false,
                HIDE_INVITE_MORE_HEADER: true,
                //VERTICAL_FILMSTRIP: true,
                HIDE_KICK_BUTTON_FOR_GUESTS: true,
                ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 4,
                ENABLE_FEEDBACK_ANIMATION: true,
                OPEN_IN_MOBILE_BROWSER: true,
                LANG_DETECTION: false,
                TILE_VIEW_MAX_COLUMNS: 3,
                LOCAL_THUMBNAIL_RATIO: 1,
                makeJsonParserHappy: 'even if last key had a trailing comma',
                ...(interfaceConfigOverwrite || {})
            },
            configOverwrite: {
                prejoinConfig: {
                    //     // When 'true', it shows an intermediate page before joining, where the user can configure their devices.
                    //     // This replaces `prejoinPageEnabled`.
                    enabled: true,
                    //     // List of buttons to hide from the extra join options dropdown.
                    //     hideExtraJoinButtons: ['no-audio', 'by-phone']
                    hideDisplayName: true
                },
                flags: {
                    receiveMultipleVideoStreams: true
                },
                securityUi: {
                    // Hides the lobby button. Replaces `hideLobbyButton`.
                    hideLobbyButton: true,
                    // Hides the possibility to set and enter a lobby password.
                    disableLobbyPassword: true
                },
                requireDisplayName: false,
                startAudioOnly: false,
                prejoinPageEnabled: false,
                enableWelcomePage: false,
                enableClosePage: false,
                enableCalendarIntegration: false,
                enableInsecureRoomNameWarning: false,
                doNotStoreRoom: true,
                disableDeepLinking: true,
                enableLayerSuspension: false,
                analytics: { disabled: true, rtcstatsEnabled: false },
                e2eping: { pingInterval: 0, analyticsInterval: 0 },
                disableInviteFunctions: true,
                startScreenSharing: false,
                startWithAudioMuted: true,
                startWithVideoMuted: true,
                enableNoAudioDetection: false,
                enableNoisyMicDetection: false,
                apiLogLevels: ['error'],
                //channelLastN: -1,
                disableAudioLevels: true,
                disableResponsiveTiles: false,
                disableTileEnlargement: false,
                disableSelfView: false,
                disableSelfViewSettings: false,
                disabledSounds: [
                    'ASKED_TO_UNMUTE_SOUND',
                    'E2EE_OFF_SOUND',
                    'E2EE_ON_SOUND',
                    'INCOMING_MSG_SOUND',
                    'KNOCKING_PARTICIPANT_SOUND',
                    'LIVE_STREAMING_OFF_SOUND',
                    'LIVE_STREAMING_ON_SOUND',
                    'NO_AUDIO_SIGNAL_SOUND',
                    'NOISY_AUDIO_INPUT_SOUND',
                    'OUTGOING_CALL_EXPIRED_SOUND',
                    'OUTGOING_CALL_REJECTED_SOUND',
                    'OUTGOING_CALL_RINGING_SOUND',
                    'OUTGOING_CALL_START_SOUND',
                    'PARTICIPANT_JOINED_SOUND',
                    'PARTICIPANT_LEFT_SOUND',
                    'RAISE_HAND_SOUND',
                    'REACTION_SOUND',
                    'RECORDING_OFF_SOUND',
                    'RECORDING_ON_SOUND',
                    'TALK_WHILE_MUTED_SOUND'
                ],
                gatherStats: false,
                //New from Oct 2022
                enableUnifiedOnChrome: true,
                //resolution: 180,
                speakerStats: {
                    // Specifies whether the speaker stats is enable or not.
                    disabled: true,
                    // Specifies whether there will be a search field in speaker stats or not.
                    disableSearch: true
                },
                //hideLobbyButton: true,

                //Tile view related config options.
                tileView: {
                    // The optimal number of tiles that are going to be shown in tile view. Depending on the screen size it may
                    // not be possible to show the exact number of participants specified here.
                    numberOfVisibleTiles: 4
                },

                //end new from oct 2022

                callStatsConfigParams: {
                    disableBeforeUnloadHandler: true, // disables callstats.js's window.onbeforeunload parameter.
                    //     applicationVersion: "app_version", // Application version specified by the developer.
                    disablePrecalltest: true, // disables the pre-call test, it is enabled by default.
                    //     siteID: "siteID", // The name/ID of the site/campus from where the call/pre-call test is made.
                    //     additionalIDs: { // additionalIDs object, contains application related IDs.
                    //         customerID: "Customer Identifier. Example, walmart.",
                    //         tenantID: "Tenant Identifier. Example, monster.",
                    //         productName: "Product Name. Example, Jitsi.",
                    //         meetingsName: "Meeting Name. Example, Jitsi loves callstats.",
                    //         serverName: "Server/MiddleBox Name. Example, jvb-prod-us-east-mlkncws12.",
                    //         pbxID: "PBX Identifier. Example, walmart.",
                    //         pbxExtensionID: "PBX Extension Identifier. Example, 5625.",
                    //         fqExtensionID: "Fully qualified Extension Identifier. Example, +71 (US) +5625.",
                    //         sessionID: "Session Identifier. Example, session-12-34"
                    //     },
                    collectLegacyStats: false, //enables the collection of legacy stats in chrome browser
                    collectIP: false //enables the collection localIP address
                },
                localRecording: { enabled: false },
                toolbarButtons: [
                    'camera',
                    //'chat',
                    //    'closedcaptions',
                    //    'desktop',
                    //    'download',
                    //    'embedmeeting',
                    //    'etherpad',
                    //    'feedback',
                    //    'filmstrip',
                    'fullscreen',
                    //    'hangup',
                    //    'help',
                    //'invite',
                    //    'livestreaming',
                    'microphone',
                    //    'mute-everyone',
                    //    'mute-video-everyone',
                    //'participants-pane',
                    //'profile',
                    'raisehand'
                    //    'recording',
                    //    'security',
                    //    'select-background',
                    //'settings'
                    //    'shareaudio',
                    //    'sharedvideo',
                    //    'shortcuts',
                    //    'stats',
                    //    'tileview',
                    //    'toggle-camera'
                    //    'videoquality',
                    //    '__end'
                ],
                toolbarConfig: {
                    //     // Moved from interfaceConfig.INITIAL_TOOLBAR_TIMEOUT
                    //     // The initial numer of miliseconds for the toolbar buttons to be visible on screen.
                    initialTimeout: 0,
                    //     // Moved from interfaceConfig.TOOLBAR_TIMEOUT
                    //     // Number of miliseconds for the toolbar buttons to be visible on screen.
                    timeout: 3000,
                    //     // Moved from interfaceConfig.TOOLBAR_ALWAYS_VISIBLE
                    //     // Whether toolbar should be always visible or should hide after x miliseconds.
                    alwaysVisible: false
                },
                disableTileView: true,
                useNewBandwidthAllocationStrategy: true,
                // Hides the conference subject
                hideConferenceSubject: true,
                // Hides the conference timer.
                hideConferenceTimer: true,
                // Hides the participants stats
                hideParticipantsStats: true,
                backgroundAlpha: 0,
                remoteVideoMenu: {
                    //     // If set to true the 'Kick out' button will be disabled.
                    disableKick: true,
                    disableGrantModerator: true
                },
                disableRemoteMute: true,
                maxFullResolutionParticipants: 5,
                disableThirdPartyRequests: true,
                disableRemoteControl: true,
                hideDisplayName: false,
                disableProfile: true,
                notifications: [
                    'connection.CONNFAIL', // shown when the connection fails,
                    //     'dialog.cameraNotSendingData', // shown when there's no feed from user's camera
                    //     'dialog.kickTitle', // shown when user has been kicked
                    //     'dialog.liveStreaming', // livestreaming notifications (pending, on, off, limits)
                    //     'dialog.lockTitle', // shown when setting conference password fails
                    //     'dialog.maxUsersLimitReached', // shown when maximmum users limit has been reached
                    //     'dialog.micNotSendingData', // shown when user's mic is not sending any audio
                    //     'dialog.passwordNotSupportedTitle', // shown when setting conference password fails due to password format
                    //     'dialog.recording', // recording notifications (pending, on, off, limits)
                    //     'dialog.remoteControlTitle', // remote control notifications (allowed, denied, start, stop, error)
                    //     'dialog.reservationError',
                    //     'dialog.serviceUnavailable', // shown when server is not reachable
                    //     'dialog.sessTerminated', // shown when there is a failed conference session
                    //     'dialog.sessionRestarted', // show when a client reload is initiated because of bridge migration
                    //     'dialog.tokenAuthFailed', // show when an invalid jwt is used
                    //     'dialog.transcribing', // transcribing notifications (pending, off)
                    //     'dialOut.statusMessage', // shown when dial out status is updated.
                    //     'liveStreaming.busy', // shown when livestreaming service is busy
                    //     'liveStreaming.failedToStart', // shown when livestreaming fails to start
                    //     'liveStreaming.unavailableTitle', // shown when livestreaming service is not reachable
                    //     'lobby.joinRejectedMessage', // shown when while in a lobby, user's request to join is rejected
                    //     'lobby.notificationTitle', // shown when lobby is toggled and when join requests are allowed / denied
                    //     'localRecording.localRecording', // shown when a local recording is started
                    //     'notify.chatMessages', // shown when receiving chat messages while the chat window is closed
                    //     'notify.disconnected', // shown when a participant has left
                    //     'notify.connectedOneMember', // show when a participant joined
                    //     'notify.connectedTwoMembers', // show when two participants joined simultaneously
                    //     'notify.connectedThreePlusMembers', // show when more than 2 participants joined simultaneously
                    //     'notify.leftOneMember', // show when a participant left
                    //     'notify.leftTwoMembers', // show when two participants left simultaneously
                    //     'notify.leftThreePlusMembers', // show when more than 2 participants left simultaneously
                    //     'notify.grantedTo', // shown when moderator rights were granted to a participant
                    //     'notify.invitedOneMember', // shown when 1 participant has been invited
                    //     'notify.invitedThreePlusMembers', // shown when 3+ participants have been invited
                    //     'notify.invitedTwoMembers', // shown when 2 participants have been invited
                    //     'notify.kickParticipant', // shown when a participant is kicked
                    //     'notify.moderationStartedTitle', // shown when AV moderation is activated
                    //     'notify.moderationStoppedTitle', // shown when AV moderation is deactivated
                    'notify.moderationInEffectTitle' // shown when user attempts to unmute audio during AV moderation
                    //     'notify.moderationInEffectVideoTitle', // shown when user attempts to enable video during AV moderation
                    //     'notify.moderationInEffectCSTitle', // shown when user attempts to share content during AV moderation
                    //     'notify.mutedRemotelyTitle', // shown when user is muted by a remote party
                    //     'notify.mutedTitle', // shown when user has been muted upon joining,
                    //     'notify.newDeviceAudioTitle', // prompts the user to use a newly detected audio device
                    //     'notify.newDeviceCameraTitle', // prompts the user to use a newly detected camera
                    //     'notify.participantWantsToJoin', // shown when lobby is enabled and participant requests to join meeting
                    //     'notify.passwordRemovedRemotely', // shown when a password has been removed remotely
                    //     'notify.passwordSetRemotely', // shown when a password has been set remotely
                    //     'notify.raisedHand', // shown when a partcipant used raise hand,
                    //     'notify.startSilentTitle', // shown when user joined with no audio
                    //     'notify.unmute', // shown to moderator when user raises hand during AV moderation
                    //     'prejoin.errorDialOut',
                    //     'prejoin.errorDialOutDisconnected',
                    //     'prejoin.errorDialOutFailed',
                    //     'prejoin.errorDialOutStatus',
                    //     'prejoin.errorStatusCode',
                    //     'prejoin.errorValidation',
                    //     'recording.busy', // shown when recording service is busy
                    //     'recording.failedToStart', // shown when recording fails to start
                    //     'recording.unavailableTitle', // shown when recording service is not reachable
                    //     'toolbar.noAudioSignalTitle', // shown when a broken mic is detected
                    //     'toolbar.noisyAudioInputTitle', // shown when noise is detected for the current microphone
                    //     'toolbar.talkWhileMutedPopup', // shown when user tries to speak while muted
                    //     'transcribing.failedToStart' // shown when transcribing fails to start
                ],
                ...(configOverwrite || {})
            }
        };

        if (jwt !== null) {
            // @ts-ignore
            options.jwt = jwt;
        }

        /*
    * configOverwrite: {
          prejoinPageEnabled: false,
          requireDisplayName: false,
          startWithVideoMuted: false,
          startAudioMuted: 1,
          enableLayerSuspension: false,
          startScreenSharing: false,
          followMe:true,
          channelLastN: -1
      }
    * */

        Window.jitsiApi = new JitsiMeetExternalAPI(domain, options);
        // api.executeCommand('displayName', jitsi.userName || app.user);
        //api.executeCommand('displayName', app.user);
        //allow="autoplay"
        //Window.jitsiApi.getiF

        Window.jitsiApi.on('passwordRequired', function () {
            //       console.log('SharkJitsi passwordRequired');
            passwordRequired = true;
            Window.jitsiApi.executeCommand('password', password);
        });

        Window.jitsiApi.addEventListener('videoConferenceLeft', () => {
            // Window.jitsiApi.dispose();
        });

        Window.jitsiApi.addEventListener('videoConferenceJoined', (e: any) => {
            try {
                console.log('SharkJitsi videoConferenceJoined', e);
                if (!e.breakoutRoom && jwt && goToBreakout && breakOut) {
                    console.log('SharkJitsi videoConferenceJoined', jwt);
                    console.log('SharkJitsi videoConferenceJoined', goToBreakout, breakOut);
                    // @ts-ignore
                    Window.jitsiApi.listBreakoutRooms().then(breakoutRooms => {
                        const isFound = Object.values(breakoutRooms).some(element => {
                            // @ts-ignore
                            if (element.name === breakOut) {
                                // @ts-ignore
                                Window.jitsiApi.executeCommand('joinBreakoutRoom', element.jid);
                                return true;
                            }
                        });
                        if (!isFound) {
                            console.log('SharkJitsi Add breakout', breakOut);
                            Window.jitsiApi.executeCommands({
                                addBreakoutRoom: breakOut
                            });
                            // @ts-ignore
                            setTimeout(function () {
                                // @ts-ignore
                                Window.jitsiApi.listBreakoutRooms().then(newBreakoutRooms => {
                                    console.log('SharkJitsi Breakout rooms', newBreakoutRooms);
                                    const isFound = Object.values(newBreakoutRooms).some(element => {
                                        // @ts-ignore
                                        if (element.name === breakOut) {
                                            // @ts-ignore
                                            console.log('SharkJitsi joinBreakoutRoom', element.jid);
                                            // @ts-ignore
                                            Window.jitsiApi.executeCommand('joinBreakoutRoom', element.jid);
                                            return true;
                                        }
                                    });
                                });
                            }, 5000);
                        }
                    });
                }
            } catch (e) {}
        });
        Window.jitsiApi.addListener('incomingMessage', (e: any) => {
            return;
        });
        Window.jitsiApi.addListener('contentSharingParticipantsChanged', (e: any) => {
            //console.log('SharkJitsi Sharing changed', e);
            if (e.data !== undefined && e.data.length > 0) {
                jitsi.fullSize = true;
                props.setJitsi(jitsi);
                Window.jitsiApi.executeCommand('toggleChat');
                Window.jitsiApi.executeCommand('setLargeVideoParticipant', e.data[0]);
                setIsTileView(false);
                Window.jitsiApi.executeCommand('toggleTileView');
                return;
            }
            jitsi.fullSize = false;
            props.setJitsi(jitsi);
            Window.jitsiApi.executeCommand('toggleChat');
            return;
        });
        Window.jitsiApi.addEventListener('audioMuteStatusChanged', ({ muted }: { muted: boolean }) => {
            //console.log('SharkJitsi mic changed : ', muted);
            props.setVideo({
                micro: !muted
            });
        });
        Window.jitsiApi.addEventListener('audioAvailabilityChanged', ({ available }: { available: boolean }) => {
            //console.log('SharkJitsi audioAvailabilityChanged : ', available);
            /*Window.jitsiApi.getAvailableDevices().then((devices: any) => {
                console.log('SharkJitsi audioAvailabilityChanged Available Devices: ', devices);
            });

            Window.jitsiApi.getCurrentDevices().then((devices: any) => {
                console.log('SharkJitsi audioAvailabilityChanged Current Devices: ', devices);
            });*/
        });

        Window.jitsiApi.addEventListener('videoMuteStatusChanged', ({ muted }: { muted: boolean }) => {
            props.setVideo({
                camera: !muted
            });
        });
        Window.jitsiApi.addEventListener('tileViewChanged', ({ enabled }: { enabled: boolean }) => {
            if (jitsi.fullSize && !enabled) return;
            if (jitsi.fullSize && enabled) {
                setIsTileView(true);
                Window.jitsiApi.executeCommand('toggleTileView');
                return;
            }
            setIsTileView(enabled);
        });
        Window.jitsiApi.addEventListener('chatUpdated', ({ isOpen, unreadCount }: { isOpen: boolean; unreadCount: number }) => {
            if (jitsi.fullSize && isOpen) return;
            if (!jitsi.fullSize && !isOpen) return;
            Window.jitsiApi.executeCommand('toggleChat');
        });
        Window.jitsiApi.addEventListener('filmstripDisplayChanged', ({ visible }: { visible: boolean }) => {
            if (visible) Window.jitsiApi.executeCommand('toggleFilmStrip');
        });

        Window.jitsiApi.addEventListener('dominantSpeakerChanged', ({ id }: { id: string }) => {
            if (!jitsi.fullSize && id !== undefined) Window.jitsiApi.executeCommand('setLargeVideoParticipant', id);
            Window.jitsiApi.executeCommand('setNoiseSuppressionEnabled', {
                enabled: true // Enable or disable noise suppression.
            });
        });

        Window.jitsiApi.addEventListener('readyToClose', () => {
            //console.log('CreateJitSi justcreated 3');
            //Window.jitsiApi.dispose();
        });

        Window.jitsiApi.addEventListener('dataChannelOpened', () => {
            console.log('SharkJitsi dataChannelOpened');
        });

        setJitsiApi(Window.jitsiApi);
        setCurrentRoomName(roomName);
    };

    const toggleTileView = () => {
        jitsiApi.executeCommand('toggleTileView');
    };

    const classes = ['Jitsi', fullSize ? 'fullSize' : '', !jitsi.roomName || !jitsi.domain || !settings.showVideo ? 'hidden' : ''];

    return (
        <section className={classes.join(' ')}>
            <div className="innerJitsi" ref={videoContainer} />
            {!isTileView && <button className="toggleTileView" onClick={toggleTileView} />}
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {
    setJitsi,
    setVideo
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Jitsi);
