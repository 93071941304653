import React from 'react';
import { connect } from 'react-redux';
import {
    ExtensionRequest,
    LoginRequest,
    LogoutRequest,
    PublicMessageRequest,
    SFSArray,
    SFSEvent,
    SFSObject,
    SFSRoom,
    SFSUser,
    SmartFox
} from 'sfs2x-api';
import {
    BoardStatKeys,
    BoardStats,
    BridgePosition,
    ChatEntry,
    GamePhase,
    IBid,
    ICard,
    IGameResults,
    IGameResultsPair,
    IGameResultsV2,
    IModal,
    levels,
    strains
} from '../app/_models/models';
import {
    getAlert,
    getClaimNotificationConfig,
    getConnectionLostConfig,
    getDeclarerBridgePosition,
    getDummyBridgePosition,
    getGameResultBlockingModalConfig,
    getGameResultModalConfig,
    getInvalidLoginConfig,
    getMySeatData,
    getPartnerBridgePosition,
    getSocketErrorConfig,
    getSplash,
    getUndoNotificationConfig,
    insertSuits
} from '../app/_utils/helper';
import BoardStatsComponent from '../app/_elements/board-stats/board-stats';

import { IRootState } from '../app/store/_root.reducer';
import {
    addModal,
    removeCurrentModal,
    removeModal,
    requestLogin,
    resetApp,
    setConnectionStatus,
    setLoggedIn,
    setLogInError,
    setPoll,
    setSoundPlaying,
    unsetF2F
} from '../app/store/app.reducer';
import {
    addChatEntry,
    appendAuction,
    blockGameByTeacher,
    clearChat,
    clearMyHighlightedCards, clearSystemChat,
    closeTrick,
    dealHands,
    displayActiveSeat,
    enableDummy,
    forceHideMe,
    ForeignBoardReviewData,
    hideButtons,
    injectTrickHistory,
    makePlay,
    raiseCards,
    resetGame,
    resetGameExcept,
    resetGamePartials,
    setActiveSeat,
    setAuction,
    setBoard,
    setBoardStats,
    setContract,
    setDealer,
    setDoubleValid,
    setDummyCards,
    setEnabledBiddingBox,
    setForeignBoardReviewData,
    setForeignBoardReviewPlayerData,
    setGamePhase,
    setGameResults,
    setGameResultsV2,
    setIframe,
    setIsHighlighted,
    setIsVisible,
    setJitsi,
    setJitsiFullSize,
    setMetaData,
    setMyBoardStatKeys,
    setMyBridgePosition,
    setPlayerHCP,
    setPlayerNames,
    setTableId,
    setTimeToGameEnd,
    setTimeToTournament,
    setTrickCount,
    setTwitch,
    setVulnerability,
    setWinners,
    showAuctionBox,
    showButtons,
    showChatSendForm,
    showClosedTricks,
    showInvites,
    showTrickHistory,
    unRaiseCards,
} from '../app/store/game.reducer';
import { IOutputState, resetOutput } from '../app/store/output.reducer';

import {
    convertBidToInt,
    convertBridgePositionToInt,
    convertBridgePositionToStr,
    convertDummyToDeclarer,
    convertIntToBid,
    convertIntToBridgePosition,
    convertIntToSuitStr,
    convertIntToVulnerabilty,
    convertPlayerToBridgePosition,
    convertPlayerToIntBridgePosition,
    convertStringToBid,
    convertSuitRank,
    convertToPbn,
    sfsInt
} from './game-engine-helper';
import { GAME_STATE, MEETING, MeetingService, MeetingState, netMID, SFSVAR } from './sfsVar';
import moment from 'moment';
import { Translate } from '../app/_basics/translate';
import BoardReview from '../app/_elements/board-review/board-review';
import store from '../app/store/store';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { convertS3toForeignBoardReviewData, IS3BoardData } from './s3-board-map';
import { lineForMinMaxValue, lineForPoints, lineForSimpleString, lineForSuitEndingWith } from './handle-bid-explanation';

//import {types} from "util";

export interface IGameEngineInterfaceProps extends StateProps, DispatchProps {}

export interface IGameEngineInterfaceState {
    processingLogin: Boolean;
    currentPlayerOnServer: number;
    lag: number;
    myposition: BridgePosition;
    visibility: number;
    isDummy: boolean;
    myLobby: SFSRoom;
    competemode: boolean;
    jitsiconf?: {
        domain?: string;
        password?: string;
        roomName?: string;
        userName?: string;
        fullSize?: boolean;
        interfaceConfigOverwrite: any;
        configOverwrite: object;
        breakOut?: string;
        jwt?: string;
        goToBreakout: boolean;
    };
    twitchconf?: {
        channel?: string;
        configOverwrite?: any;
        fullSize?: boolean;
        muted?: boolean;
    };
    farewellMessage?: string;
    meetingState?: MeetingState;
    userActed: boolean;
    freenav: boolean;
    boardData: {
        [key: string]: IS3BoardData;
    };
    hasNext: boolean;
}

export class GameEngineInterface extends React.Component<IGameEngineInterfaceProps, IGameEngineInterfaceState> {
    public state: IGameEngineInterfaceState = {
        processingLogin: false,
        currentPlayerOnServer: 0,
        lag: 10,
        myposition: BridgePosition.south,
        visibility: 1,
        isDummy: false,
        jitsiconf: undefined,
        twitchconf: undefined,
        myLobby: new SFSRoom(),
        competemode: false,
        meetingState: MeetingState.NONE,
        farewellMessage: undefined, // 'This was the last hand in this set!'
        userActed: false,
        freenav: false,
        boardData: {},
        hasNext: false
    };
    private consolOut: boolean = false;
    private sfs: any = undefined;

    public componentDidMount(): void {}

    public componentDidUpdate(
        prevProps: Readonly<IGameEngineInterfaceProps>,
        prevState: Readonly<IGameEngineInterfaceState>,
        snapshot?: any
    ): void {
        const { app, output } = this.props;
        const { user, token, uuid, requestLogin, requestLogout, connection, partner } = app;
        const { currentPlayerOnServer, visibility } = this.state;

        // LOGIN
        if (!prevProps.app.requestLogin && requestLogin) {
            this.props.resetOutput();
            if (!connection.established && !connection.requested) {
                this.props.setConnectionStatus({
                    established: false,
                    requested: true
                });
                if (app.urlParams['sfshost'] !== undefined) {
                    this.configueSFS();
                    this.sfs.connect();
                } else {
                    this.showModal(
                        getInvalidLoginConfig({
                            cancel: () => {
                                window.location.href =
                                    app?.urlParams['sfshost']?.toLowerCase() === 'local'
                                        ? 'https://local.onlinebridge.club/index.php'
                                        : 'https://thesharkbridgecompany.com';
                            },
                            retry: () => {
                                this.logout();
                            }
                        })
                    );
                }
            } else if (connection.established) {
                //this.clientRequestsLogin();
            }
        }

        // LOGOUT
        else if (!prevProps.app.requestLogout && requestLogout) {
            this.clientRequestsLogout();
        }
        // MAKE CALL
        else if (prevProps.output.makeCall !== output.makeCall && output.makeCall) {
            this.clientRequestsMakeCall(output.makeCall);
        }
        // MAKE PLAY
        else if (prevProps.output.makePlay !== output.makePlay && output.makePlay) {
            this.clientRequestsMakePlay(output.makePlay);
        }

        // DEAL CARD
        else if (prevProps.output.dealCard !== output.dealCard && output.dealCard) {
            this.clientRequestsDealCard(output.dealCard);
        }

        // Send Chat Messsage
        else if (prevProps.output.chatEntry !== output.chatEntry && output.chatEntry) {
            this.sendChatMessage(output.chatEntry);
        }

        // Request Director
        else if (prevProps.output.director !== output.director && output.director != null) {
            this.clientRequestsDirector(output.director);
        }

        // Request Claim
        else if (prevProps.output.claim !== output.claim && output.claim != null) {
            this.clientRequestsClaim(output.claim);
        }

        // Claim Response
        else if (prevProps.output.approveClaim !== output.approveClaim && output.approveClaim) {
            if (output.approveClaim !== undefined) {
                this.clientResponseClaim(output.approveClaim);
            }
            this.props.resetOutput();
        }

        // Request Undo
        else if (prevProps.output.undo !== output.undo && output.undo) {
            if (output.undo !== undefined) {
                this.clientRequestsUndo();
            }
            this.props.resetOutput();
        }

        // Respond with YES Undo
        else if (prevProps.output.approveUndo !== output.approveUndo && output.approveUndo) {
            if (output.approveUndo !== undefined) {
                this.clientResponseUndo(output.approveUndo);
            }
            this.props.resetOutput();
        } else if (prevProps.output.changePlay !== output.changePlay && output.changePlay === 'startPlay') {
            this.clientRequestsStartPlay();
            this.props.resetOutput();
        } else if (prevProps.output.changePlay !== output.changePlay && output.changePlay === 'stopPlay') {
            this.clientRequestsStopPlay();
            this.props.resetOutput();
        } else if (prevProps.output.changePlay !== output.changePlay && output.changePlay === 'replay') {
            this.clientRequestsReplay();
            this.props.resetOutput();
        } else if (prevProps.output.prevNext !== output.prevNext && output.prevNext === 'next') {
            this.clientRequestsNextBoard();
            this.props.resetOutput();
        } else if (prevProps.output.prevNext !== output.prevNext && output.prevNext === 'prev') {
            this.clientRequestsPreviousBoard();
            this.props.resetOutput();
        } else if (!prevProps.output.pollVote && output.pollVote) {
            this.sendPollResponse(output.pollVote.pollId, output.pollVote.index);
            this.props.resetOutput();
        } else if (!prevProps.output.invite && output.invite) {
            this.sendEmailInvitation(output.invite.email, output.invite.bridgePosition);
            this.props.resetOutput();
        } else if (!prevProps.output.loadBoardReview && output.loadBoardReview) {
            //console.log('Action Request', output.loadBoardReview);
            this.loadBoardReview(
                output.loadBoardReview.game,
                output.loadBoardReview.pair,
                output.loadBoardReview.bn,
                output.loadBoardReview.comparisonUuid
            );
            this.props.resetOutput();
        } else if (!prevProps.output.closeResults && output.closeResults) {
            this.updateBoardData(undefined);
            this.props.resetOutput();
        } else if (prevProps.output.clickedBid === undefined && prevProps.output.clickedBid !== output.clickedBid) {
            //   console.log("Bid Clicked: ",  output.clickedBid as number);
            const bidIndex: number = output.clickedBid as number;
            this.props.resetOutput();
            this.clientRequestBidExplanation(bidIndex);
        }

        // SET ACTIVE SEAT
        else if (prevState.currentPlayerOnServer !== currentPlayerOnServer) {
            this.props.setIsHighlighted(convertIntToBridgePosition(currentPlayerOnServer), true);
            if (this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) <= 0 && visibility !== 31 && this.sfs.mySelf.containsVariable('kbd')) {
                const seatid: number = this.sfs.mySelf.getVariable('kbd').value;
                if (seatid === 4) {
                    this.props.setMyBridgePosition(convertIntToBridgePosition(currentPlayerOnServer));
                }
            }
            this.props.resetOutput();
        }
    }

    public render() {
        return null;
    }

    public onConnection = (evtParams: { success: boolean }) => {
        if (evtParams.success) {
            this.state.processingLogin = false;
            this.props.setConnectionStatus({
                established: true,
                requested: false
            });
            this.clientRequestsLogin();
        }
    };

    private configueSFS() {
        //const bla = JSON.parse(JSON.stringify(this.props.game.foreignBoardReviewData));

        // Use a flag to easily switch cryptography on and off
        const useEncryption = true;
        const { app } = this.props;

        // Create configuration object
        let hostName: String = app.urlParams['sfshost'].toLowerCase();
        const teacherName: String = app.urlParams['sfsteacher'];
        if (teacherName === 'Shark Table' && hostName === 'acbl') {
            hostName = 'sfs';
        }

        const config = {
            host: hostName === 'local' ? 'local.onlinebridge.club' : hostName + '.emabridge.com',
            port: hostName === 'local' ? 8443 : 443,
            useSSL: useEncryption,
            zone: app.urlParams['service']
                ? app.urlParams['service']
                : app.urlParams['jwe']
                ? 'OnlineBridgeClub'
                : app.urlParams['sfsteacher'] === 'bigteamgame'
                ? 'EmaBridge_TheBigTeamGame'
                : 'EmaBridge_Teacher'
        };

        // Create the SmartFox client instance
        this.sfs = new SmartFox(config);
        this.sfs.addEventListener(SFSEvent.SOCKET_ERROR, this.onSocketError, this);
        this.sfs.addEventListener(SFSEvent.CONNECTION, this.onConnection, this);
        this.sfs.addEventListener(SFSEvent.CONNECTION_LOST, this.onConnectionLost, this);
        this.sfs.addEventListener(SFSEvent.ROOM_JOIN, this.onRoomJoined, this);
        this.sfs.addEventListener(SFSEvent.ROOM_JOIN_ERROR, this.onRoomJoinError, this);
        this.sfs.addEventListener(SFSEvent.ROOM_VARIABLES_UPDATE, this.onRoomVarsUpdate, this);
        this.sfs.addEventListener(SFSEvent.USER_ENTER_ROOM, this.onUserEnterRoom, this);
        this.sfs.addEventListener(SFSEvent.USER_EXIT_ROOM, this.onUserExitRoom, this);
        this.sfs.addEventListener(SFSEvent.USER_VARIABLES_UPDATE, this.onUserVarsUpdate, this);
        this.sfs.addEventListener(SFSEvent.SPECTATOR_TO_PLAYER, this.onSpectatorToPlayerSwitch, this);
        this.sfs.addEventListener(SFSEvent.SPECTATOR_TO_PLAYER_ERROR, this.onSpectatorToPlayerSwitchError, this);
        this.sfs.addEventListener(SFSEvent.PLAYER_TO_SPECTATOR, this.onPlayerToSpectatorSwitch, this);
        this.sfs.addEventListener(SFSEvent.PLAYER_TO_SPECTATOR_ERROR, this.onPlayerToSpectatorSwitchError, this);
        this.sfs.addEventListener(SFSEvent.EXTENSION_RESPONSE, this.onExtensionResponse, this);
        this.sfs.addEventListener(SFSEvent.OBJECT_MESSAGE, this.onObjectMessage, this);
        this.sfs.addEventListener(SFSEvent.ADMIN_MESSAGE, this.onAdminMessage, this);
        this.sfs.addEventListener(SFSEvent.MODERATOR_MESSAGE, this.onModeratorMessage, this);
        this.sfs.addEventListener(SFSEvent.PUBLIC_MESSAGE, this.onPublicMessage, this);
        this.sfs.addEventListener(SFSEvent.PRIVATE_MESSAGE, this.onPrivateMessage, this);
        this.sfs.addEventListener(SFSEvent.PING_PONG, this.onPING_PONG, this);
        this.sfs.addEventListener(SFSEvent.LOGOUT, this.onLogout, this);
    }

    private clientRequestsLogin = () => {
        const { app } = this.props;
        if (this.sfs.isLoggedIn) {
            return;
        }
        if (this.state.processingLogin) {
            return;
        }
        this.state.processingLogin = true;
        if (app.urlParams['sfsteacher'] === undefined && app.urlParams['jwe'] === undefined) {
            this.showModal(
                getInvalidLoginConfig({
                    cancel: () => {
                        window.location.href =
                            app.urlParams['sfshost'].toLowerCase() === 'local'
                                ? 'https://local.onlinebridge.club/index.php'
                                : 'https://thesharkbridgecompany.com';
                    },
                    retry: () => {
                        this.logout();
                    }
                })
            );
            return;
        }
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.PROTOCOL_VERSION, 50);
        sfsObj.putUtfString(SFSVAR.TEACHER_NAME, app.urlParams['sfsteacher'] || 'invalidteacher');
        sfsObj.putUtfString(SFSVAR.LOBBY_PASS, app.urlParams['accesscode'] || 'invalidaccesscode');
        sfsObj.putUtfString('jwe', app.urlParams['jwe'] || '');
        sfsObj.putUtfString('stuid', app.uuid || '');
        sfsObj.putUtfString('partner', app.urlParams['pn'] || app.partner || '');
        if (app.tableNumber !== undefined && app.bridgePosition !== undefined) {
            sfsObj.putInt(SFSVAR.GAME_TABLE, app.tableNumber);
            sfsObj.putUtfString(SFSVAR.GAME_SEAT, app.bridgePosition[0]);
            this.props.unsetF2F();
        }
        this.sfs.addEventListener(SFSEvent.LOGIN, this.onLogin, this);
        this.sfs.addEventListener(SFSEvent.LOGIN_ERROR, this.onLoginError, this);

        const zone: string = app.urlParams['service']
            ? app.urlParams['service']
            : app.urlParams['jwe']
            ? 'OnlineBridgeClub'
            : app.urlParams['sfsteacher'] === 'bigteamgame'
            ? 'EmaBridge_TheBigTeamGame'
            : 'EmaBridge_Teacher';
        const loginRequest = new LoginRequest(app.urlParams['nn'] || (app.user as string) || 'nologin', app.apptoken, sfsObj, zone);
        this.sfs.send(loginRequest);
    };

    private onSocketError({ errorMessage }: { errorMessage: string }) {
        if (this.sfs === undefined || this.sfs.isConnected) {
            return;
        }
        const { app } = this.props;
        (window as any).jitsiApi = undefined;
        this.props.setJitsi({});
        this.showModal(
            getSocketErrorConfig({
                cancel: () => {
                    window.location.href =
                        app.urlParams['sfshost'].toLowerCase() === 'local'
                            ? 'https://local.onlinebridge.club/index.php'
                            : 'https://thesharkbridgecompany.com/blogs/students-manual/how-to-practice-with-robots-or-fellow-students-with-shark-bridge-app';
                },
                retry: () => {
                    this.sfs = undefined;
                    this.logout();
                }
            })
        );
        this.state.processingLogin = false;
    }

    private onConnectionLost({ reason }: { reason: string }) {
        const { app } = this.props;
        (window as any).jitsiApi = undefined;
        this.props.setJitsi({});
        if (this.sfs !== undefined) {
            if (reason !== 'manual') {
                this.showModal(
                    getConnectionLostConfig({
                        cancel: () => {
                            window.location.href =
                                app.urlParams['sfshost'].toLowerCase() === 'local'
                                    ? 'https://local.onlinebridge.club/index.php'
                                    : 'https://thesharkbridgecompany.com/blogs/students-manual/how-to-practice-with-robots-or-fellow-students-with-shark-bridge-app';
                        },
                        retry: () => {
                            this.state.processingLogin = false;
                            this.props.resetGame();
                            this.sfs.connect();
                        }
                    })
                );
            } else {
                this.sfs = undefined;
                this.logout();
            }
        }
        this.state.processingLogin = false;
    }

    private logout = () => {
        this.state.processingLogin = false;
        this.props.resetApp();
        this.props.resetGame();
        this.sfs = undefined;
    };

    private onLogin = ({ user, data }: { user: SFSUser; data: SFSObject }) => {
        this.props.setLoggedIn();
        this.state.processingLogin = false;
        const monitor_interval: number = data.getInt(SFSVAR.SFS_LAG_MONITOR);
        this.sfs.enableLagMonitor(true, 10, 1);
        //if (user.containsVariable("pn")) this.props.app.user = user.getVariable("pn").value;
        //else if (user.containsVariable("nn")) this.props.app.user = user.getVariable("nn").value;
        this.props.setPlayerNames({
            [BridgePosition.north]: 'Empty Seat',
            [BridgePosition.east]: 'Empty Seat',
            [BridgePosition.south]: 'Empty Seat',
            [BridgePosition.west]: 'Empty Seat'
        });
        if (data.containsKey('8x8jwt')) {
            this.setState({
                jitsiconf: {
                    jwt: data.getUtfString('8x8jwt'),
                    interfaceConfigOverwrite: {},
                    configOverwrite: {
                        startAudioOnly: false,
                        startWithAudioMuted: false,
                        startSilent: this.props.app.urlParams['silent'] !== undefined || this.props.app.settings.silent8x8
                    },
                    goToBreakout: false
                }
            });
        }
    };

    private onLoginError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        this.state.processingLogin = true;
        this.props.setLoggedIn();
        const { app } = this.props;
        this.sfs = undefined;
        if (errorCode === 3) {
            this.showModal(
                getInvalidLoginConfig({
                    cancel: () => {
                        window.location.href =
                            app.urlParams['sfshost'].toLowerCase() === 'local'
                                ? 'https://local.onlinebridge.club/index.php'
                                : 'https://thesharkbridgecompany.com';
                    },
                    retry: () => {
                        this.logout();
                    }
                })
            );
        } else {
            this.showModal(
                getConnectionLostConfig({
                    cancel: () => {
                        window.location.href =
                            app.urlParams['sfshost'].toLowerCase() === 'local'
                                ? 'https://local.onlinebridge.club/index.php'
                                : 'https://thesharkbridgecompany.com';
                    },
                    retry: () => {
                        this.logout();
                    }
                })
            );
        }
    };

    private clientRequestsLogout = () => {
        this.sfs.send(new LogoutRequest());
    };

    private onLogout = () => {
        this.state.processingLogin = false;
        this.sfs.disconnect();
        this.sfs = undefined;
        // TODO When you reset the App it looses is App.Params and when we try to connect we do not have the correct host
        this.props.resetApp();
        this.props.resetGame();
    };

    private onPING_PONG = ({ lagValue }: { lagValue: number }) => {
        const { app } = this.props;
        if (app.urlParams['service'] === 'OnlineBridgeClub') {
            return;
        }

        //if (!this.sfs.IsConnected) return;
        const diff: number = this.state.lag - lagValue;
        if (this.sfs.lastJoinedRoom != null && (diff > 50 || diff < -50)) {
            this.setState({
                lag: lagValue
            });
            const set: SFSObject = new SFSObject();
            set.putInt(SFSVAR.SFSUSER_LAG, lagValue);
            this.sfs.send(new ExtensionRequest(SFSVAR.EXTENSION_LAG, set));
        } else {
            this.sfs.send(new ExtensionRequest(SFSVAR.EXTENSION_LAG));
        }
    };

    //Add message to the table or lobby chat, pop screen notifications of the message
    private onModeratorMessage = ({ message, sender }: { message: string; sender: SFSUser }) => {
        if (this.consolOut) {
            console.log('The moderator sent the following message: ' + message);
        }
    };

    //This one can have data, regarding request to unblock a user, we will not deal with it now.
    private onAdminMessage = ({ message, sender, data }: { message: any; sender: SFSUser; data: SFSObject }) => {
        const apiCall: boolean = data ? data.containsKey('sendername') : false;
        const isPop: boolean = data ? data.containsKey('popup') : false;
        if (!isPop) {
            const senderName: string = apiCall ? data.getUtfString('sendername') : 'Shark';
            this.props.addChatEntry({ timestamp: moment.utc().format(), sender: senderName, message: message, isSystemMessage: !apiCall });
            return;
        }
    };

    //On a special case where there is data sent back,
    private onPublicMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        this.props.addChatEntry({
            timestamp: moment.utc().format(),
            sender: sender.containsVariable('pn')
                ? sender.getVariable('pn').value
                : sender.containsVariable('nn')
                ? sender.getVariable('nn').value
                : sender.name,
            message: message
        });
        if (message === 'fullscreen') {
            this.props.setJitsiFullSize(true);
        } else if (message === 'normalscreen') {
            this.props.setJitsiFullSize(false);
        }
    };

    private onPrivateMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        this.props.addChatEntry({
            timestamp: moment.utc().format(),
            sender: sender.containsVariable('pn')
                ? sender.getVariable('pn').value
                : sender.containsVariable('nn')
                ? sender.getVariable('nn').value
                : sender.name,
            message: message
        });
        if (this.consolOut) {
            console.log('Received message: ' + message);
        }
    };

    //onRoomJoin , the player receives it's Room PLayerID which is also BridgePosition.
    //
    private onRoomJoined = ({ room }: { room: SFSRoom }) => {
        const { app } = this.props;
        console.log('Joined room: ', room);
        this.props.showChatSendForm(room.getUserList().length > 1);
        switch (room.isGame) {
            case false: {
                this.setState({
                    myLobby: room
                });

                let result_url = undefined;
                // console.log('Game DATA', room.getVariables());
                if (room.containsVariable(SFSVAR.SFSGAME_MODE)) {
                    if (room.getVariable(SFSVAR.SFSGAME_MODE).value === SFSVAR.gameMode.Swissteam) {
                        result_url = room.containsVariable(SFSVAR.SFSGAME_ROOM_RESULTS_URL)
                            ? room.getVariable(SFSVAR.SFSGAME_ROOM_RESULTS_URL).value
                            : undefined;
                    }
                }

                const isOneHumanTourney: boolean =
                    room.containsVariable(SFSVAR.SFSGAME_MODE) &&
                    room.getVariable(SFSVAR.SFSGAME_MODE).value === SFSVAR.gameMode.OneHumanTourney;

                this.props.setMetaData({
                    eventName: room.containsVariable('eventname')
                        ? room.getVariable('eventname').value
                        : room.containsVariable(SFSVAR.SFSGAME_ROOM_DISPLAY)
                        ? room.getVariable(SFSVAR.SFSGAME_ROOM_DISPLAY).value
                        : 'default',
                    hostName: room.containsVariable(SFSVAR.SFSGAME_ROOM_HOST)
                        ? room.getVariable(SFSVAR.SFSGAME_ROOM_HOST).value
                        : room.name,
                    round: room.containsVariable(SFSVAR.SFSGAME_ROOM_ROUND) ? room.getVariable(SFSVAR.SFSGAME_ROOM_ROUND).value : undefined,
                    resultUrl: result_url,
                    welcomeMessage: room.containsVariable(SFSVAR.SFSGAME_ROOM_WELCOME_MESSAGE)
                        ? room.getVariable(SFSVAR.SFSGAME_ROOM_WELCOME_MESSAGE).value
                        : undefined,
                    director: room.containsVariable(SFSVAR.SFSGAME_ROOM_ROUND) ? 'Director' : undefined,
                    isSupervised: !isOneHumanTourney,
                    isBiddingQuiz: false
                });

                if (room.containsVariable(SFSVAR.SFSGAME_ROOM_UTC_STRTIME)) {
                    this.props.setTimeToTournament(room.getVariable(SFSVAR.SFSGAME_ROOM_UTC_STRTIME).value);
                }

                /*
                if (isOneHumanTourney && room.getVariable(SFSVAR.SFSGAME_ROOM_ROUND).value === -1) {
                    const date: Date = new Date(room.getVariable(SFSVAR.SFSGAME_ROOM_UTC_STRTIME).value * 1000);
                    this.props.addModal(getSplash([<div>{format(date, 'MMMM do, yyyy HH:mm') + ' (local time)'}</div>], 'Game starts on'));
                }
*/
                if (this.isPostMortem()) {
                    this.props.setTimeToTournament(undefined);
                }
                this.setState({
                    competemode: room.containsVariable(SFSVAR.SFSGAME_ROOM_ROUND),
                    farewellMessage: room.containsVariable(SFSVAR.SFSGAME_ROOM_FAREWELL_MESSAGE)
                        ? room.getVariable(SFSVAR.SFSGAME_ROOM_FAREWELL_MESSAGE).value
                        : undefined
                });

                //Audio/Video settings for the lobby
                if (room.containsVariable(SFSVAR.RVAR_MEETING_STATE)) {
                    const mstate: string = room.getVariable(SFSVAR.RVAR_MEETING_STATE).value;
                    this.setState({
                        meetingState: mstate as MeetingState
                    });
                } else {
                    this.setState({ meetingState: MeetingState.NONE });
                }

                //Get Lobby Meeting Parameters
                if (room.containsVariable(SFSVAR.RVAR_MEETING_PARAMS)) {
                    const meetingParams: SFSObject = room.getVariable(SFSVAR.RVAR_MEETING_PARAMS).value;
                    const meetingService: MeetingService = meetingParams.getUtfString(MEETING.MeetingService) as MeetingService;
                    switch (meetingService) {
                        case MeetingService.JitSi: {
                            this.setState({
                                jitsiconf: {
                                    ...(this.state.jitsiconf || {}),
                                    domain: meetingParams.getUtfString(MEETING.MeetingHost),
                                    password: meetingParams.getUtfString(MEETING.AccessCode),
                                    roomName: meetingParams.getUtfString(MEETING.RoomChannel),
                                    userName: this.sfs.mySelf.containsVariable('nn')
                                        ? this.sfs.mySelf.getVariable('nn').value
                                        : this.sfs.mySelf.name,
                                    fullSize: meetingParams.getBool(MEETING.FullScreen),
                                    interfaceConfigOverwrite: {},
                                    configOverwrite: {
                                        startAudioOnly: false,
                                        startWithAudioMuted: false,
                                        startSilent: this.props.app.urlParams['silent'] !== undefined || this.props.app.settings.silent8x8
                                    },
                                    goToBreakout: false
                                }
                            });
                            break;
                        }
                        case MeetingService.x8: {
                            this.setState({
                                jitsiconf: {
                                    ...(this.state.jitsiconf || {}),
                                    domain: meetingParams.getUtfString(MEETING.MeetingHost),
                                    password: meetingParams.getUtfString(MEETING.AccessCode),
                                    roomName: meetingParams.getUtfString(MEETING.RoomChannel),
                                    userName: this.sfs.mySelf.containsVariable('nn')
                                        ? this.sfs.mySelf.getVariable('nn').value
                                        : this.sfs.mySelf.name,
                                    fullSize: meetingParams.getBool(MEETING.FullScreen),
                                    interfaceConfigOverwrite: {},
                                    configOverwrite: {
                                        startAudioOnly: false,
                                        startWithAudioMuted: false,
                                        startSilent: this.props.app.urlParams['silent'] !== undefined || this.props.app.settings.silent8x8
                                    },
                                    goToBreakout: false
                                }
                            });
                            break;
                        }
                        case MeetingService.x8Jaas: {
                            this.setState({
                                jitsiconf: {
                                    ...(this.state.jitsiconf || {}),
                                    domain: meetingParams.getUtfString(MEETING.MeetingHost),
                                    roomName: meetingParams.getUtfString(MEETING.RoomChannel),
                                    fullSize: meetingParams.getBool(MEETING.FullScreen),
                                    interfaceConfigOverwrite: {},
                                    configOverwrite: {
                                        startAudioOnly: false,
                                        startWithAudioMuted: false,
                                        startSilent: this.props.app.urlParams['silent'] !== undefined || this.props.app.settings.silent8x8
                                    },
                                    goToBreakout: false
                                }
                            });
                            break;
                        }
                    }
                }
                console.log('8x8 Params ', this.state.jitsiconf);
                this.props.setJitsiFullSize(false);
                this.props.setMyBridgePosition(BridgePosition.south);
                this.props.hideButtons(['next', 'prev', 'undo', 'claim', 'startPlay', 'stopPlay', 'replay']);

                if (room.containsVariable(SFSVAR.SFSGAME_POLL_QUESTION)) {
                    if (room.getVariable(SFSVAR.SFSGAME_POLL_QUESTION) !== undefined) {
                        const params: SFSObject = room.getVariable(SFSVAR.SFSGAME_POLL_QUESTION).value;
                        const poll = {
                            id: params.getUtfString('pollID'),
                            question: params.getUtfString('question'),
                            answers: params.getUtfStringArray('answers')
                        };
                        this.props.setPoll(poll);
                    }
                }

                if (room.containsVariable(SFSVAR.SFSGAME_FRAME_URL)) {
                    //TO DO Show frame URL, for play cards IO, or somethign else
                    const params: SFSObject = room.getVariable(SFSVAR.SFSGAME_FRAME_URL).value;
                    this.props.setIframe(params.getUtfString(SFSVAR.SFSGAME_MID_LOAD));
                }

                if (this.state.myLobby.containsVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS)) {
                    const gameData: string = new TextDecoder('utf-8').decode(
                        this.state.myLobby.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS).value.getByteArray(SFSVAR.SFSGAME_MID_LOAD)
                    );
                    let gameJSON: IGameResults = JSON.parse(gameData);
                    this.props.setGameResults(gameJSON);
                }

                break;
            }
            case true: {
                this.props.removeCurrentModal();
                if (this.state.myLobby.id === undefined) {
                    this.setState({
                        myLobby: room
                    });
                }
                const { game } = this.props;
                const playerID: number = this.sfs.mySelf.getPlayerId(room);

                if (playerID <= 0) {
                    //I'm a kibitzer
                    this.props.setMyBridgePosition(BridgePosition.south);
                } else {
                    this.props.setMyBridgePosition(convertPlayerToBridgePosition(playerID));
                }
                this.setState({
                    myposition: convertPlayerToBridgePosition(playerID)
                });

                if (room.containsVariable(SFSVAR.SFSGAME_MODE)) {
                    this.props.setMetaData({
                        isBiddingQuiz: room.getVariable(SFSVAR.SFSGAME_MODE).value === SFSVAR.gameMode.BiddingQuiz
                    });
                }

                //Set the room display
                if (room.containsVariable(SFSVAR.SFSGAME_ROOM_DISPLAY)) {
                    this.props.setTableId(' ' + room.getVariable(SFSVAR.SFSGAME_ROOM_DISPLAY).value + ' ');
                } else {
                    this.props.setTableId('Table: ' + room.id + ' ');
                }
                if (this.state.myLobby.containsVariable(SFSVAR.RVAR_MEETING_STATE)) {
                    this.setState({ meetingState: this.state.myLobby.getVariable(SFSVAR.RVAR_MEETING_STATE).value as MeetingState });
                } else {
                    this.setState({ meetingState: MeetingState.NONE });
                }

                //TODO: Move to new project and simplify
                if (room.containsVariable(SFSVAR.RVAR_MEETING_PARAMS)) {
                    const meetingParams: SFSObject = room.getVariable(SFSVAR.RVAR_MEETING_PARAMS).value;
                    let meetingRoom: string = '';
                    if (meetingParams.containsKey(MEETING.BreakOutChannel)) {
                        meetingRoom = meetingParams.getUtfString(MEETING.BreakOutChannel);
                    } else {
                        meetingRoom = meetingParams.getUtfString(MEETING.RoomChannel);
                    }

                    if (meetingParams.getBool(MEETING.Screened)) {
                        if (this.state.myposition === BridgePosition.east || this.state.myposition === BridgePosition.north) {
                            meetingRoom = meetingRoom;
                        } else {
                            meetingRoom = meetingRoom + 'sw';
                        }
                    }
                    const meetingService: MeetingService = meetingParams.getUtfString(MEETING.MeetingService) as MeetingService;
                    if (this.state.jitsiconf !== undefined) {
                        this.state.jitsiconf.breakOut = meetingRoom;
                        if (this.state.jitsiconf.jwt) {
                            this.setState({
                                jitsiconf: {
                                    ...(this.state.jitsiconf || {}),
                                    breakOut: meetingRoom,
                                    goToBreakout: true
                                }
                            });
                        }
                    } else {
                        this.setState({
                            jitsiconf: {
                                ...(this.state.jitsiconf || {}),
                                domain: meetingParams.getUtfString(MEETING.MeetingHost),
                                password: meetingParams.getUtfString(MEETING.AccessCode),
                                roomName: meetingParams.getUtfString(MEETING.RoomChannel),
                                userName: this.sfs.mySelf.containsVariable('nn')
                                    ? this.sfs.mySelf.getVariable('nn').value
                                    : this.sfs.mySelf.name,
                                fullSize: false,
                                interfaceConfigOverwrite: {},
                                configOverwrite: {
                                    startAudioOnly: false,
                                    startWithAudioMuted: false,
                                    startSilent: this.props.app.urlParams['silent'] !== undefined || this.props.app.settings.silent8x8
                                },
                                goToBreakout: false
                            }
                        });
                    }
                }

                //Bridge Settings Below
                if (this.isPostMortem()) {
                    this.props.setTimeToTournament(undefined);
                    this.props.setTimeToGameEnd(undefined);
                    this.props.showButtons(['next', 'prev']);
                } else {
                    if (this.sfs.mySelf.containsVariable(SFSVAR.SFSGAME_ROOM_STT)) {
                        const startTime: number = this.sfs.mySelf.getVariable(SFSVAR.SFSGAME_ROOM_STT).value;
                        if (startTime > 1) {
                            this.props.setTimeToTournament(this.sfs.mySelf.getVariable(SFSVAR.SFSGAME_ROOM_STT).value);
                            this.props.setTimeToGameEnd(this.sfs.mySelf.getVariable(SFSVAR.SFSGAME_ROOM_STT).value);
                        } else {
                            this.props.setTimeToTournament(undefined);
                        }
                    }
                    try {
                        if (this.state.myLobby.containsVariable(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME)) {
                            this.props.setSoundPlaying('tournamentStart');
                            this.props.setTimeToTournament(undefined);
                            const currentTime: number = Math.floor(Date.now() / 1000);
                            const endTime: number = this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME).value; //SFSGAME_ROOM_UTC_STRTIME
                            this.props.setTimeToGameEnd(endTime - currentTime);
                            this.props.setMetaData({
                                director: 'Director'
                            });
                        }
                    } catch (e) {}
                    this.props.hideButtons(['next', 'prev']);
                }

                if (this.state.myLobby.containsVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS)) {
                    const gameData: string = new TextDecoder('utf-8').decode(
                        this.state.myLobby.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS).value.getByteArray(SFSVAR.SFSGAME_MID_LOAD)
                    );
                    let gameJSON: IGameResults = JSON.parse(gameData);
                    this.props.setGameResults(gameJSON);
                }

                if (room.containsVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2)) {
                    const game_results: IGameResultsV2 = JSON.parse(
                        new TextDecoder('utf-8').decode(
                            room.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2).value.getByteArray('data')
                        )
                    );
                    this.props.setGameResults(undefined);
                    this.props.setGameResultsV2(game_results);
                }

                if (room.containsVariable('showinvites')) {
                    this.props.showInvites(room.getVariable('showinvites').value);
                } else {
                    this.props.showInvites(false);
                }

                this.props.hideButtons(['undo', 'claim']);
                this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
                this.refreshMeeting(true);
                break;
            }
        }
    };

    private loadMeetingConfig() {}

    /* Event is fired the player */
    private onRoomJoinError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        // console.log('Room joining failed: ' + errorMessage);
    };

    /*Event is fired when a new user joins the room. Currently it does nothing for a teachers room
      In the EmaBridge_Social this events fires a sound (if sounds are enabled)
      and post a message in the room chat announcing the new arrival.
      */
    private onUserEnterRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        this.props.showChatSendForm(room.getUserList().length > 1);
    };

    //IMPORTANT: if the user that left is ME, then move out of table to lobby, for now probably just a simple note or somethign.
    /**/
    private onUserExitRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        if (user.isItMe) {
            if (room.id !== this.sfs.lastJoinedRoom) return;
            //Close the table interface and back to lobby.
            this.props.resetGameExcept(['jitsi', 'metaData', 'gamePhase']);
            this.props.setGamePhase(GamePhase.END);
            //this.props.resetGamePartials(['tricks', 'winner', 'contract', 'stake', 'cards', 'auction','gamePhase','gameResults']);
            if (room.isGame) {
                /*
                this.setState({
                    meetingState:
                        this.state.jitsiconf !== undefined || this.state.twitchconf !== undefined ? MeetingState.inLobby : MeetingState.NONE
                });
                //this.state.jitsibreakconf = undefined;
                this.refreshMeeting();

                 */
            }
        } else {
            this.props.showChatSendForm(room.getUserList().length > 1);
        }
    };
    //Here are we are interested in the following variables
    /*
     *  Player names to use in the Hand trays, those are adjusted at the server based on what happens
     * SFSGAME_NORTH_PLAYER_NAME (string)
     * SFSGAME_SOUTH_PLAYER_NAME
     * SFSGAME_EAST_PLAYER_NAME
     * SFSGAME_WEST_PLAYER_NAME
     *
     * Seat availability mode variables, it shows who can join in. It is for information only, rules are enforced on the server side
     * Users with valid SFSInvitations by pass those rules.
     * Like if someone is kibitzing ( watching  in the game ) they can / can't join in based on the rules.
     * SFSGAME_NORTH_AVAILABILITY
     * SFSGAME_NORTH_AVAILABILITY
     * SFSGAME_NORTH_AVAILABILITY
     * SFSGAME_NORTH_AVAILABILITY
     *
     * The possible values are
     * int SEAT_OPEN=0; //any one can join in
     *	int SEAT_LOCKED=1; //no one can join in without SFSInvitation
     *	int SEAT_BUDDY=2; //only friends can join in wittout SFSInvitation
     * */
    private onRoomVarsUpdate = ({ room, changedVars }: { room: SFSRoom; changedVars: string[] }) => {
        if (
            room.isGame &&
            room === this.sfs.lastJoinedRoom &&
            (changedVars.includes(SFSVAR.SFSGAME_NORTH_PLAYER_NAME) ||
                changedVars.includes(SFSVAR.SFSGAME_SOUTH_PLAYER_NAME) ||
                changedVars.includes(SFSVAR.SFSGAME_EAST_PLAYER_NAME) ||
                changedVars.includes(SFSVAR.SFSGAME_WEST_PLAYER_NAME))
        ) {
            this.setTrayNames(room);
        }

        //always present variable
        if (changedVars.includes(GAME_STATE.INTERACTIVE_bool)) {
            this.props.blockGameByTeacher(!room.getVariable(GAME_STATE.INTERACTIVE_bool).value);
        }

        //variable presence set the stage, might not be present
        if (room.isGame && room.containsVariable(SFSVAR.SFSGAME_NOAUCTION) && changedVars.includes(SFSVAR.SFSGAME_NOAUCTION)) {
            this.props.showAuctionBox(!room.getVariable(SFSVAR.SFSGAME_NOAUCTION).value);
        }

        if (room.containsVariable(SFSVAR.SFSGAME_NOUNDO) && changedVars.includes(SFSVAR.SFSGAME_NOUNDO)) {
            this.props.hideButtons(['undo']);
        }

        if (room.isGame && room.containsVariable(SFSVAR.SFSGAME_NOLASTTRICK) && changedVars.includes(SFSVAR.SFSGAME_NOLASTTRICK)) {
            this.props.showClosedTricks(!room.getVariable(SFSVAR.SFSGAME_NOLASTTRICK).value);
        }

        if (room.isGame && room.containsVariable(SFSVAR.SFSGAME_ROOM_DISPLAY) && changedVars.includes(SFSVAR.SFSGAME_ROOM_DISPLAY)) {
            this.props.setTableId(' ' + room.getVariable(SFSVAR.SFSGAME_ROOM_DISPLAY).value + ' ');
        }

        if (room.isGame && changedVars.includes(SFSVAR.RVAR_MEETING_STATE)) {
            this.setState({
                meetingState: room.getVariable(SFSVAR.RVAR_MEETING_STATE).value as MeetingState
            });
            this.refreshMeeting(false);
        }

        if (changedVars.includes(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME)) {
            this.props.setTimeToTournament(undefined);
            this.props.setTimeToGameEnd(undefined);
            if (room.containsVariable(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME)) {
                this.props.setSoundPlaying('tournamentStart');
                const currentTime: number = Math.floor(Date.now() / 1000);
                const endTime: number = this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME).value; //SFSGAME_ROOM_UTC_STRTIME
                this.props.setTimeToGameEnd(endTime - currentTime);
                this.props.setMetaData({
                    director: 'Director'
                });
                console.log('CURRENT TIME CHANGED : ', currentTime);
                console.log('END TIME CHANGED : ', endTime);
            }
            if (room.containsVariable(SFSVAR.SFSGAME_ROOM_UTC_STRTIME)) {
                this.props.setTimeToTournament(this.sfs.mySelf.getVariable(SFSVAR.SFSGAME_ROOM_STT).value);
                this.props.setTimeToGameEnd(this.sfs.mySelf.getVariable(SFSVAR.SFSGAME_ROOM_STT).value);
                this.props.setMetaData({
                    director: 'Director'
                });
            }
        }

        if (changedVars.includes(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS)) {
            let gameJSON: IGameResults | undefined = undefined;
            if (room.isGame) {
                const pairsData: string = new TextDecoder('utf-8').decode(
                    room.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS).value.getByteArray(SFSVAR.SFSGAME_MID_LOAD)
                );
                const pairs: IGameResultsPair[] = JSON.parse(pairsData);

                if (this.state.myLobby.containsVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS)) {
                    const gameData: string = new TextDecoder('utf-8').decode(
                        this.state.myLobby.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS).value.getByteArray(SFSVAR.SFSGAME_MID_LOAD)
                    );
                    gameJSON = JSON.parse(gameData);
                    if (gameJSON) {
                        gameJSON.Pairs = pairs;
                    }
                }
            } else {
                const gameData: string = new TextDecoder('utf-8').decode(
                    room.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS).value.getByteArray(SFSVAR.SFSGAME_MID_LOAD)
                );
                gameJSON = JSON.parse(gameData);
            }

            if (gameJSON) {
                gameJSON.myPairId = undefined;
            }
            this.props.setGameResults(gameJSON);
        }

        if (changedVars.includes(SFSVAR.SFSGAME_ROOM_RESULTS_URL)) {
            let result_url = undefined;
            if (room.containsVariable(SFSVAR.SFSGAME_MODE) && room.getVariable(SFSVAR.SFSGAME_MODE).value === SFSVAR.gameMode.Swissteam) {
                result_url = room.containsVariable(SFSVAR.SFSGAME_ROOM_RESULTS_URL)
                    ? room.getVariable(SFSVAR.SFSGAME_ROOM_RESULTS_URL).value
                    : undefined;
            }
            this.props.setMetaData({
                resultUrl: result_url
            });
        }

        if (changedVars.includes(SFSVAR.SFSGAME_MODE)) {
            let result_url = undefined;
            if (room.getVariable(SFSVAR.SFSGAME_MODE).value === SFSVAR.gameMode.Swissteam) {
                result_url = room.containsVariable(SFSVAR.SFSGAME_ROOM_RESULTS_URL)
                    ? room.getVariable(SFSVAR.SFSGAME_ROOM_RESULTS_URL).value
                    : undefined;
            }
            this.props.setMetaData({
                resultUrl: result_url
            });
        }

        if (changedVars.includes(SFSVAR.SFSGAME_POLL_QUESTION)) {
            if (room.getVariable(SFSVAR.SFSGAME_POLL_QUESTION) === undefined) {
                //close pending poll popup
                this.props.setPoll(undefined);
            } else {
                const params: SFSObject = room.getVariable(SFSVAR.SFSGAME_POLL_QUESTION).value;
                const poll = {
                    id: params.getUtfString('pollID'),
                    question: params.getUtfString('question'),
                    answers: params.getUtfStringArray('answers')
                };
                this.props.setPoll(poll);
            }
        }

        if (changedVars.includes(SFSVAR.SFSGAME_VIDEO_CLIP)) {
            //TO DO Show Video Screen
        }

        if (changedVars.includes(SFSVAR.SFSGAME_FRAME_URL)) {
            //TO DO Show frame URL, for play cards IO, or somethign else
            if (room.getVariable(SFSVAR.SFSGAME_FRAME_URL) === undefined) {
                this.props.setIframe(undefined);
            } else {
                const params: SFSObject = room.getVariable(SFSVAR.SFSGAME_FRAME_URL).value;
                this.props.setIframe(params.getUtfString(SFSVAR.SFSGAME_MID_LOAD));
            }
        }

        if (room.isGame && changedVars.includes(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2)) {
            const game_results: IGameResultsV2 = JSON.parse(
                new TextDecoder('utf-8').decode(room.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2).value.getByteArray('data'))
            );
            this.props.setGameResults(undefined);
            this.props.setGameResultsV2(game_results);
        }
    };

    private onUserVarsUpdate = ({ user, changedVars }: { user: SFSUser; changedVars: string[] }) => {
        if (user.isItMe) {
            if (changedVars.includes('kbd')) {
                const seatid: number = user.getVariable('kbd').value;
                this.props.setMyBridgePosition(convertIntToBridgePosition(seatid));
            }

            if (changedVars.includes(SFSVAR.SFSGAME_SEAT_ID)) {
                const seatid: number = user.getVariable(SFSVAR.SFSGAME_SEAT_ID).value;
                this.props.setMyBridgePosition(convertIntToBridgePosition(seatid));
            }

            /*if (changedVars.includes('teamid')) {
                    if (this.state.myLobby.containsVariable(SFSVAR.SFSGAME_ROOM_IS_POSPORTEM) && this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_IS_POSPORTEM).value && this.state.myLobby.containsVariable('TKey')) {
                     // console.log('onUserVarsUpdate :' + changedVars + ' value :' +user.getVariable('teamid').value);
                      const resulturl : string = 'https://tcgcloud.bridgefinesse.com/PHPPOSTCGS.php?options=PingWBF&mode=TeamResults&tnumber='
                          + this.state.myLobby.getVariable('TKey').value + '&source=0.'
                          + user.getVariable('teamid').value + '.0';
                      this.props.setMetaData({
                        resultUrl: resulturl
                      });
                    }
                  }*/
        }
    };

    //This is like the onRoomJoin, but the player was actually kibitzing an is now seated at the table
    private onSpectatorToPlayerSwitch = ({ room, user, playerID }: { room: SFSRoom; user: SFSUser; playerID: number }) => {
        if (!room.isGame || room !== this.sfs.lastJoinedRoom || !user.isItMe) {
            return;
        }
        const playerRID: number = this.sfs.mySelf.getPlayerId(room);
        this.props.setMyBridgePosition(convertPlayerToBridgePosition(playerRID));
        this.setState({
            myposition: convertPlayerToBridgePosition(playerRID)
        });
        this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
    };

    private onSpectatorToPlayerSwitchError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        //console.log('Unable to become a player due to the following error: ' + errorMessage);
    };

    //Unseat the player, it turns in Kibitz. The important action here is to adjust hand visibility based on current rules for kibitzers
    private onPlayerToSpectatorSwitch = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        if (!room.isGame || room !== this.sfs.lastJoinedRoom || user.isItMe) {
            return;
        }
        this.props.hideButtons(['undo', 'claim']);
        this.props.setMyBridgePosition(convertIntToBridgePosition(this.state.currentPlayerOnServer));
        this.setState({
            myposition: BridgePosition.south
        });
        this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
    };

    private onPlayerToSpectatorSwitchError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        //console.log('Unable to become a spectator due to the following error: ' + errorMessage);
    };

    //This is the fun part, the actually game logic come it.
    private onExtensionResponse = ({ cmd, params }: { cmd: string; params: SFSObject }) => {
        //console.log('Object Command: ' + cmd + ' object : ' + params.getDump());
        if (cmd === SFSVAR.CMD_GAME_LOGIC) {
            // We expect atleast one INT value in the params: EXTENSION_MID with values one of netMID
            //console.log('The custom Extension is: ' + params.getDump());
            this.processObject(params);
        } else if (cmd === SFSVAR.CMD_CONTROL_ACTION) {
            this.processObject(params);
        } else if (cmd === SFSVAR.SFSGAME_JITSI_CONTROL) {
            //console.log('Object Command: ' + cmd + ' object : ' + params.getDump());
            this.process_JITSI_CONTROL(params);
        } else if (cmd === SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS) {
            //Here we receive new game data.
            const gameData: string = new TextDecoder('utf-8').decode(params.getByteArray(SFSVAR.SFSGAME_MID_LOAD));
            let gameJSON: IGameResults = JSON.parse(gameData);
            gameJSON.myPairId = undefined;
            //console.log(gameJSON);
            this.props.setGameResults(gameJSON);
        } else if (cmd === SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2) {
            //Here we receive new game data.
            //const gameData: string = new TextDecoder('utf-8').decode(params.getByteArray(SFSVAR.SFSGAME_MID_LOAD));
            //let gameJSON: IGameResults = JSON.parse(gameData);
            //gameJSON.myPairId = undefined;
            //console.log(gameJSON);
            //this.props.setGameResults(gameJSON);
            if (params.containsKey('data')) {
                const game_results: IGameResultsV2 = JSON.parse(new TextDecoder('utf-8').decode(params.getByteArray('data')));
                //         console.log('Game Results', game_results);
                this.props.setGameResults(undefined);
                this.props.setGameResultsV2(game_results);
            }
        } else if (cmd === SFSVAR.SFSGAME_ROOM_RESULTS_URL) {
            //Here we receive new game data.
            this.props.setMetaData({
                resultUrl: params.getUtfString(SFSVAR.SFSGAME_MID_LOAD)
            });
        } else if (cmd === SFSVAR.SFSGAME_CARD_HIGHLIGHT) {
            if (!params.containsKey(SFSVAR.SFSGAME_MID_LOAD)) {
                this.props.unRaiseCards(undefined);
                return;
            }
            const localcard: string = convertSuitRank(params.getUtfString(SFSVAR.SFSGAME_MID_LOAD).toUpperCase());
            const cardArray: Array<ICard['id']> = [];
            cardArray.push(localcard);
            const action: boolean = params.getBool(SFSVAR.SFSGAME_MID_LOAD_EXTRA);
            if (action) {
                //Hightlight card
                this.props.raiseCards(cardArray);
            } else {
                //Remove highlight card
                this.props.unRaiseCards(cardArray);
            }
        } else if (cmd === SFSVAR.SFSGAME_FRAME_URL) {
            //TO DO Show frame URL, for play cards IO, or somethign else
        } else if (cmd === SFSVAR.CMD_POPUP) {
            this.process_CMD_POPUP(params);
        }
    };

    private process_JITSI_CONTROL(params: SFSObject) {
        const mstate: string = params.getUtfString(SFSVAR.SFSGAME_MID_LOAD);
        this.setState({
            meetingState: mstate as MeetingState
        });

        /*
        const isScreened: boolean = params.containsKey(SFSVAR.SFSGAME_MID_LOAD_EXTRA)
            ? params.getBool(SFSVAR.SFSGAME_MID_LOAD_EXTRA)
            : false;
        const meetingParams: SFSObject = this.sfs.lastJoinedRoom.getVariable(SFSVAR.RVAR_MEETING_PARAMS).value;
        let meetingRoom = meetingParams.getUtfString(MEETING.RoomChannel);
        if (params.containsKey(MEETING.RoomChannel)) {
            meetingRoom = params.getUtfString(MEETING.RoomChannel);
        } else if (MeetingState.atTable && this.sfs.lastJoinedRoom !== null) {
            if (isScreened && (this.state.myposition === BridgePosition.south || this.state.myposition === BridgePosition.west)) {
                meetingRoom = meetingRoom + 'sw';
            }
            if (this.state.jitsibreakconf !== undefined) {
                this.state.jitsibreakconf.roomName = meetingRoom;
            }
        }
        */
        this.refreshMeeting(false);
    }

    private refreshMeeting(justJoined: boolean) {
        // console.log('Window.jitsiApi: Meeting State: ', justJoined, this.state.meetingState);
        // console.log('Window.jitsiApi: JitSi Switch Room: ', this.state.jitsiconf);

        const { app } = this.props;
        const Window: any = window;

        //        console.log('Window.jitsiApi: JitSi API: ', Window.jitsiApi);

        if (app.urlParams['novideo'] !== undefined) {
            Window.jitsiApi = undefined;
            this.props.setJitsi({});
            this.props.setTwitch({});
            return;
        }
        if (this.state.jitsiconf?.jwt) {
            switch (this.state.meetingState) {
                case MeetingState.atTable: {
                    this.state.jitsiconf.goToBreakout = true;
                    if (Window.jitsiApi === undefined) {
                        this.props.setJitsi(this.state.jitsiconf);
                    } else {
                        // @ts-ignore
                        Window.jitsiApi.listBreakoutRooms().then(breakoutRooms => {
                            const isFound = Object.values(breakoutRooms).some(element => {
                                // @ts-ignore
                                if (element.name === this.state.jitsiconf?.breakOut) {
                                    // @ts-ignore
                                    Window.jitsiApi.executeCommand('joinBreakoutRoom', element.jid);
                                    return true;
                                }
                            });
                            if (isFound) {
                                // @ts-ignore
                                this.state.jitsiconf.goToBreakout = true;
                            }
                        });
                    }
                    break;
                }
                case MeetingState.inLobby: {
                    if (this.state.jitsiconf !== undefined) {
                        if (Window.jitsiApi === undefined) {
                            this.state.jitsiconf.goToBreakout = false;
                            this.props.setJitsi(this.state.jitsiconf);
                        } else if (this.props.game.jitsi.goToBreakout) {
                            this.state.jitsiconf.goToBreakout = false;
                            Window.jitsiApi.executeCommand('joinBreakoutRoom');
                        }
                    }
                    break;
                }
                case MeetingState.NONE: {
                    Window.jitsiApi = undefined;
                    this.props.setJitsi({});
                    this.props.setTwitch({});
                    break;
                }
            }
        } else {
            switch (this.state.meetingState) {
                case MeetingState.atTable: {
                    if (this.state.jitsiconf !== undefined) {
                        if (Window.jitsiApi === undefined) {
                            this.state.jitsiconf.goToBreakout = true;
                            this.props.setJitsi(this.state.jitsiconf);
                        } else {
                            // @ts-ignore
                            Window.jitsiApi.listBreakoutRooms().then(breakoutRooms => {
                                const isFound = Object.values(breakoutRooms).some(element => {
                                    // @ts-ignore
                                    if (element.name === this.state.jitsiconf?.breakOut) {
                                        // @ts-ignore
                                        Window.jitsiApi.executeCommand('joinBreakoutRoom', element.jid);
                                        return true;
                                    }
                                });
                                if (isFound) {
                                    // @ts-ignore
                                    this.state.jitsiconf.goToBreakout = true;
                                }
                            });
                        }
                    }
                    break;
                }
                case MeetingState.inLobby: {
                    if (this.state.jitsiconf !== undefined) {
                        if (Window.jitsiApi === undefined) {
                            this.state.jitsiconf.goToBreakout = false;
                            this.props.setJitsi(this.state.jitsiconf);
                        } else if (this.props.game.jitsi.goToBreakout) {
                            this.state.jitsiconf.goToBreakout = false;
                            Window.jitsiApi.executeCommand('joinBreakoutRoom');
                        }
                    }
                    break;
                }
                case MeetingState.NONE: {
                    Window.jitsiApi = undefined;
                    this.props.setJitsi({});
                    this.props.setTwitch({});
                    break;
                }
            }
        }
    }

    //This one is processed the same as onExtensionResponse, but it is generated internally by SFS server, rather in response to request from
    // client.
    private onObjectMessage = ({ message, sender }: { message: SFSObject; sender: SFSUser }) => {
        if (message.containsKey(SFSVAR.EXTENSION_MID)) {
            //Pass the the same SFS_OBJECT_MESSAGE processor. Server will often send object as a result of game logic , rather than in response
            // to an Extention request.
            this.processObject(message);
        } else if (message.containsKey('o')) {
            this.props.addChatEntry({
                timestamp: moment.utc().format(),
                sender: sender.containsVariable('pn')
                    ? sender.getVariable('pn').value
                    : sender.containsVariable('nn')
                    ? sender.getVariable('nn').value
                    : sender.name,
                message: message.getUtfString('o')
            });
        }
    };

    private processObject = (cmdObj: SFSObject) => {
        const mid: netMID = cmdObj.getInt(SFSVAR.EXTENSION_MID);
        switch (mid) {
            case SFSVAR.netMID.MID_NEW_DEAL: {
                //DONE
                this.setState({
                    userActed: false
                });
                this.process_MID_CURRENT_DEAL(cmdObj);
                this.sendRoomCommand(SFSVAR.CMD_TABLE_READY, new SFSObject());
                break;
            }
            case SFSVAR.netMID.MID_CURRENT_DEAL: {
                this.setState({
                    userActed: false
                });
                this.process_MID_CURRENT_DEAL(cmdObj);
                break;
            }

            case SFSVAR.netMID.MID_CHANGE_HAND_VISIBILITY: {
                //DONE
                this.process_MID_CHANGE_HAND_VISIBILITY(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_TIME_BID: {
                // 5 DONE
                this.process_MID_TIME_TO_BID(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_MADE_BID: {
                // 6 DONE
                this.process_MID_MADE_BID(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_CONTRACT: {
                // 7 DONE
                this.process_MID_CONTRACT(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_TIME_PLAY: {
                // 8 DONE
                this.process_MID_TIME_TO_PLAY(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_MADE_PLAY: {
                // 9 DONE
                this.process_MID_MADE_PLAY(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_TIME_CLOSE_TRICK: {
                // 10 DONE
                this.process_MID_TIME_CLOSE_TRICK(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_TIME_SCORE: {
                // 11 DONE
                this.process_MID_TIME_TO_SCORE(cmdObj);
                return;
            }
            case SFSVAR.netMID.MID_NEXT_DEAL: {
                //12
                // Not used right now.
                return;
            }

            case SFSVAR.netMID.MID_REPLAY_DEAL: {
                //13
                // Not used right now.
                return;
            }

            case SFSVAR.netMID.MID_CLAIM_REQUEST: {
                //14
                // Not used right now.
                return;
            }

            case SFSVAR.netMID.MID_CLAIM_RESPONSE: {
                //15 DONE
                this.props.removeCurrentModal();
                return;
            }

            case SFSVAR.netMID.MID_UNDO_RESPONSE: {
                //15
                // DONE
                this.props.removeCurrentModal();
                return;
            }

            case SFSVAR.netMID.MID_UNDO_NOTIFICATION: {
                //DONE
                this.process_MID_UNDO_NOTIFICATION(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_CLAIM_NOTIFICATION: {
                //DONE
                this.process_MID_CLAIM_NOTIFICATION(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_TO_JITSI_BRK: {
                return;
            }

            case SFSVAR.netMID.MID_FROM_JITSI_BRK: {
                return;
            }

            case SFSVAR.netMID.MID_CONTROL_ACTION: {
                //DONE
                this.process_MID_ACTION_CONTROL(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_START_ROUND: {
                //DONE
                this.props.setSoundPlaying('tournamentStart');
                if (this.state.myLobby.containsVariable(SFSVAR.SFSGAME_REM_TIME)) {
                    this.props.setTimeToTournament(undefined);
                    this.props.setTimeToGameEnd(this.state.myLobby.getVariable(SFSVAR.SFSGAME_REM_TIME).value);
                } else if (this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_REM_TIME)) {
                    this.props.setTimeToTournament(undefined);
                    this.props.setTimeToGameEnd(this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_REM_TIME).value);
                }
                return;
            }

            case SFSVAR.netMID.MID_END_OF_SET: {
                //DONE
                this.process_MID_END_OF_SET(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_SET_HANDS: {
                //DONE
                this.process_MID_SET_HANDS(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_WAIT_FOR_ROUND: {
                //DONE
                if (cmdObj.containsKey(SFSVAR.SFSGAME_ROOM_UTC_STRTIME)) {
                    const currentTime: number = Math.floor(Date.now() / 1000);
                    const endTime: number = this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME).value; //SFSGAME_ROOM_UTC_STRTIME
                    this.props.setTimeToGameEnd(endTime - currentTime);
                }
                if (cmdObj.containsKey(SFSVAR.SFSGAME_ROOM_STT)) {
                    // console.log("Game Start time: ", cmdObj.getUtfString(SFSVAR.SFSGAME_ROOM_STT));
                    this.props.setTimeToTournament(undefined);
                    this.props.setTimeToTournament(Number(cmdObj.getUtfString(SFSVAR.SFSGAME_ROOM_STT)));
                }

                return;
            }

            case SFSVAR.netMID.MID_TEXT_MESSAGE: {
                //DONE
                this.process_MID_TEXT_MESSAGE(cmdObj);
                return;
            }

            case SFSVAR.netMID.MID_SHOW_WELCOME: {
                //DONE
                this.process_POP_WELCOME_MESSAGE();
                return;
            }
        }
    };

    private process_MID_TEXT_MESSAGE(cmdObj: SFSObject) {
        //const { t } = useTranslation();

        if (cmdObj.containsKey('o')) {
            if (cmdObj.containsKey('exitshark') && cmdObj.getBool('exitshark')) {
                const modalview: IModal = {
                    noClickOutside: true,
                    noHeaderClose: true,
                    header: this.props.game.metaData.eventName,
                    body: [
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('o')) }} />
                            <p dangerouslySetInnerHTML={{ __html: this.state.farewellMessage ? this.state.farewellMessage : '' }} />
                            <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('oe')) }} />
                        </div>
                    ],
                    cancelButtonLabel: <Translate contentKey="modal.ok" />,
                    onCancel: () => {
                        this.props.game.gameResults || this.props.game.gameResultsV2
                            ? this.showModal({
                                  ...getGameResultBlockingModalConfig(
                                      this.props.game.gameResultsV2 ? this.props.game.gameResultsV2 : this.props.game.gameResults,
                                      !!this.props.game.gameResultsV2
                                  ),
                                  onCancel: () => this.updateBoardData(undefined)
                              })
                            : (window.location.href =
                                  this.props.game.metaData.resultUrl + '&pn=' + this.sfs.mySelf.getVariable(SFSVAR.SFS_NICKNAME).value);
                    }
                };
                this.showModal(modalview);
            } else {
                this.showModal(
                    getAlert([
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('o')) }} />
                            <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('oe')) }} />
                        </div>
                    ])
                );
            }
        }
    }

    private process_CMD_POPUP(cmdObj: SFSObject) {
        this.showModal({
            noHeaderClose: true,
            header: cmdObj.getUtfString('oe'),
            body: [
                <div>
                    <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('o')) }} />
                </div>
            ],
            cancelButtonLabel: <Translate contentKey="modal.ok" />
        });
    }

    private process_POP_WELCOME_MESSAGE() {
        //process pop-ups from server side.
        console.log('Welcome message', this.props.game.metaData.welcomeMessage);
        //console.log('Game phase', this.props.game.gamePhase);
        if (this.props.game.metaData.welcomeMessage) {
            const isMiniBridge: boolean =
                this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
                this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value;

            const modalMessage: IModal = getSplash(
                [
                    <div dangerouslySetInnerHTML={{ __html: 'Decide <b>where</b> and <b>how high<b/> to play.' }} />,
                    <div>
                        {' '}
                        Choose whether to play notrump (NT) or in a trump (suit) contract AND your level – the # of tricks over ‘Book’ (6)
                        you think you can win.
                    </div>
                ],
                'This is mini bridge!',
                true
            );
            this.props.addModal({
                noHeaderClose: true,
                header: this.props.game.metaData.eventName,
                body: [
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: insertSuits(this.props.game.metaData.welcomeMessage) }} />
                    </div>
                ],
                cancelButtonLabel: <Translate contentKey="modal.ok" />,
                onCancel: () => (isMiniBridge && this.props.game.gamePhase === GamePhase.BID ? this.showModal(modalMessage) : () => {})
            });
        }
    }

    //MID processors
    private process_MID_CURRENT_DEAL(cmdObj: SFSObject) {
        // console.log('---------------------------process_MID_CURRENT_DEAL');
        // console.log('Lobby Variables: \n ' + this.state.myLobby);
        // console.log('The deal is: \n ' + cmdObj.getDump());
        this.props.removeCurrentModal();
        /*const foreignBR: string | undefined = this.props.game.foreignBoardReviewData
                                              ? JSON.stringify(this.props.game.foreignBoardReviewData)
                                              : undefined;
        //console.log('ForeignData', foreignBR);
        if (foreignBR) {
            const newForeignData: ForeignBoardReviewData = JSON.parse(foreignBR);
            newForeignData.playerData[0].auction = calls;
            this.props.setForeignBoardReviewData(newForeignData);
        }*/
        this.props.clearChat();
        this.props.setPoll(undefined);
        this.props.hideButtons(['undo', 'claim', 'startPlay', 'stopPlay', 'replay']);
        this.props.unRaiseCards(undefined);
        this.props.clearMyHighlightedCards();
        const oblState: SFSObject = cmdObj.getSFSObject('tablestate');
        const playerID: number = this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom);
        const tableState: number = oblState.getInt('state');
        this.props.resetGamePartials(['tricks', 'winner', 'contract', 'stake', 'cards', 'auction', 'myBoardStatKeys', 'boardStats']);
        //  this.props.setGamePhase(GamePhase.PRE);
        if (this.state.myLobby.containsVariable(SFSVAR.SFSGAME_ROOM_NB) && cmdObj.containsKey(SFSVAR.SFSGAME_MID_LOAD_STATE)) {
            if (cmdObj.containsKey(SFSVAR.SFSGAME_ROOM_ROUND)) {
                const bvalue: string =
                    '(' +
                    (cmdObj.getInt(SFSVAR.SFSGAME_DEAL_SET_START_INDEX) + 1) +
                    ' of ' +
                    this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_NB).value +
                    ')';
                this.props.setBoard({
                    label: `${cmdObj.getInt(SFSVAR.SFSGAME_ROOM_ROUND)}`,
                    value: bvalue,
                    boardNumber: cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE)
                });
                this.setState({
                    hasNext:
                        cmdObj.getInt(SFSVAR.SFSGAME_DEAL_SET_START_INDEX) + 1 !==
                        this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_NB).value
                });
            } else if (cmdObj.containsKey(SFSVAR.SFSGAME_DEAL_SET_START_INDEX)) {
                const bvalue: string =
                    '(' +
                    (cmdObj.getInt(SFSVAR.SFSGAME_DEAL_SET_START_INDEX) + 1) +
                    ' of ' +
                    this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_NB).value +
                    ')';
                this.props.setBoard({
                    label: `${cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE)}`,
                    value: bvalue,
                    boardNumber: cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE)
                });
                this.setState({
                    hasNext:
                        cmdObj.getInt(SFSVAR.SFSGAME_DEAL_SET_START_INDEX) + 1 !==
                        this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_NB).value
                });
            } else {
                this.props.setBoard({
                    label:
                        cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE) +
                        ' of ' +
                        this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_NB).value,
                    value: '',
                    boardNumber: cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE)
                });
                this.setState({
                    hasNext: cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE) !== this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_NB).value
                });
            }
        } else if (cmdObj.containsKey(SFSVAR.SFSGAME_MID_LOAD_STATE)) {
            this.props.setBoard({
                label: 'Board: ' + cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE),
                value: '',
                boardNumber: cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE)
            });
        }

        if (cmdObj.containsKey('comment') && !this.state.competemode) {
            this.props.setMetaData({
                comment: cmdObj.getUtfString('comment')
            });
        }

        if (playerID > 0) {
            this.setState({
                isDummy: false
            });
            this.setHandVisibility(oblState.getByte(SFSVAR.SFSGAME_MID_HANDPLAYER_VISIBILITY));
        } else {
            this.setHandVisibility(oblState.getByte(SFSVAR.SFSGAME_MID_HANDKIBITZ_VISIBILITY));
        }

        if (this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_NOAUCTION)) {
            this.props.showAuctionBox(!this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_NOAUCTION).value);
        }
        if (this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_NOLASTTRICK)) {
            this.props.showClosedTricks(!this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_NOLASTTRICK).value);
        }

        if (tableState > 0) {
            this.props.setActiveSeat(undefined);
        }
        this.props.setEnabledBiddingBox(false);
        this.props.enableDummy(false);
        this.setHandsFromState(oblState);

        if (
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
        ) {
            this.props.showAuctionBox(false);
        }

        this.setAuctionFromState(oblState);
        this.setLineOfPlayFromState(oblState);
        this.setHighLightedCards(oblState);
        if (oblState.containsKey('actstate')) {
            this.props.blockGameByTeacher(oblState.getBool('actstate'));
        }
        this.setState({
            freenav: false
        });
        if (cmdObj.containsKey('freenav')) {
            this.setState({
                freenav: true
            });
            if (cmdObj.getBool('freenav')) {
                this.props.showButtons(['next', 'prev']);
            } else {
                this.props.hideButtons(['next', 'prev']);
            }
        }

        if (cmdObj.containsKey('hasnext') && cmdObj.getBool('hasnext')) {
            this.setState({
                hasNext: true
            });
        }

        if (cmdObj.containsKey('mainmessage')) {
            this.props.addChatEntry({
                timestamp: moment.utc().format(),
                sender: 'Shark',
                message: cmdObj.getUtfString('mainmessage'),
                isSystemMessage: false
            });
        }

        if (tableState === 0) {
            this.props.setGamePhase(GamePhase.DEAL);
        } else if (tableState === 22) {
            const score: number = oblState.getInt('score');
            this.setScore(score, undefined, cmdObj);
        }
        this.updateBoardData(undefined);
    }

    private updateBoardData(
        extraPlayer: { auction: IBid[]; tricks: ICard[][]; winner: BridgePosition[]; declarer: BridgePosition } | undefined
    ) {
        const playerData: {
            auction: IBid[];
            tricks: ICard[][];
            winner: BridgePosition[];
            uuid: string;
            declarer: BridgePosition | undefined;
        }[] = [];

        playerData.push({
            auction: this.props.game.auction,
            tricks: this.props.game.tricks,
            winner: this.props.game.winner,
            uuid: '',
            declarer: this.props.game.seatData[BridgePosition.north].isDeclarer
                ? BridgePosition.north
                : this.props.game.seatData[BridgePosition.south].isDeclarer
                ? BridgePosition.south
                : this.props.game.seatData[BridgePosition.east].isDeclarer
                ? BridgePosition.east
                : this.props.game.seatData[BridgePosition.west].isDeclarer
                ? BridgePosition.west
                : undefined
        });
        if (extraPlayer) {
            playerData.push({
                auction: extraPlayer.auction,
                tricks: extraPlayer.tricks,
                winner: extraPlayer.winner,
                uuid: '',
                declarer: extraPlayer.declarer
            });
        }

        this.props.setForeignBoardReviewData({
            cards: this.props.game.cards,
            seatData: this.props.game.seatData,
            contract: this.props.game.contract,
            gamePhase: this.props.game.gamePhase,
            vulnerability: this.props.game.vulnerability,
            showAuctionBox: this.props.game.showAuctionBox,
            boardStats: this.props.game.boardStats,
            myBoardStatKeys: this.props.game.myBoardStatKeys,
            playerData: playerData
        });
    }

    private process_MID_SET_HANDS(cmdObj: SFSObject) {
        if (cmdObj.containsKey('northhand')) {
            this.setHandFromState(cmdObj.getUtfStringArray('northhand'), BridgePosition.north);
        }
        if (cmdObj.containsKey('easthand')) {
            this.setHandFromState(cmdObj.getUtfStringArray('easthand'), BridgePosition.east);
        }
        if (cmdObj.containsKey('southhand')) {
            this.setHandFromState(cmdObj.getUtfStringArray('southhand'), BridgePosition.south);
        }
        if (cmdObj.containsKey('westhand')) {
            this.setHandFromState(cmdObj.getUtfStringArray('westhand'), BridgePosition.west);
        }
        this.updateBoardData(undefined);
    }

    private process_MID_CHANGE_HAND_VISIBILITY(cmdObj: SFSObject) {
        //North visible if handVisibility & 2 != 0, East & 4, South & 8, West &16
        const playerID: number = this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom);
        if ((playerID > 0 && cmdObj.getBool('os') === true) || (playerID <= 0 && cmdObj.getBool('os') === false)) {
            console.log('process_MID_CHANGE_HAND_VISIBILITY ', cmdObj);
            this.setHandVisibility(cmdObj.getByte(SFSVAR.SFSGAME_MID_LOAD));
        }
        //this.updateBoardData(undefined);
    }

    private process_MID_TIME_TO_BID(cmdObj: SFSObject) {
        if (cmdObj.containsKey('message')) {
            this.props.addChatEntry({
                timestamp: moment.utc().format(),
                sender: cmdObj.getUtfString('messenger'),
                message: cmdObj.getUtfString('message'),
                isSystemMessage: false
            });
        }

        this.setState({
            currentPlayerOnServer: cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER),
            isDummy: false
        });
        this.props.enableDummy(false);
        if (this.isPostMortem()) {
            const { game } = this.props;
            // @ts-ignore
            const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
            this.props.setActiveSeat(mybridgeposition);
            this.props.setEnabledBiddingBox(true);
        } else {
            this.props.setActiveSeat(convertIntToBridgePosition(this.state.currentPlayerOnServer));
            this.props.setEnabledBiddingBox(true);
        }

        if (
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
        ) {
            this.props.addModal(
                getSplash(
                    [
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    'Decide <span style="font-weight: 600">where</span> and <span style="font-weight: 600">how high</span> to play.'
                            }}
                        />,
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    'Choose <span style="font-weight: 600">notrump</span> (NT) or <span style="font-weight: 600">trump</span> (a suit) and a <span style="font-weight: 600">level</span> –  the # of tricks over 6 (book) you think you can win.'
                            }}
                        />
                    ],
                    'This is mini bridge!',
                    true
                )
            );
            this.props.setIsVisible(getPartnerBridgePosition(this.props.game.activeBridgePosition ?? BridgePosition.north), true);
            this.props.enableDummy(true);
            return;
        }

        const stake: number = cmdObj.getInt('stake');
        switch (stake) {
            case 1:
                this.props.setDoubleValid(1);
                break;
            case 2:
                this.props.setDoubleValid(2);
                break;
            case 4:
                this.props.setDoubleValid(4);
                break;
            default:
                this.props.setDoubleValid(1);
        }
        //this.props.setSoundPlaying('tournamentStart');
        if (!this.isPostMortem()) {
            this.props.setSoundPlaying('yourTurn');
        }
        //Activate Bidding Keyboard
    }

    private process_MID_MADE_BID(cmdObj: SFSObject) {
        console.log('process_MID_MADE_BID', cmdObj.getDump(true));
        this.props.setActiveSeat(undefined);
        this.props.setSoundPlaying(undefined);
        this.props.setEnabledBiddingBox(false);
        const call: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);
        const nextPlayerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        this.setState({
            currentPlayerOnServer: nextPlayerBridgePosition,
            isDummy: false
        });
        this.props.setIsHighlighted(convertIntToBridgePosition(nextPlayerBridgePosition), true);

        if (
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
        ) {
            return;
        }
        let expl: {} | undefined = undefined;
        if (cmdObj.containsKey('expl')) {
            expl = JSON.parse(new TextDecoder().decode(cmdObj.getByteArray('expl')));
        }
        let bid: IBid = convertIntToBid(call, expl);
        console.log('Exlanation ', expl);
        if (expl && 'alert' in expl) {
            bid.alertMessage = lineForSimpleString('alert', expl);
            this.props.addChatEntry({ timestamp: moment.utc().format(), sender: 'Alert!', message: bid.alertMessage });
        } else if (cmdObj.containsKey(SFSVAR.SFSGAME_MID_ALERT)) {
            const { game } = this.props;
            const playerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_PLAYER);
            // TODO Check ts-ignore errors
            // @ts-ignore
            const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
            const bidderParnerBridgePossition: BridgePosition = convertIntToBridgePosition(playerBridgePosition ^ 2);
            if (bidderParnerBridgePossition !== mybridgeposition) {
                bid.alertMessage = cmdObj.getUtfString(SFSVAR.SFSGAME_MID_ALERT);
                const messenger: string = cmdObj.containsKey('messenger') ? ' by ' + cmdObj.getUtfString('messenger') : '';
                this.props.addChatEntry({ timestamp: moment.utc().format(), sender: 'Alert' + messenger + '!', message: bid.alertMessage });
            }
        }

        if (cmdObj.containsKey('message')) {
            const messenger: string = cmdObj.containsKey('messenger') ? cmdObj.getUtfString('messenger') : '';
            this.props.addChatEntry({ timestamp: moment.utc().format(), sender: messenger, message: cmdObj.getUtfString('message') });
        }

        this.props.appendAuction(bid);
        if (nextPlayerBridgePosition !== -1) {
        }
        this.updateBoardData(undefined);
    }

    private process_MID_CONTRACT(cmdObj: SFSObject) {
        this.props.removeCurrentModal();
        this.props.setGamePhase(GamePhase.BID_PLAY);
        this.setContractFromSFSObject(cmdObj.getSFSObject('contract'));

        this.updateBoardData(undefined);
    }

    private process_MID_TIME_TO_PLAY(cmdObj: SFSObject) {
        const currentPlayer: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        this.setState({
            currentPlayerOnServer: currentPlayer
        });
        this.props.setActiveSeat(convertIntToBridgePosition(currentPlayer));

        //Activate The hand for this.currentPlayerOnServer, we have to go with that parameter, as during the play Declarer plays from two positions
        this.props.setIsVisible(convertIntToBridgePosition(this.state.currentPlayerOnServer), true);

        if (!this.isPostMortem()) {
            this.props.setSoundPlaying('yourTurn');
        }
    }

    private process_MID_MADE_PLAY(cmdObj: SFSObject) {
        this.props.setActiveSeat(undefined);
        this.props.setSoundPlaying(undefined);
        if (cmdObj.containsKey('northhand')) {
            this.setHandFromState(cmdObj.getUtfStringArray('northhand'), BridgePosition.north);
        }
        if (cmdObj.containsKey('easthand')) {
            this.setHandFromState(cmdObj.getUtfStringArray('easthand'), BridgePosition.east);
        }
        if (cmdObj.containsKey('southhand')) {
            this.setHandFromState(cmdObj.getUtfStringArray('southhand'), BridgePosition.south);
        }
        if (cmdObj.containsKey('westhand')) {
            this.setHandFromState(cmdObj.getUtfStringArray('westhand'), BridgePosition.west);
        }

        const card: string = cmdObj.getUtfString(SFSVAR.SFSGAME_MID_LOAD); // in the form c7 light need to capitalize it for good measure.
        // const playerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_PLAYER);
        const nextPlayerBridgePosition: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        this.setState({
            currentPlayerOnServer: nextPlayerBridgePosition
        });

        const playedbyseat: BridgePosition = convertIntToBridgePosition(cmdObj.getInt(SFSVAR.SFSGAME_PLAYER));
        const hand: Array<ICard['id']> = [];
        hand.push(convertSuitRank(card));
        this.props.dealHands({ [playedbyseat]: hand });

        this.props.makePlay(convertSuitRank(card));
        if (this.shouldShowDummy()) {
            setTimeout(() => {
                this.props.enableDummy(true);
            }, 300);
        }

        if (this.props.game.seatData[playedbyseat].dummyCards > 0) {
            if (cmdObj.containsKey('ncardsinplayer')) {
                this.setHandFromNumber(cmdObj.getInt('ncardsinplayer'), playedbyseat);
            }
        }
        const { game } = this.props;
        const { gamePhase } = game;
        if (gamePhase === GamePhase.PLAY) {
            // @ts-ignore
            const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
            this.props.setIsHighlighted(convertIntToBridgePosition(nextPlayerBridgePosition), true);
        }

        if (cmdObj.containsKey('message')) {
            const messenger: string = cmdObj.containsKey('messenger') ? cmdObj.getUtfString('messenger') : '';
            this.props.addChatEntry({ timestamp: moment.utc().format(), sender: messenger, message: cmdObj.getUtfString('message') });
        }
        this.updateBoardData(undefined);
    }

    private process_MID_TIME_CLOSE_TRICK(cmdObj: SFSObject) {
        this.props.setActiveSeat(undefined);
        const currentPlayer: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        this.setState({
            currentPlayerOnServer: currentPlayer
        });
        // Move the trick from center to  bottom right
        this.props.setIsHighlighted(convertIntToBridgePosition(currentPlayer), true);
        this.props.closeTrick(convertIntToBridgePosition(cmdObj.getInt(SFSVAR.SFSGAME_CURENT_TRICK_WINNER))); // This is the winner of the trick and leader of the next one.
        this.props.setTrickCount({ ns: cmdObj.getInt('nstr'), ew: cmdObj.getInt('ewtr') });
        this.updateBoardData(undefined);
    }

    // The Score comes in relative to NS, for EW it is Score * (-1).
    // A simple pop up message
    private process_MID_TIME_TO_SCORE(cmdObj: SFSObject) {
        this.props.setActiveSeat(undefined);
        const oblState: SFSObject | undefined = cmdObj.containsKey('oplay') ? cmdObj.getSFSObject('oplay') : undefined;

        const nsTricks: number = cmdObj.containsKey('nstr')
            ? cmdObj.getInt('nstr')
            : oblState && oblState.containsKey('nstr')
            ? oblState.getInt('nstr')
            : 0;
        const ewTricks: number = cmdObj.containsKey('ewtr')
            ? cmdObj.getInt('ewtr')
            : oblState && oblState.containsKey('ewtr')
            ? oblState.getInt('ewtr')
            : 0;

        this.props.setTrickCount({ ns: nsTricks, ew: ewTricks });

        if (oblState) {
            this.setHandsFromState(oblState);
            this.setTrickHistory(oblState);
        }

        const score: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);

        this.setScore(
            score,
            cmdObj.containsKey(SFSVAR.SFSGAME_MID_LOAD_EXTRA) ? cmdObj.getSFSArray(SFSVAR.SFSGAME_MID_LOAD_EXTRA) : undefined,
            cmdObj
        );

        if (
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
        ) {
            this.props.showAuctionBox(false);
        }

        if (cmdObj.containsKey('stats')) {
            const boardStats: BoardStats[] = JSON.parse(new TextDecoder('utf-8').decode(cmdObj.getByteArray('stats')));
            this.props.setBoardStats(boardStats);
            if (cmdObj.containsKey('mystat')) {
                const myStatsKey: BoardStatKeys = JSON.parse(new TextDecoder('utf-8').decode(cmdObj.getByteArray('mystat')));
                this.props.setMyBoardStatKeys(myStatsKey);
            } else {
                this.props.setMyBoardStatKeys({} as BoardStatKeys);
            }
        } else {
            this.props.setBoardStats([]);
            this.props.setMyBoardStatKeys({} as BoardStatKeys);
        }

        //This is to be depricated, should be done on demand.
        if (cmdObj.containsKey('game_result')) {
            let gameJSON: IGameResults | undefined = undefined;
            const pairsData: string = new TextDecoder('utf-8').decode(cmdObj.getByteArray('game_result'));
            const pairs: IGameResultsPair[] = JSON.parse(pairsData);
            const gameData: string = new TextDecoder('utf-8').decode(
                this.state.myLobby.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS).value.getByteArray(SFSVAR.SFSGAME_MID_LOAD)
            );
            gameJSON = JSON.parse(gameData);
            if (gameJSON) {
                gameJSON.Pairs = pairs;
                gameJSON.myPairId = undefined;
            }
            this.props.setGameResults(gameJSON);
        }
        this.updateBoardData(undefined);
        //  console.log('Board Data Object, ', JSON.stringify(this.props.game.foreignBoardReviewData));
    }

    // Server asks for Undo approval
    private process_MID_UNDO_NOTIFICATION(cmdObj: SFSObject) {
        // TODO Show Accept/Deny confirmation message, in both cases need a return method with boolean paylod.
        this.showModal(getUndoNotificationConfig(convertIntToBridgePosition(cmdObj.getInt(SFSVAR.SFSGAME_SEAT_ID))));
    }

    // Server asks for Claim approval
    private process_MID_CLAIM_NOTIFICATION(cmdObj: SFSObject) {
        // TODO Show Accept/Decline confirmation message, in both cases need a return method with boolean paylod.
        this.setHandsFromState(cmdObj);
        const tricks: number = cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD);
        const seat: number = cmdObj.getInt(SFSVAR.SFSGAME_SEAT_ID);
        this.setHandVisibility(31);
        this.showModal(getClaimNotificationConfig(convertIntToBridgePosition(seat), tricks));
    }

    // Server asks for Claim approval
    private process_MID_ACTION_CONTROL(cmdObj: SFSObject) {
        // TODO Show Accept/Decline confirmation message, in both cases need a return method with boolean paylod.
        const stopAction: boolean = cmdObj.getBool(SFSVAR.SFSGAME_MID_LOAD);
        const showNextDealButton: boolean = cmdObj.containsKey('snextb') ? cmdObj.getBool('snextb') : false;
        this.setState({ userActed: false });
        if (showNextDealButton) {
            this.props.showButtons(['startPlay']);
        } else {
            this.setStopActionControl(stopAction);
            this.props.blockGameByTeacher(stopAction);
        }

        if (cmdObj.containsKey('r_auction')) {
            //    console.log("Contract ", cmdObj.getDump(true));
            try {
                const auction: string[] = cmdObj.getUtfStringArray('r_auction');
                const calls: IBid[] = [];
                auction.forEach(call => {
                    calls.push(convertStringToBid(call));
                });
                this.updateBoardData({
                    auction: calls,
                    tricks: [],
                    winner: [],
                    declarer: BridgePosition.north
                });
            } catch (Exception) {}
        }

        if (cmdObj.containsKey('message')) {
            const sfsObj = new SFSObject();
            sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
            sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
            const id = 'nextBoard';

            this.showModal({
                id,
                noClickOutside: true,
                noHeaderClose: true,
                body: [<div dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('message')) }} />],
                cancelButtonLabel: <Translate contentKey="modal.ok" />,
                onCancel: () => store.dispatch(removeModal(id)),
                buttons: showNextDealButton
                    ? [
                          {
                              label: <Translate contentKey="tray.nextBoard" />,
                              primary: true,
                              onClick: () => {
                                  this.sendRoomCommand(SFSVAR.CMD_NEXT_DEAL, new SFSObject());
                                  this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
                                  store.dispatch(removeModal(id));
                              }
                          }
                      ]
                    : [],
                className: id,
                showForeignBoardReviewData: true,
                fullSize: true
            });
        }
    }

    private process_MID_END_OF_SET(cmdObj: SFSObject) {
        if (cmdObj.containsKey('message') || this.state.farewellMessage !== undefined) {
            const modalview: IModal = {
                noClickOutside: true,
                noHeaderClose: true,
                header: this.props.game.metaData.eventName,
                body: [
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: cmdObj.getUtfString('message') }} />
                        <p dangerouslySetInnerHTML={{ __html: this.state.farewellMessage ? this.state.farewellMessage : '' }} />
                        <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('oe')) }} />
                    </div>
                ],
                cancelButtonLabel: <Translate contentKey="modal.ok" />,
                onCancel: () => {
                    this.props.game.gameResults || this.props.game.gameResultsV2
                        ? this.showModal({
                              ...getGameResultBlockingModalConfig(
                                  this.props.game.gameResultsV2 ? this.props.game.gameResultsV2 : this.props.game.gameResults,
                                  !!this.props.game.gameResultsV2
                              ),
                              onCancel: () => this.updateBoardData(undefined)
                          })
                        : (window.location.href =
                              this.props.game.metaData.resultUrl + '&me=' + this.sfs.mySelf.getVariable(SFSVAR.SFS_NICKNAME).value);
                }
            };
            this.showModal(modalview);
        } else {
            this.showModal(
                getAlert([
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: 'This was the last hand of this set.' }} />
                    </div>
                ])
            );
        }
    }

    // Helper methods for processing server messages.

    //This is helper function that sends game logic
    private sendRoomCommand(cmd: string, params: SFSObject) {
        //  console.log("Send Remote Command:" ,cmd, params );
        if (!this.sfs.lastJoinedRoom.isGame) {
            return;
        }
        this.sfs.send(new ExtensionRequest(cmd, params, this.sfs.lastJoinedRoom));
    }

    private sendLobbyCommand(cmd: string, params: SFSObject) {
        if (!this.sfs.lastJoinedRoom.isGame) {
            return;
        }
        this.sfs.send(new ExtensionRequest(cmd, params, this.state.myLobby));
    }

    private setTrayNames(room: SFSRoom) {
        this.props.setPlayerNames({
            [BridgePosition.north]: room.containsVariable(SFSVAR.SFSGAME_NORTH_PLAYER_NAME)
                ? room.getVariable(SFSVAR.SFSGAME_NORTH_PLAYER_NAME).value
                : 'Robot',
            [BridgePosition.east]: room.containsVariable(SFSVAR.SFSGAME_EAST_PLAYER_NAME)
                ? room.getVariable(SFSVAR.SFSGAME_EAST_PLAYER_NAME).value
                : 'Robot',
            [BridgePosition.south]: room.containsVariable(SFSVAR.SFSGAME_SOUTH_PLAYER_NAME)
                ? room.getVariable(SFSVAR.SFSGAME_SOUTH_PLAYER_NAME).value
                : 'Robot',
            [BridgePosition.west]: room.containsVariable(SFSVAR.SFSGAME_WEST_PLAYER_NAME)
                ? room.getVariable(SFSVAR.SFSGAME_WEST_PLAYER_NAME).value
                : 'Robot'
        });
    }

    private setHandsFromState(state: SFSObject) {
        if (state.containsKey('northhand') || state.containsKey('nh')) {
            const hand: string[] = state.containsKey('nh') ? state.getUtfStringArray('nh') : state.getUtfStringArray('northhand');
            if (hand.length === 1 && hand[0].length === 0) {
                if (state.containsKey('nncards')) {
                    this.setHandFromNumber(state.getInt('nncards'), BridgePosition.north);
                }
            } else {
                this.setHandFromState(hand, BridgePosition.north);
            }
        } else if (state.containsKey('nncards')) {
            this.setHandFromNumber(state.getInt('nncards'), BridgePosition.north);
        }

        if (state.containsKey('southhand') || state.containsKey('sh')) {
            const hand: string[] = state.containsKey('sh') ? state.getUtfStringArray('sh') : state.getUtfStringArray('southhand');
            if (hand.length === 1 && hand[0].length === 0) {
                if (state.containsKey('sncards')) {
                    this.setHandFromNumber(state.getInt('sncards'), BridgePosition.south);
                }
            } else {
                this.setHandFromState(hand, BridgePosition.south);
            }
        } else if (state.containsKey('sncards')) {
            this.setHandFromNumber(state.getInt('sncards'), BridgePosition.south);
        }

        if (state.containsKey('eh') || state.containsKey('easthand')) {
            const hand: string[] = state.containsKey('eh') ? state.getUtfStringArray('eh') : state.getUtfStringArray('easthand');
            if (hand.length === 1 && hand[0].length === 0) {
                if (state.containsKey('encards')) {
                    this.setHandFromNumber(state.getInt('encards'), BridgePosition.east);
                }
            } else {
                this.setHandFromState(hand, BridgePosition.east);
            }
        } else if (state.containsKey('encards')) {
            this.setHandFromNumber(state.getInt('encards'), BridgePosition.east);
        }

        if (state.containsKey('wh') || state.containsKey('westhand')) {
            const hand: string[] = state.getUtfStringArray('wh') ? state.getUtfStringArray('wh') : state.getUtfStringArray('westhand');
            if (hand.length === 1 && hand[0].length === 0) {
                if (state.containsKey('wncards')) {
                    this.setHandFromNumber(state.getInt('wncards'), BridgePosition.west);
                }
            } else {
                this.setHandFromState(hand, BridgePosition.west);
            }
        } else if (state.containsKey('wncards')) {
            this.setHandFromNumber(state.getInt('wncards'), BridgePosition.west);
        }
        if (
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
        ) {
            if (state.containsKey('nprops') && state.getSFSObject('nprops').containsKey('hcp')) {
                this.props.setPlayerHCP({
                    [BridgePosition.north]: state.getSFSObject('nprops').getInt('hcp'),
                    [BridgePosition.east]: state.getSFSObject('eprops').getInt('hcp'),
                    [BridgePosition.south]: state.getSFSObject('sprops').getInt('hcp'),
                    [BridgePosition.west]: state.getSFSObject('wprops').getInt('hcp')
                });

                if (state.getSFSObject('nprops').getBool('faceup')) {
                    this.props.setDummyCards(BridgePosition.north, 0);
                    this.props.setIsVisible(BridgePosition.north, true);
                }

                if (state.getSFSObject('sprops').getBool('faceup')) {
                    this.props.setDummyCards(BridgePosition.south, 0);
                    this.props.setIsVisible(BridgePosition.south, true);
                }

                if (state.getSFSObject('eprops').getBool('faceup')) {
                    this.props.setDummyCards(BridgePosition.east, 0);
                    this.props.setIsVisible(BridgePosition.east, true);
                }

                if (state.getSFSObject('wprops').getBool('faceup')) {
                    this.props.setDummyCards(BridgePosition.west, 0);
                    this.props.setIsVisible(BridgePosition.west, true);
                }
            } else {
                this.props.setPlayerHCP({
                    [BridgePosition.north]: sfsInt(state, 'nhcp', undefined),
                    [BridgePosition.east]: sfsInt(state, 'ehcp', undefined),
                    [BridgePosition.south]: sfsInt(state, 'shcp', undefined),
                    [BridgePosition.west]: sfsInt(state, 'whcp', undefined)
                });
            }
        }
    }

    private setHandFromState(cards: string[], bridgePosition: string) {
        const hand: Array<ICard['id']> = [];
        cards.forEach(card => {
            hand.push(convertSuitRank(card));
        });
        // @ts-ignore
        this.props.setDummyCards(BridgePosition[bridgePosition], 0);
        this.props.dealHands({ [bridgePosition]: hand });
    }

    private setHandFromNumber(ncards: number, bridgePosition: BridgePosition) {
        this.props.setDummyCards(bridgePosition, ncards);
    }

    private setAuctionFromState(state: SFSObject) {
        const dealer: BridgePosition = convertIntToBridgePosition(state.getInt('dealer'));
        const vuln: number = state.getInt('vuln');
        const auction: number[] = state.getIntArray('auction');
        const calls: IBid[] = [];
        const playerBridgePosition: number = state.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        let expl: any = undefined;
        if (state.containsKey('auctionExpl')) {
            expl = JSON.parse(new TextDecoder().decode(state.getByteArray('auctionExpl')));
            //            console.log("expl : ", expl);
        }
        this.setState({
            currentPlayerOnServer: playerBridgePosition
        });
        this.setContractFromSFSObject(state.getSFSObject('contract'));
        if (
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value
        ) {
            this.props.resetGamePartials(['auction']);
        } else {
            auction.forEach(call => {
                const index: number | undefined = expl && expl[(calls.length as unknown) as string] ? calls.length + 1 : undefined;
                const ibid: IBid = convertIntToBid(
                    call,
                    index ? JSON.parse(String.fromCharCode(...expl[((index - 1) as unknown) as string])) : undefined
                );
                if (ibid.explanation && 'alert' in ibid.explanation) {
                    ibid.alertMessage = lineForSimpleString('alert', ibid.explanation);
                    this.props.addChatEntry({ timestamp: moment.utc().format(), sender: 'Alert!', message: ibid.alertMessage });
                }
                //          console.log("Expl for ", calls.length, ibid.explanation)
                calls.push(ibid);
            });
        }

        this.props.setVulnerability(convertIntToVulnerabilty(vuln));
        this.props.setDealer(dealer);
        this.props.setAuction(calls);
    }

    private setContractFromSFSObject(contract: SFSObject) {
        if (this.shouldShowReplay()) {
            this.props.showButtons(['replay']);
        }
        if (contract.getBool('passed')) {
            this.props.removeModal('NonBlockingSpalash');
            //We are at the ed of passed out deal, the End of the hand logic should kick in, when we figured what is it
            //There is no declarer, not stakes, just 4 passes in the auction.
            if (this.state.competemode) {
                this.props.hideButtons(['undo', 'claim']);
            }
            this.props.setGamePhase(GamePhase.END);
            return;
        }

        if (contract.getInt('declarer') === -1) {
            this.props.setGamePhase(GamePhase.BID);
            if (this.shouldShowUndo()) {
                this.props.showButtons(['undo']);
            }
            return;
        }

        this.props.removeModal('NonBlockingSpalash');
        const declInt: number = contract.getInt('declarer');
        const declarer: BridgePosition = convertIntToBridgePosition(declInt);
        const dummy: BridgePosition = convertIntToBridgePosition(declInt ^ 2);
        const stake: number = contract.getInt('stake');
        const levelIndex: number = contract.getInt('level');
        const strainIndex: number = contract.getInt('trump'); //Use that one for proper suit rotation in the hand
        const nextPlayerBridgePosition: number = contract.getInt('leader');

        this.setState({
            currentPlayerOnServer: contract.getInt('leader'),
            isDummy: false
        });

        const stakes: { [id: number]: undefined | IBid } = {
            1: undefined,
            2: { level: 'zero', strain: 'double' },
            4: { level: 'zero', strain: 'redouble' }
        };

        this.props.setContract({
            contract: {
                level: levels[levelIndex],
                strain: strains[strainIndex]
            },
            stake: stakes[stake],
            declarer
        });
        /*
            if (this.props.game.gamePhase !== GamePhase.DEAL) {
              this.props.setActiveSeat(convertIntToBridgePosition(nextPlayerBridgePosition));
            }

             */
        this.props.setIsHighlighted(convertIntToBridgePosition(nextPlayerBridgePosition), true);
        this.props.setGamePhase(GamePhase.PLAY);
        // TODO make declarer visible if we are dummy
        const { game } = this.props;
        // TODO Looking at Michael's screen was not good enough :), error suppression will do for tonight.
        // @ts-ignore
        const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
        if (mybridgeposition === dummy && this.shouldShowDummy() && this.props.game.gamePhase >= GamePhase.PLAY) {
            this.setState({
                isDummy: true
            });
            this.props.setIsVisible(declarer, true);
            this.props.hideButtons(['undo', 'claim']);
        } else if (this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) > 0) {
            if (this.shouldShowClaim()) {
                this.props.showButtons(['claim']);
            }
            if (this.shouldShowUndo()) {
                this.props.showButtons(['undo']);
            }
        }
    }

    private setLineOfPlayFromState(state: SFSObject) {
        const lineOfPlay: SFSArray = state.getSFSArray('play');
        let winners: BridgePosition[] = [];
        for (let i = 0; i < lineOfPlay.size(); i++) {
            const card: SFSObject = lineOfPlay.getSFSObject(i);

            const playedbyseat: number = card.getInt('p');
            const localcard: string = convertSuitRank(card.getUtfString('c'));
            const hand: Array<ICard['id']> = [];
            hand.push(localcard);
            this.props.dealHands({ [convertIntToBridgePosition(playedbyseat)]: hand });
            this.props.makePlay(localcard);
            if (i === 0) {
                if (this.shouldShowDummy()) {
                    this.props.enableDummy(true);
                }
            }
            if (card.getBool('w')) {
                winners.push(convertIntToBridgePosition(card.getInt('p')));
            }
        }

        this.props.setWinners(winners);
        this.props.setTrickCount({ ns: state.getInt('nstr'), ew: state.getInt('ewtr') });
        const playerBridgePosition: number = state.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        this.setState({
            currentPlayerOnServer: playerBridgePosition
        });
    }

    private setHighLightedCards(state: SFSObject) {
        const cards: Array<ICard['id']> = [];
        if (state.containsKey('highlights')) {
            const hcards: string[] = state.getUtfStringArray('highlights');
            if (!hcards) {
                return;
            }

            hcards.forEach(card => {
                cards.push(convertSuitRank(card.toUpperCase()));
            });
        }
        if (state.containsKey('highlightStr')) {
            this.setCardStates(state.getUtfString('highlightStr'), cards);
        }
        if (cards.length > 0) {
            this.props.raiseCards(cards);
        }
    }

    private setCardStates(hand: String, cards: Array<ICard['id']>) {
        let startWithSuit: number = 3;
        try {
            let h: boolean = false;
            // @ts-ignore
            const chars = [...hand];
            chars.forEach((c, i) => {
                if (c === '!') {
                    h = true;
                } else if (c === '^') {
                } else if (c === '$') {
                } else if (c === '^') {
                } else if (c === '.') {
                    startWithSuit--;
                    h = false;
                } else if (c === ' ') {
                    startWithSuit = 3;
                    h = false;
                } else if (c === '1') {
                } else {
                    //it must be card
                    if (h) {
                        if (c === '0' || c === 'T') {
                            cards.push(convertSuitRank(convertIntToSuitStr(startWithSuit) + 'T'));
                        } else {
                            cards.push(convertSuitRank(convertIntToSuitStr(startWithSuit) + c.toUpperCase()));
                        }
                        h = false;
                    }
                }
            });
        } finally {
        }
    }

    private setHandVisibility(visibility: number) {
        console.log('Visibility to chage to ', visibility);
        const bridgeRulesOn: boolean = (visibility & 1) !== 0;

        this.setState({
            visibility: visibility
        });
        if (visibility === 0) {
            this.props.forceHideMe(true);
            this.props.setIsVisible(BridgePosition.north, false);
            this.props.setIsVisible(BridgePosition.east, false);
            this.props.setIsVisible(BridgePosition.south, false);
            this.props.setIsVisible(BridgePosition.west, false);
            this.props.enableDummy(false);
            return;
        }

        this.props.setIsVisible(BridgePosition.north, (visibility & 2) !== 0);
        this.props.setIsVisible(BridgePosition.east, (visibility & 4) !== 0);
        this.props.setIsVisible(BridgePosition.south, (visibility & 8) !== 0);
        this.props.setIsVisible(BridgePosition.west, (visibility & 16) !== 0);
        const { game } = this.props;

        if (bridgeRulesOn) {
            this.props.enableDummy(this.shouldShowDummy());
            this.props.forceHideMe(false);
            if (this.state.isDummy && this.shouldShowDummy()) {
                // @ts-ignore
                const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
                this.props.setIsVisible(convertDummyToDeclarer(mybridgeposition), true);
                // @ts-ignore
                const dummyPosition: BridgePosition = getDummyBridgePosition(game.seatData).bridgePosition;
                this.props.setIsVisible(convertDummyToDeclarer(dummyPosition), true);
            }
        } else {
            // @ts-ignore
            const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
            if (mybridgeposition === BridgePosition.north && !(BridgePosition.north, (visibility & 2) !== 0)) {
                this.props.forceHideMe(true);
            } else if (mybridgeposition === BridgePosition.east && !(BridgePosition.east, (visibility & 4) !== 0)) {
                this.props.forceHideMe(true);
            } else if (mybridgeposition === BridgePosition.south && !(BridgePosition.south, (visibility & 8) !== 0)) {
                this.props.forceHideMe(true);
            } else if (mybridgeposition === BridgePosition.west && !(BridgePosition.west, (visibility & 16) !== 0)) {
                this.props.forceHideMe(true);
            } else {
                this.props.forceHideMe(false);
            }

            this.props.enableDummy(false);
        }
    }

    private setScore(score: number, stats: SFSArray | undefined, cmdObj: SFSObject | undefined) {
        if (cmdObj && cmdObj.containsKey('callback_url')) {
            window.location.href = cmdObj.getUtfString('callback_url');
            return;
        }
        this.setHandVisibility(31);
        const { game } = this.props;
        // TODO Looking at Michael's screen was not good enough :), error suppression will do for tonight.
        // @ts-ignore

        const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
        let score_str: string = 'Passed out hand, your score is 0';
        if (score !== 0 && (mybridgeposition === BridgePosition.east || mybridgeposition === BridgePosition.west)) {
            score_str = 'Score is : ' + score * -1;
        } else if (score !== 0) {
            score_str = 'Score is : ' + score;
        }

        const showNotes: boolean = cmdObj ? cmdObj.containsKey('commentatscore') && cmdObj.getBool('commentatscore') : false;
        const { app } = this.props;
        if (app.urlParams['noscore'] === undefined) {
            this.showModal({
                id: 'scoreModal',
                body: showNotes
                    ? [
                          <div>
                              <p dangerouslySetInnerHTML={{ __html: insertSuits(this.props.game.metaData.comment ?? '') }} />
                          </div>,
                          <BoardStatsComponent />
                      ]
                    : [<BoardStatsComponent />],
                header: score_str,
                noCancel: true,
                buttons: [
                    {
                        label: <Translate contentKey="table.metaData.boardReview" />,
                        onClick: () => {
                            this.props.removeCurrentModal();
                            this.showBoardReview();
                        }
                    },
                    {
                        label:
                            this.state.competemode && this.state.hasNext ? (
                                'Next hand'
                            ) : this.state.competemode ? (
                                'Continue'
                            ) : this.state.hasNext ? (
                                'Next hand'
                            ) : (
                                <Translate contentKey="modal.ok" />
                            ),
                        onClick: () => {
                            this.state.competemode || this.state.hasNext ? this.clientRequestsNextBoard() : this.props.removeCurrentModal();
                        }
                    }
                ]
            });
        }

        this.props.hideButtons(['claim']);
        if (this.state.competemode) {
            this.props.hideButtons(['undo']);
        }

        this.props.setGamePhase(GamePhase.END);
        if (this.shouldShowStop()) {
            this.props.showButtons(['startPlay']);
            this.props.hideButtons(['stopPlay']);
        }
        if (!this.state.competemode && this.state.hasNext) {
            this.props.showButtons(['next']);
        }
    }

    private showBoardReview = () => {
        this.showModal({
            id: 'showBoardReviewModal',
            body: [<BoardReview />],
            noCancel: true,
            buttons: [
                {
                    label: <Translate contentKey="table.boardReview.ok" />,
                    onClick: () => this.props.removeCurrentModal()
                }
            ]
        });
    };

    private setTrickHistory(state: SFSObject) {
        if (state === null) {
            return;
        }
        const lineOfPlay: SFSArray = state.getSFSArray('play');
        if (lineOfPlay === undefined || lineOfPlay.size() === 0) {
            return;
        }
        let winners: BridgePosition[] = [];
        let cards: ICard[][] = [];
        for (let i = 0; i < lineOfPlay.size(); i += 4) {
            const ntrick: number = Math.ceil(i / 4);
            for (let j = i; j < 4; j++) {
                if (j >= lineOfPlay.size()) {
                    break;
                }
                const card: SFSObject = lineOfPlay.getSFSObject(j);
                // TODO we have declarer interface but without at least one declarer implementation how do I create new object of type ICard.
                //const icard: ICard = card.getUtfString('c');
                //cards[ntrick][j] = icard;
                if (card.getBool('w')) {
                    winners.push(convertIntToBridgePosition(card.getInt('p')));
                }
            }
        }
        // this.props.injectTrickHistory(cards ,winners);
    }

    private setStopActionControl(value: boolean) {
        if (value) {
            if (this.shouldShowStop()) {
                this.props.showButtons(['startPlay']);
                this.props.hideButtons(['stopPlay']);
            } else {
                this.props.hideButtons(['startPlay', 'stopPlay']);
            }
        } else {
            if (this.shouldShowStop()) {
                this.props.hideButtons(['startPlay']);
                this.props.showButtons(['stopPlay']);
            } else {
                this.props.hideButtons(['startPlay', 'stopPlay']);
            }
        }
    }

    //Process client actions, make sure this.props.resetOutput(); is called when the action is received.

    //Game requests sent to server
    private clientRequestsMakeCall = (bid: IBid) => {
        this.props.resetOutput();
        if (
            !this.isPostMortem() &&
            convertPlayerToIntBridgePosition(this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom)) !== this.state.currentPlayerOnServer
        ) {
            return;
        }
        this.setState({
            userActed: true
        });
        this.props.setActiveSeat(undefined);
        //Disable Bidding Keyboard
        this.props.setEnabledBiddingBox(false);
        const sfsObj = new SFSObject();
        //IMPORTANT Call need to have Int parameter, one of  /* Call intValue constants */
        sfsObj.putInt(SFSVAR.SFSGAME_MID_LOAD, convertBidToInt(bid));
        sfsObj.putBool(SFSVAR.SFSGAME_MID_ROBOT, false);
        if (bid.alertMessage !== undefined) {
            sfsObj.putUtfString(SFSVAR.SFSGAME_MID_ALERT, bid.alertMessage);
        }
        sfsObj.putInt(SFSVAR.SFSGAME_CURENT_PLAYER, this.state.currentPlayerOnServer);
        this.sendRoomCommand(SFSVAR.CMD_MAKE_CALL, sfsObj);
        if (this.shouldShowUndo()) {
            this.props.showButtons(['undo']);
        }
        const isMiniBridge: boolean =
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value;
        if (isMiniBridge) {
            this.props.removeCurrentModal();
        }
    };

    private clientRequestsMakePlay = (card: ICard) => {
        this.props.resetOutput();
        const { game } = this.props;
        const { gamePhase } = game;
        if (
            !this.isPostMortem() &&
            (gamePhase !== GamePhase.PLAY || card.bridgePosition !== convertIntToBridgePosition(this.state.currentPlayerOnServer))
        ) {
            return;
        }
        this.setState({
            userActed: true
        });
        this.props.clearSystemChat();
        this.props.setActiveSeat(undefined);
        const sfsObj = new SFSObject();
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, convertToPbn(card.id));
        sfsObj.putBool(SFSVAR.SFSGAME_MID_ROBOT, false);
        sfsObj.putInt(SFSVAR.SFSGAME_CURENT_PLAYER, this.state.currentPlayerOnServer);
        this.sendRoomCommand(SFSVAR.CMD_MAKE_PLAY, sfsObj);
        if (this.shouldShowClaim()) {
            this.props.showButtons(['claim']);
        }
        if (this.shouldShowUndo()) {
            this.props.showButtons(['undo']);
        }
    };

    private clientRequestsDealCard = (dealCard: IOutputState['dealCard']) => {
        this.props.resetOutput();
        if (!dealCard) {
            return;
        }
        const cardStr: string = convertToPbn(dealCard.suit + '.' + dealCard.rank);
        const sfsObj = new SFSObject();
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, cardStr);
        if (dealCard.seat === undefined) {
            this.sendRoomCommand(SFSVAR.CMD_REMOVE_CARD, sfsObj);
            return;
        }
        sfsObj.putInt(SFSVAR.SFSGAME_MID_LOAD_EXTRA, convertBridgePositionToInt(dealCard.seat));
        this.sendRoomCommand(SFSVAR.CMD_ADD_CARD, sfsObj);
    };

    // Client request to Undo
    private clientRequestsUndo = () => {
        this.props.resetOutput();
        if (this.props.game.isBlockedByTeacher) {
            this.props.removeCurrentModal();
            return;
        }
        const sfsObj = new SFSObject();
        this.sendRoomCommand(SFSVAR.CMD_REQUEST_UNDO, sfsObj);
        this.props.removeCurrentModal();
    };

    // Client responds request to undo
    private clientResponseUndo(response: string) {
        this.props.resetOutput();
        if (this.props.game.isBlockedByTeacher) {
            return;
        }
        const resp: boolean = response === 'approve';
        // TODO send notification to server
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_UNDO_RESPONSE);
        sfsObj.putBool(SFSVAR.UNDO_RESPONSE, response === 'approve');
        sfsObj.putInt(SFSVAR.SFSGAME_SEAT_ID, convertPlayerToIntBridgePosition(this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom)));
        this.sendRoomCommand(SFSVAR.CMD_RESPONSE_UNDO, sfsObj);
        // this.props.removeCurrentModal();
    }

    // Client claims tricks
    private clientRequestsClaim = (claim: number) => {
        this.props.resetOutput();
        if (this.props.game.isBlockedByTeacher) {
            this.props.removeCurrentModal();
            return;
        }
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.SFSGAME_MID_LOAD, claim);
        sfsObj.putInt(SFSVAR.SFSGAME_CURENT_PLAYER, this.state.currentPlayerOnServer);
        this.sendRoomCommand(SFSVAR.CMD_REQUEST_CLAIM, sfsObj);
        this.props.hideButtons(['claim']);
        this.props.removeCurrentModal();
    };

    // Client claims tricks
    private clientRequestsDirector = (message: string) => {
        this.props.resetOutput();
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, true);
        this.sfs.send(new PublicMessageRequest('Director call: ' + message, sfsObj));
    };

    private clientRequestsStartPlay = () => {
        this.props.resetOutput();
        this.props.hideButtons(['startPlay']);
        //  this.props.showButtons(['stopPlay']);
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
        this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
    };

    private clientRequestsStopPlay = () => {
        this.props.showButtons(['startPlay']);
        this.props.hideButtons(['stopPlay']);
        this.props.resetOutput();
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, true);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
        this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
    };

    private clientRequestsReplay = () => {
        this.props.resetOutput();
        if (this.props.game.isBlockedByTeacher) {
            return;
        }
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
        this.sendRoomCommand(SFSVAR.CMD_REPLAY_DEAL, sfsObj);
    };

    private clientRequestsNextBoard = () => {
        this.props.resetOutput();
        if (this.props.game.isBlockedByTeacher) {
            return;
        }
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
        if (this.state.userActed && this.props.game.gamePhase < GamePhase.END) {
            const id = 'nextBoard';
            this.showModal({
                id,
                noClickOutside: true,
                noHeaderClose: true,
                body: [<div>Are you sure you want to change deals? All of your progress on this deal will be lost.</div>],
                cancelButtonLabel: <Translate contentKey="modal.cancel" />,
                onCancel: () => store.dispatch(removeModal(id)),
                buttons: [
                    {
                        label: <Translate contentKey="modal.ok" />,
                        primary: true,
                        onClick: () => {
                            this.sendRoomCommand(SFSVAR.CMD_NEXT_DEAL, new SFSObject());
                            this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
                            store.dispatch(removeModal(id));
                        }
                    }
                ],
                className: id
            });
        } else {
            this.sendRoomCommand(SFSVAR.CMD_NEXT_DEAL, new SFSObject());
            this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
        }
    };

    private clientRequestsPreviousBoard = () => {
        this.props.resetOutput();
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);

        if (this.state.userActed && this.props.game.gamePhase < GamePhase.END) {
            const id = 'prevBoard';
            this.showModal({
                id,
                noClickOutside: true,
                noHeaderClose: true,
                body: [<div>Are you sure you want to change deals? All of your progress on this deal will be lost.</div>],
                cancelButtonLabel: <Translate contentKey="modal.cancel" />,
                onCancel: () => store.dispatch(removeModal(id)),
                buttons: [
                    {
                        label: <Translate contentKey="modal.ok" />,
                        primary: true,
                        onClick: () => {
                            this.sendRoomCommand(SFSVAR.CMD_PREV_DEAL, new SFSObject());
                            this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
                            store.dispatch(removeModal(id));
                        }
                    }
                ],
                className: id
            });
        } else {
            this.sendRoomCommand(SFSVAR.CMD_PREV_DEAL, new SFSObject());
            this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
        }
    };

    private clientRequestBidExplanation = (bidIndex: number): void => {
        if (bidIndex > this.props.game.auction.length) return;
        const expl = this.props.game.auction[bidIndex].explanation;
        console.log('Explanations', expl);
        console.log('Alerts', this.props.game.auction[bidIndex].alertMessage);

        if (!expl) return;
        const call: {} | undefined = 'call' in expl ? expl['call'] : undefined;
        const hand: {} | undefined = 'hand' in expl ? expl['hand'] : undefined;

        let message: string = '';
        if ('alert' in expl) {
            message += '</br>' + lineForSimpleString('alert', expl);
        } else if (call || hand) {
            message += lineForPoints(call, hand) ?? '';
            // lineForMinMaxValue(call, 'minl', 'maxl', 'Losers: ')
            message += lineForSuitEndingWith('spade', call, hand) ?? '';
            message += lineForSuitEndingWith('heart', call, hand) ?? '';
            message += lineForSuitEndingWith('diamond', call, hand) ?? '';
            message += lineForSuitEndingWith('club', call, hand) ?? '';
            message += lineForMinMaxValue('minaces', 'maxaces', 'Aces: ', call) ?? '';
            message += lineForSimpleString('distro', call) ?? '';
            message += lineForSimpleString('conv', call) ?? '';
            if (hand) {
                message += lineForSimpleString('misc', hand) ?? '';
            }
        } else {
            message = 'Natural';
        }

        this.showModal({
            id: 'showCallExplanation',
            body: [
                <div>
                    <p dangerouslySetInnerHTML={{ __html: insertSuits(message) }} />
                </div>
            ],
            header:
                'sm' in expl ? (
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: insertSuits(expl['sm']) }} />
                    </div>
                ) : (
                    ''
                ),
            noCancel: true,
            buttons: [
                {
                    label: <Translate contentKey="modal.ok" />,
                    onClick: () => {
                        this.props.removeCurrentModal();
                    }
                }
            ]
        });
    };

    //Client responds to claim
    private clientResponseClaim(response: string) {
        this.props.resetOutput();
        const resp: boolean = response === 'approve';
        // TODO send notification to server
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CLAIM_RESPONSE);
        sfsObj.putBool(SFSVAR.CLAIM_RESPONSE, response === 'approve');
        sfsObj.putInt(SFSVAR.SFSGAME_SEAT_ID, convertPlayerToIntBridgePosition(this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom)));
        this.sendRoomCommand(SFSVAR.CMD_RESPONSE_CLAIM, sfsObj);
        this.props.removeCurrentModal();
    }

    private sendEmailInvitation(email: string, seat: BridgePosition) {
        this.props.resetOutput();
        const sfsObj = new SFSObject();
        sfsObj.putUtfString('email', email);
        sfsObj.putUtfString('name', 'Guest');
        sfsObj.putUtfString('seat', convertBridgePositionToStr(seat));
        this.sendRoomCommand(SFSVAR.CMD_INVITE_EMAIL, sfsObj);
    }

    private loadBoardReview(g_uuid: string, p_uuid: string, bn: number, c_uuid: string | undefined) {
        if (!this.state.boardData[p_uuid]) {
            const resultUrl: string = `https://duplicate-games.s3.us-east-2.amazonaws.com/${g_uuid}/pairs/${p_uuid}.json`;
            axios
                .get(resultUrl)
                .then(response => {
                    const { data } = response;
                    this.setState({
                        boardData: {
                            ...this.state.boardData,
                            [p_uuid]: data
                        }
                    });
                    this.loadPlayerBoardReview(g_uuid, p_uuid, bn, c_uuid);
                })
                .catch(e => {
                    //S3 bucket failed, must be V1 game result , lets try it.
                    console.log('S3 bucket failed Error', e);
                    /*if (e.response.status === 403) {
                        //we can try to read for SQL
                    }*/
                });
        } else {
            this.loadPlayerBoardReview(g_uuid, p_uuid, bn, c_uuid);
        }
        return;
    }

    //ported to BB
    private loadPlayerBoardReview(g_uuid: string, p_uuid: string, bn: number, c_uuid: string | undefined) {
        const topPair: string | undefined = c_uuid
            ? c_uuid
            : this.props.game.gameResultsV2?.Pairs[0].uuid !== p_uuid
            ? this.props.game.gameResultsV2?.Pairs[0].uuid
            : this.props.game.gameResultsV2?.Pairs[1] && this.props.game.gameResultsV2?.Pairs[1].uuid !== p_uuid
            ? this.props.game.gameResultsV2?.Pairs[1].uuid
            : undefined;
        if (!topPair) {
            const boarddata: ForeignBoardReviewData | undefined = convertS3toForeignBoardReviewData(this.state.boardData[p_uuid], bn, 0);
            boarddata.playerData[1] = {
                tricks: [],
                auction: [],
                winner: [],
                uuid: '',
                declarer: undefined
            };
            this.props.setForeignBoardReviewData(boarddata);
            return;
        }

        if (!this.state.boardData[topPair]) {
            const resultUrl: string = `https://duplicate-games.s3.us-east-2.amazonaws.com/${g_uuid}/pairs/${topPair}.json`;
            axios
                .get(resultUrl)
                .then(response => {
                    const { data } = response;
                    this.setState({
                        boardData: {
                            ...this.state.boardData,
                            [topPair]: data
                        }
                    });
                    this.updateForegndata(g_uuid, p_uuid, bn, topPair);
                })
                .catch(e => {
                    //S3 bucket failed, must be V1 game result , lets try it.
                    console.log('S3 bucket failed Error', e);
                    /*if (e.response.status === 403) {
                        //we can try to read for SQL
                    }*/
                });
        } else {
            this.updateForegndata(g_uuid, p_uuid, bn, topPair);
        }
    }
    //ported to BB
    private updateForegndata(g_uuid: string, p_uuid: string, bn: number, c_uuid: string | undefined) {
        const boarddata: ForeignBoardReviewData | undefined = convertS3toForeignBoardReviewData(this.state.boardData[p_uuid], bn, 0);
        const boarddataComp: ForeignBoardReviewData | undefined = c_uuid
            ? convertS3toForeignBoardReviewData(this.state.boardData[c_uuid], bn, 0)
            : boarddata;
        console.log('Board Data : ', boarddata.playerData.length);
        console.log('Board boarddataComp : ', boarddataComp);
        if (boarddata?.playerData?.length > 0 && boarddataComp?.playerData?.length > 0) {
            boarddata.playerData[1] = boarddataComp.playerData[0];
        }
        this.props.setForeignBoardReviewData(boarddata);
    }

    private sendChatMessage(chatentry: ChatEntry) {
        this.props.resetOutput();
        if (!this.sfs.lastJoinedRoom.isGame) {
            const sfsObj = new SFSObject();
            sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, chatentry.message);
            this.sfs.send(new ExtensionRequest(SFSVAR.CMD_SCREEN_MESSAGE, sfsObj, this.sfs.lastJoinedRoom));
            this.props.addChatEntry({
                timestamp: moment.utc().format(),
                sender: this.sfs.mySelf.containsVariable('pn')
                    ? this.sfs.mySelf.getVariable('pn').value
                    : this.sfs.mySelf.containsVariable('nn')
                    ? this.sfs.mySelf.getVariable('nn').value
                    : this.sfs.mySelf.name,
                message: chatentry.message
            });
        } else if (chatentry.isDirectorMessage) {
            const sfsObj = new SFSObject();
            sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, 'Director');
            this.sfs.send(new PublicMessageRequest(chatentry.message, sfsObj));
        } else if (
            this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_ROOM_IS_SCREENGAME) &&
            this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_ROOM_IS_SCREENGAME).value === true
        ) {
            const sfsObj = new SFSObject();
            sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, chatentry.message);
            this.sendRoomCommand(SFSVAR.CMD_SCREEN_MESSAGE, sfsObj);
        } else {
            this.sfs.send(new PublicMessageRequest(chatentry.message, undefined));
        }
    }

    private sendPollResponse = (pollID: string, answer: number) => {
        this.props.resetOutput();
        const sfsObj = new SFSObject();
        sfsObj.putUtfString('pollID', pollID);
        sfsObj.putInt('answer', answer);
        this.sendLobbyCommand(SFSVAR.SFSGAME_POLL_ANSWER, sfsObj);
    };

    private shouldShowClaim() {
        if (this.isPostMortem()) {
            return false;
        }
        const { game } = this.props;
        const { gamePhase } = game;
        if (gamePhase !== GamePhase.PLAY) {
            return false;
        }
        if (this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) <= 0) {
            return false;
        }
        if (!this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_NOCLAIM)) {
            return true;
        }

        // @ts-ignore
        const mybridgeposition: BridgePosition = getMySeatData(game.seatData).bridgePosition;
        if (mybridgeposition === getDeclarerBridgePosition(game.seatData) || mybridgeposition === getDummyBridgePosition(game.seatData)) {
            return !this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_NOCLAIM).value;
        }
        return false;
    }

    private shouldShowUndo() {
        const { game } = this.props;
        const { gamePhase } = game;
        if (gamePhase !== GamePhase.PRE && gamePhase !== GamePhase.BID && gamePhase !== GamePhase.PLAY) {
            return false;
        }
        if (this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) <= 0) {
            return false;
        }
        if (!this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_NOUNDO)) {
            return true;
        }
        return !this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_NOUNDO).value;
    }

    private shouldShowDummy() {
        if (this.props.game.gamePhase < GamePhase.PLAY) {
            return false;
        }

        if (this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) <= 0) {
            return true;
        }
        if (!this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_NODUMMY)) {
            return true;
        }
        return !this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_NODUMMY).value;
    }

    private shouldShowStop() {
        const { game } = this.props;
        const { gamePhase } = game;
        if (gamePhase !== GamePhase.END) {
            return false;
        }
        if (this.isPostMortem()) {
            return true;
        }
        if (this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) <= 0) {
            return false;
        }
        if (!this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_ALLOW_STUDENT_CONTROL)) {
            return false;
        }
        return this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_ALLOW_STUDENT_CONTROL).value;
    }

    private shouldShowReplay() {
        if (this.isPostMortem()) {
            return true;
        }
        const { game } = this.props;
        const { gamePhase } = game;
        //if (this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) <= 0) return false;
        if (!this.sfs.lastJoinedRoom.containsVariable(SFSVAR.SFSGAME_ALLOW_REPLAY)) {
            return false;
        }
        return this.sfs.lastJoinedRoom.getVariable(SFSVAR.SFSGAME_ALLOW_REPLAY).value;
    }

    private isPostMortem() {
        return (
            this.state.myLobby.containsVariable(SFSVAR.SFSGAME_ROOM_IS_POSPORTEM) &&
            this.state.myLobby.getVariable(SFSVAR.SFSGAME_ROOM_IS_POSPORTEM).value
        );
    }

    private showModal(modal: IModal) {
        this.props.removeCurrentModal();
        this.props.addModal(modal);
    }

    private getGameResultsModalCancel() {
        if (!this) return;
        if (!this.props.game) return;
        this.props.game.gameResults || this.props.game.gameResultsV2
            ? this.showModal({
                  ...getGameResultBlockingModalConfig(
                      this.props.game.gameResultsV2 ? this.props.game.gameResultsV2 : this.props.game.gameResults,
                      !!this.props.game.gameResultsV2
                  ),
                  onCancel: () => this.updateBoardData(undefined)
              })
            : (window.location.href = this.props.game.metaData.resultUrl + '&me=' + this.sfs.mySelf.getVariable(SFSVAR.SFS_NICKNAME).value);
    }
}

const mapStateToProps = ({ app, game, output }: IRootState) => ({
    app,
    game,
    output
});

const mapDispatchToProps = {
    addChatEntry,
    addModal,
    appendAuction,
    clearChat,
    clearSystemChat,
    closeTrick,
    dealHands,
    displayActiveSeat,
    enableDummy,
    hideButtons,
    injectTrickHistory,
    makePlay,
    removeCurrentModal,
    removeModal,
    resetApp,
    resetGame,
    resetGamePartials,
    resetGameExcept,
    resetOutput,
    setActiveSeat,
    setAuction,
    setConnectionStatus,
    setContract,
    setDealer,
    setDoubleValid,
    setEnabledBiddingBox,
    setGamePhase,
    setIsVisible,
    setIsHighlighted,
    setJitsi,
    setTwitch,
    setJitsiFullSize,
    setMyBridgePosition,
    setLoggedIn,
    setLogInError,
    setPlayerHCP,
    setPlayerNames,
    setTableId,
    setTrickCount,
    setVulnerability,
    showAuctionBox,
    showButtons,
    showChatSendForm,
    showClosedTricks,
    showInvites,
    showTrickHistory,
    requestLogin,
    forceHideMe,
    setBoard,
    setMetaData,
    setTimeToTournament,
    setTimeToGameEnd,
    setWinners,
    blockGameByTeacher,
    setDummyCards,
    setSoundPlaying,
    setGameResults,
    setGameResultsV2,
    raiseCards,
    unRaiseCards,
    setPoll,
    unsetF2F,
    setIframe,
    clearMyHighlightedCards,
    setBoardStats,
    setMyBoardStatKeys,
    getGameResultModalConfig,
    getGameResultBlockingModalConfig,
    useTranslation,
    setForeignBoardReviewData,
    setForeignBoardReviewPlayerData
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GameEngineInterface);
