import axios from 'axios';
import qs from 'querystring';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { v4 as uuidv4 } from 'uuid';
import { LabeledInput } from '../../_basics/labeled-input/labeled-input';

import { IRootState } from '../../store/_root.reducer';
import { requestLogin, setDummyMode, setLoggedIn, setUrlParams, setUuid } from '../../store/app.reducer';

import './login.scss';
import { Loading } from '../loading/loading';
import { BridgePosition } from '../../_models/models';

interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps {}

export const Login: FunctionComponent<ILoginProps> = props => {
    const { t } = useTranslation();
    const [storedUuid, setStoredUuid] = useLocalStorage('uuid', undefined);
    const [storedUser, setStoredUser] = useLocalStorage('user', undefined);
    const [storedPartner, setStoredPartner] = useLocalStorage('partner', undefined);
    const { location, sfsteacher, apptoken, loginError, urlParams } = props;

    // Login stuff
    const [user, setUser] = useState<string>(storedUser || '');
    const [partner, setPartner] = useState<string>(storedPartner || '');
    const [userFirstName, setUserFirstName] = useState<string>(storedUser.split(' ')[0] || '');
    const [userLastInitial, setUserLastInitial] = useState<string>(storedUser.split(' ')[1] || '');
    const [partnerFirstName, setPartnerFirstName] = useState<string>(storedPartner.split(' ')[0] || '');
    const [partnerLastInitial, setPartnerLastInitial] = useState<string>(storedPartner.split(' ')[1] || '');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(!!storedUser);
    const [tableNumber, setTableNumber] = useState<number>();
    const [bridgePosition, setBridgePosition] = useState<BridgePosition>();

    // register stuff
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        if (urlParams.gameResults || urlParams.gameResultsV2) {
            props.setLoggedIn();
            props.setDummyMode();
        }
    }, []);

    const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        if (!checked) {
            setStoredUser('');
            setStoredPartner('');
        }
        setRememberMe(e.target.checked);
    };

    const userLogin = (_user?: string, _partner?: string) => {
        if (rememberMe) {
            setStoredUser(_user || user || '');
            setStoredPartner(_partner || partner);
        }
        const uuid = storedUuid || uuidv4();
        setStoredUuid(uuid);
        props.requestLogin({
            user: _user || user,
            token: 'token',
            uuid,
            rememberMe,
            ...(urlParams.f2f == null || urlParams.nli != null ? { partner: _partner || partner } : {}),
            ...(urlParams.f2f != null ? { bridgePosition } : {}),
            ...(urlParams.f2f != null ? { tableNumber } : {})
        });
    };

    const onSubmitUserLogin = (e: React.MouseEvent<HTMLFormElement, MouseEvent>) => {
        e.preventDefault();
        userLogin();
    };

    const onSubmitUserLoginNli = (e: React.MouseEvent<HTMLFormElement, MouseEvent>) => {
        e.preventDefault();
        userLogin(
            `${userFirstName} ${userLastInitial}`,
            partnerFirstName || partnerLastInitial ? `${partnerFirstName} ${partnerLastInitial}` : undefined
        );
    };

    // Error:(119, 15) TS2322: Type '(e: string) => void' is not assignable to type '(event: MouseEvent<HTMLFormElement, MouseEvent>) =>
    // void'. Types of parameters 'e' and 'event' are incompatible. Type 'MouseEvent<HTMLFormElement, MouseEvent>' is not assignable to type
    // 'string'.

    const userRegister = (e: React.MouseEvent<HTMLFormElement, MouseEvent>) => {
        e.preventDefault();

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setEmailError(t('login.emailError'));
            return false;
        }

        const uuid = storedUuid || uuidv4();

        setStoredUuid(uuid);

        const requestBody = {
            firstname: firstName,
            lastname: lastName,
            nickname: user,
            email,
            password,
            apptoken,
            teacher: sfsteacher
        };
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        axios
            .post('https://secure.emabridge.com/webservice/register.php', qs.stringify(requestBody), config)
            .then(response => {
                props.requestLogin({
                    user: response['data']['nickname'],
                    token: response['data']['token'],
                    uuid,
                    rememberMe: false
                });
            })
            .catch(e => {
                console.error(e);
            });
    };

    const errors: { [errorCode: string]: string } = t('login.errors', { returnObjects: true });

    const error =
        !!loginError && (errors[loginError] ? t(`login.errors.${loginError}`, { teacher: urlParams.sfsteacher }) : errors.unknown);

    if (!urlParams) {
        return null;
    }

    if (urlParams.nologin === '' || urlParams.jwe) {
        setTimeout(() => {
            userLogin();
        }, 10);
        return <Loading />;
    } else if (urlParams.nli != null) {
        return (
            <section className={`Login`}>
                <form onSubmit={onSubmitUserLoginNli}>
                    <input type="text" autoComplete="on" />
                    <input type="password" autoComplete="on" />
                    <h1>
                        {t('login.login', { teacher: urlParams.sfsteacher })}
                        {/*{t('login.login')} <Link to="/register">{t('login.register')}?</Link>*/}
                    </h1>
                    {error && <div className="error">{error}</div>}
                    <div className="fnli me">
                        <LabeledInput
                            className="firstName"
                            label={t('login.firstName')}
                            type="text"
                            value={userFirstName}
                            onChange={e => setUserFirstName(e.target.value)}
                            required
                        />
                        <LabeledInput
                            className="lastInitial"
                            label={t('login.lastInitial')}
                            type="text"
                            value={userLastInitial}
                            onChange={e => setUserLastInitial(e.target.value)}
                            maxLength={2}
                            required
                        />
                    </div>
                    <div className="fnli partner">
                        <LabeledInput
                            className="firstName"
                            label={t('login.pfirstName')}
                            type="text"
                            value={partnerFirstName}
                            onChange={e => setPartnerFirstName(e.target.value)}
                        />
                        <LabeledInput
                            className="lastInitial"
                            label={t('login.plastInitial')}
                            type="text"
                            value={partnerLastInitial}
                            onChange={e => setPartnerLastInitial(e.target.value)}
                            maxLength={2}
                        />
                    </div>
                    <LabeledInput label={t('login.rememberMe')} type="checkbox" checked={rememberMe} onChange={handleRememberMeChange} />
                    <div className="actions">
                        {/*<button type="button" onClick={autoLogin}>*/}
                        {/*  auto-login*/}
                        {/*</button>*/}
                        <button type="submit">{t('login.submit')}</button>
                    </div>
                    <div className="requiredInfo">{t('login.requiredInfo')}</div>
                </form>
            </section>
        );
    } else {
        return (
            <section className={`Login`}>
                {location.pathname === '/login' ? (
                    <form onSubmit={onSubmitUserLogin}>
                        <input type="text" autoComplete="on" />
                        <input type="password" autoComplete="on" />
                        <h1>
                            {t('login.login', { teacher: urlParams.sfsteacher })}
                            {/*{t('login.login')} <Link to="/register">{t('login.register')}?</Link>*/}
                        </h1>
                        {error && <div className="error">{error}</div>}
                        {urlParams.f2f != null && (
                            <div className="f2f">
                                <LabeledInput
                                    label={t('login.tableNumber')}
                                    required
                                    type="number"
                                    name="tableNumber"
                                    onChange={e => setTableNumber(Number(e.target.value))}
                                />
                                <div className="bridgePosition">
                                    <div>{t('login.bridgePosition')}</div>
                                    <div className="bridgePositions">
                                        {[
                                            [BridgePosition.north, BridgePosition.south],
                                            [BridgePosition.east, BridgePosition.west]
                                        ].map((bridgePositions, index) => (
                                            <div className={`bridgePositions-${index}`}>
                                                {bridgePositions.map(bridgePosition => (
                                                    <React.Fragment key={bridgePosition}>
                                                        <label htmlFor={bridgePosition}>
                                                            <input
                                                                type="radio"
                                                                name="bridgePosition"
                                                                required
                                                                id={bridgePosition}
                                                                onChange={e => setBridgePosition(bridgePosition)}
                                                            />
                                                            <span />
                                                            {t(`bridgePosition.${bridgePosition}`)}
                                                        </label>
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        <LabeledInput label={t('login.user')} type="text" value={user} onChange={e => setUser(e.target.value)} required />
                        {urlParams.f2f == null && (
                            <LabeledInput
                                label={t('login.partner')}
                                type="text"
                                value={partner}
                                onChange={e => setPartner(e.target.value)}
                            />
                        )}
                        {/*<LabeledInput label={t('login.password')} type="password" value={password} onChange={e => setPassword(e.target.value)} required />*/}
                        <LabeledInput
                            label={t('login.rememberMe')}
                            type="checkbox"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                        />
                        <div className="actions">
                            {/*<button type="button" onClick={autoLogin}>*/}
                            {/*  auto-login*/}
                            {/*</button>*/}
                            <button type="submit">{t('login.submit')}</button>
                        </div>
                        <div className="requiredInfo">{t('login.requiredInfo')}</div>
                    </form>
                ) : (
                    <form onSubmit={userRegister}>
                        <input type="text" autoComplete="on" />
                        <input type="password" autoComplete="on" />
                        <h1>
                            {t('login.register')} <Link to="/login">{t('login.login')}?</Link>
                        </h1>
                        <LabeledInput
                            label={t('login.firstname')}
                            type="text"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            required
                        />
                        <LabeledInput
                            label={t('login.lastname')}
                            type="text"
                            name="lastname"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            required
                        />
                        <LabeledInput
                            label={t('login.nickname')}
                            type="text"
                            value={nickname}
                            onChange={e => setNickname(e.target.value)}
                            required
                        />
                        <LabeledInput
                            label={t('login.email')}
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                            error={emailError}
                        />
                        <LabeledInput
                            label={t('login.password')}
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                        <div className="actions">
                            <button type="submit">{t('login.register')}</button>
                        </div>
                    </form>
                )}
            </section>
        );
    }
};

const mapStateToProps = ({ app }: IRootState) => ({
    sfsteacher: (app.urlParams || {}).sfsteacher,
    apptoken: app.apptoken,
    urlParams: app.urlParams,
    loginError: app.loginError
});

const mapDispatchToProps = {
    requestLogin,
    setUrlParams,
    setUuid,
    setLoggedIn,
    setDummyMode
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
