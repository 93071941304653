import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useLocalStorage from 'react-use-localstorage';
import { soundTypes } from '../_basics/audioPlayer';
import { LabeledInput } from '../_basics/labeled-input/labeled-input';
import { Suit } from '../_models/models';
import { getCssVarsForSuitColorSet } from '../_utils/helper';

import { IRootState } from '../store/_root.reducer';
import { addModal, IAppState, requestLogout, setLayout, setSettings } from '../store/app.reducer';
import {
    possibleCardBacks,
    possibleCurrentPlayerHighlights,
    possibleGeneralLayouts,
    possibleHandLayouts,
    possibleInteractions,
    possibleSuitColorSets,
    possibleSuitOrders,
    tableBackgrounds,
    trayBackgrounds
} from './options';

import './settings.scss';

export interface ISettingsProps extends StateProps, DispatchProps {}

export const renderSuits = (suitOrder: number) => {
    const suits = {
        S: Suit.spades,
        H: Suit.hearts,
        C: Suit.clubs,
        D: Suit.diamonds
    };
    return (
        <span className="suits">
            {possibleSuitOrders[suitOrder].split('').map(key => {
                // @ts-ignore
                const suit = suits[key];
                return suit ? <span key={`suit-${suit}`} className={`suit ${suit}`} /> : null;
            })}
        </span>
    );
};

const Settings: FunctionComponent<ISettingsProps> = props => {
    const { t } = useTranslation();
    const [storedUser, setStoredUser] = useLocalStorage('user', undefined);
    const [storedToken, setStoredToken] = useLocalStorage('token', undefined);
    const [storedSettings, setStoredSettings] = useLocalStorage('settings', undefined);
    const [show, setShow] = useState(false);
    const { layout, settings, user } = props;
    const { suitOrder, colorSets, cardBack } = layout;

    useEffect(() => {
        if (storedSettings) {
            try {
                const _storedSettings = JSON.parse(storedSettings);
                if (_storedSettings.layout) {
                    props.setLayout(_storedSettings.layout);
                }
                if (_storedSettings.settings) {
                    props.setSettings(_storedSettings.settings);
                }
            } catch (e) {}
        }
    }, []);

    useEffect(() => {
        setStoredSettings(
            JSON.stringify({
                layout,
                settings
            })
        );
    }, [layout, settings]);

    const changeLayout = (layoutPartial: Partial<IAppState['layout']>) => () => {
        props.setLayout({
            ...layout,
            ...layoutPartial
        });
    };

    const changeSettings = (key: keyof IAppState['settings'], value: any) => () => {
        props.setSettings({
            [key]: value
        });
    };

    const changeSettingsArray = (key: keyof IAppState['settings'], value: any) => () => {
        // @ts-ignore
        const otherSettings = (settings[key] || []).filter(_value => _value !== value);

        // @ts-ignore
        if (settings[key].indexOf(value) === -1) {
            props.setSettings({
                [key]: [...otherSettings, value]
            });
        } else {
            props.setSettings({
                [key]: otherSettings
            });
        }
    };

    const handleLogout = () => {
        const id = 'logoutConfirmation';
        props.addModal({
            id,
            header: t('modal.logout.header'),
            body: [<div>{t('modal.logout.body')}</div>],
            buttons: [
                {
                    onClick: () => {
                        setStoredUser('');
                        setStoredToken('');
                        props.requestLogout();
                    },
                    label: t('modal.logout.confirm'),
                    primary: true
                }
            ]
        });
    };

    const renderSelectCardBack = () => {
        return (
            possibleCardBacks.length > 1 && (
                <div className="selectCardBack">
                    <h3>{t('settings.selectCardBacks.title')}</h3>
                    <div className="cardBacks">
                        {possibleCardBacks.map((possibleCardBack, index) => (
                            <div
                                key={`cardBack-${index}`}
                                className={`cardBack ${cardBack === index ? 'selected' : ''}`}
                                style={{ backgroundImage: `url(${possibleCardBack})` }}
                                onClick={changeLayout({ cardBack: index })}
                            />
                        ))}
                    </div>
                </div>
            )
        );
    };

    const renderSelectCurrentPlayerHighlight = () => {
        return (
            possibleCurrentPlayerHighlights.length > 1 && (
                <div className="selectCurrentPlayerHighlight">
                    <h3>{t('settings.selectCurrentPlayerHighlight.title')}</h3>
                    <div className="currentPlayerHighlights">
                        {possibleCurrentPlayerHighlights.map((currentPlayerHighlight, index) => {
                            const checked = settings.currentPlayerHighlight.indexOf(currentPlayerHighlight) !== -1;
                            return (
                                <React.Fragment key={currentPlayerHighlight}>
                                    <LabeledInput
                                        label={t(`settings.selectCurrentPlayerHighlight.${currentPlayerHighlight}`)}
                                        type="checkbox"
                                        checked={checked}
                                        onChange={changeSettingsArray('currentPlayerHighlight', currentPlayerHighlight)}
                                    />
                                    {currentPlayerHighlight === 'background' && checked && (
                                        <div className="trayBackgrounds">
                                            {trayBackgrounds.map(trayBackground => (
                                                <div
                                                    key={trayBackground}
                                                    onClick={changeSettings('selectedCurrentPlayerBackground', trayBackground)}
                                                    className={
                                                        trayBackground === settings.selectedCurrentPlayerBackground ? 'selected' : ''
                                                    }
                                                    style={{ background: trayBackground }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            )
        );
    };

    const renderSelectGeneralLayout = () => {
        return (
            possibleGeneralLayouts.length > 1 && (
                <div className="selectGeneralLayout">
                    <h3>{t('settings.selectGeneralLayout.title')}</h3>
                    <div className="currentGeneralLayout">
                        {possibleGeneralLayouts.map(generalLayout => {
                            const checked = layout.generalLayout === generalLayout;
                            return (
                                <LabeledInput
                                    key={generalLayout}
                                    label={t(`settings.selectGeneralLayout.${generalLayout}`)}
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() => {
                                        if (generalLayout === 'v_impaired') {
                                            changeLayout({
                                                handLayout: 'straight',
                                                generalLayout
                                            })();
                                        } else {
                                            changeLayout({ generalLayout })();
                                        }
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )
        );
    };

    const renderSelectHandLayout = () => {
        return (
            possibleHandLayouts.length > 1 && (
                <div className="selectHandLayout">
                    <h3>{t('settings.selectHandLayout.title')}</h3>
                    <div className="currentHandLayout">
                        {possibleHandLayouts.map(handLayout => {
                            const checked = layout.handLayout === handLayout;
                            const disabled = layout.generalLayout === 'v_impaired' && handLayout === 'fan';
                            return (
                                <LabeledInput
                                    key={handLayout}
                                    label={t(`settings.selectHandLayout.${handLayout}`)}
                                    type="checkbox"
                                    checked={checked}
                                    disabled={disabled}
                                    onChange={changeLayout({ handLayout })}
                                />
                            );
                        })}
                    </div>
                </div>
            )
        );
    };

    const renderSelectInteractionMode = (interactionKey: keyof IAppState['settings']) => {
        return (
            possibleInteractions.length > 1 && (
                <div className="selectPossibleInteractions">
                    <h3>{t(`settings.selectPossibleInteractions.${interactionKey}.title`)}</h3>
                    <div className="possibleInteractions">
                        {possibleInteractions.map((possibleInteraction, index) => (
                            <LabeledInput
                                key={possibleInteraction}
                                label={t(`settings.selectPossibleInteractions.${interactionKey}.${possibleInteraction}`)}
                                type="checkbox"
                                //@ts-ignore
                                checked={settings[interactionKey].indexOf(possibleInteraction) !== -1}
                                onChange={changeSettings(interactionKey, possibleInteraction)}
                            />
                        ))}
                    </div>
                </div>
            )
        );
    };

    const renderSelectSuitOrder = () => {
        return (
            possibleSuitOrders.length > 1 && (
                <div className="selectSuitOrder">
                    <h3>{t('settings.selectSuitOrder.title')}</h3>
                    {possibleSuitOrders.map((possibleSuitOrder, index) => (
                        <label key={possibleSuitOrder}>
                            <input type="radio" checked={index === suitOrder} onChange={changeLayout({ suitOrder: index })} />
                            <span className="label">{t(`settings.selectSuitOrder.${possibleSuitOrder}`)}</span>({renderSuits(index)})
                        </label>
                    ))}
                </div>
            )
        );
    };

    const renderSelectSounds = () => {
        const { sounds } = settings;
        return (
            <div className="selectSounds">
                <h3>{t('settings.selectSounds.title')}</h3>
                {soundTypes.map((soundType, index) => (
                    <LabeledInput
                        key={soundType}
                        label={t(`settings.selectSounds.soundType.${soundType}`)}
                        type="checkbox"
                        checked={sounds[soundType]}
                        onChange={changeSettings('sounds', {
                            ...sounds,
                            [soundType]: !sounds[soundType]
                        })}
                    />

                    // <label key={`soundTypes-${index}`}>
                    //   <input
                    //     type="checkbox"
                    //     checked={sounds[soundType]}
                    //     onChange={changeSettings('sounds', {
                    //       ...sounds,
                    //       [soundType]: !sounds[soundType]
                    //     })}
                    //   />
                    //   <span className="label">{t(`settings.selectSounds.soundType.${soundType}`)}</span>
                    // </label>
                ))}
            </div>
        );
    };

    const renderSelectSuitColors = () => {
        return (
            possibleSuitColorSets.length > 1 && (
                <div className="selectSuitColors">
                    <h3>{t('settings.selectSuitColors.title')}</h3>
                    {possibleSuitColorSets.map((possibleSuitColorSet, index) => (
                        <label key={`possibleSuitColorSet-${index}`} style={{ ...getCssVarsForSuitColorSet(index) }}>
                            <input
                                type="radio"
                                checked={index === colorSets.suits}
                                onChange={changeLayout({
                                    colorSets: {
                                        ...colorSets,
                                        suits: index
                                    }
                                })}
                            />
                            {renderSuits(suitOrder || 1)}
                        </label>
                    ))}
                </div>
            )
        );
    };

    const renderSelectTableBackgrounds = () => {
        const tableBackgroundKeys: Array<keyof IAppState['settings']['tableBackgrounds']> = ['default', 'blockedByTeacher'];

        return (
            possibleCurrentPlayerHighlights.length > 1 && (
                <div className="selectTableBackgrounds">
                    <h3>{t('settings.selectTableBackgrounds.title')}</h3>
                    {tableBackgroundKeys.map(tableBackgroundKey => (
                        <div key={tableBackgroundKey}>
                            <h4>{t(`settings.selectTableBackgrounds.${tableBackgroundKey}`)}</h4>
                            <div className="tableBackgrounds">
                                {tableBackgrounds.map(tableBackground => (
                                    <div
                                        key={tableBackground}
                                        onClick={changeSettings('tableBackgrounds', {
                                            ...settings.tableBackgrounds,
                                            [tableBackgroundKey]: tableBackground
                                        })}
                                        className={tableBackground === settings.tableBackgrounds[tableBackgroundKey] ? 'selected' : ''}
                                        style={{ backgroundColor: tableBackground }}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )
        );
    };

    const renderShowMock = () => {
        return (
            <div className="showMock">
                <h3>Local Helper</h3>
                <div>
                    <LabeledInput
                        label="Mock Buttons"
                        type="checkbox"
                        checked={settings.showMock}
                        onChange={changeSettings('showMock', !settings.showMock)}
                    />
                </div>
                <div>
                    <LabeledInput
                        label="Table Slot Borders"
                        type="checkbox"
                        checked={settings.showTableSlotBorders}
                        onChange={changeSettings('showTableSlotBorders', !settings.showTableSlotBorders)}
                    />
                </div>
            </div>
        );
    };

    const renderToggleAnimations = () => {
        return (
            <div className="toggleAnimations">
                <h3>{t('settings.toggleAnimations.title')}</h3>
                <LabeledInput
                    label={t(`settings.toggleAnimations.${settings.animations ? 'on' : 'off'}`)}
                    type="checkbox"
                    checked={settings.animations}
                    onChange={changeSettings('animations', !settings.animations)}
                />
            </div>
        );
    };

    const renderVideoSettings = () => {
        return (
            <div className="videoSettings">
                <h3>{t('settings.videoSettings.title')}</h3>
                <div>
                    <LabeledInput
                        label={t(`settings.videoSettings.show`)}
                        type="checkbox"
                        checked={settings.showVideo}
                        onChange={changeSettings('showVideo', !settings.showVideo)}
                    />
                </div>
                <div>
                    <LabeledInput
                        label={t(`settings.8x8audioSettings.show`)}
                        type="checkbox"
                        checked={settings.silent8x8}
                        onChange={changeSettings('silent8x8', !settings.silent8x8)}
                    />
                </div>
            </div>
        );
    };

    return (
        <section className={`Settings`}>
            <div className={`settings ${settings.visible ? 'show' : 'hide'}`}>
                <div className="buttons">
                    {user && (
                        <button className="logout" onClick={handleLogout}>
                            {t('tray.logout')}
                        </button>
                    )}
                    <button className="hideSettings" onClick={() => props.setSettings({ visible: false })}>
                        {t('settings.close')}
                    </button>
                </div>
                {renderSelectInteractionMode('interactionBid')}
                {renderSelectInteractionMode('interactionCard')}
                {/*{renderSelectGeneralLayout()}*/}
                {renderSelectHandLayout()}
                {renderSelectSounds()}

                <div>
                    {renderSelectSuitColors()}
                    {renderSelectSuitOrder()}
                </div>
                {renderSelectCardBack()}
                {/*{renderSelectButtons()}*/}
                {renderSelectTableBackgrounds()}
                {renderSelectCurrentPlayerHighlight()}
                {renderToggleAnimations()}
                {renderVideoSettings()}
                {window.location.hostname === 'localhost' && renderShowMock()}
            </div>
        </section>
    );
};

const mapStateToProps = ({ app }: IRootState) => ({
    layout: app.layout,
    settings: app.settings,
    user: app.user
});

const mapDispatchToProps = {
    setLayout,
    setSettings,
    addModal,
    requestLogout
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
