import {
    BridgePosition,
    ChatEntry,
    IBid,
    ICard,
    IGameResultsPairV2,
    IGameResultsTravelerV2,
    IGameResultsV2,
    Rank,
    Suit
} from '../_models/models';

export const ACTION_TYPES = {
    APPROVE_CLAIM: 'output/APPROVE_CLAIM',
    APPROVE_UNDO: 'output/APPROVE_UNDO',
    CHANGE_PLAY: 'output/CHANGE_PLAY',
    CLICK_BID: 'output/CLICK_BID',
    CLOSE_RESULTS: 'output/CLOSE_RESULTS',
    DEAL_CARD: 'output/DEAL_CARD',
    DIRECTOR: 'output/DIRECTOR',
    INVITE: 'output/INVITE',
    LOAD_BOARD_REVIEW: 'output/LOAD_BOARD_REVIEW',
    MAKE_CALL: 'output/MAKE_CALL',
    MAKE_CLAIM: 'output/MAKE_CLAIM',
    MAKE_PLAY: 'output/MAKE_PLAY',
    POLL_VOTE: 'output/POLL_VOTE',
    PREV_NEXT: 'output/PREV_NEXT',
    REQUEST_UNDO: 'output/REQUEST_UNDO',
    RESET: 'output/RESET',
    SEND_CHAT_MESSAGE: 'output/SEND_CHAT_MESSAGE',
    SEND_CONVENTION_CARD_URL: 'output/SEND_CONVENTION_CARD_URL'
};

export interface IOutputState {
    approveClaim?: 'approve' | 'reject';
    approveUndo?: 'approve' | 'reject';
    changePlay?: 'startPlay' | 'stopPlay' | 'replay';
    chatEntry?: ChatEntry;
    claim?: number;
    clickedBid?: number;
    closeResults?: true;
    conventionCardUrl?: string;
    dealCard?: {
        suit: Suit;
        rank: Rank;
        seat?: BridgePosition;
    };
    director?: string;
    invite?: {
        email: string;
        bridgePosition: BridgePosition;
    };
    loadBoardReview?: {
        bn: IGameResultsTravelerV2['bn'];
        pair: IGameResultsPairV2['uuid'];
        game: IGameResultsV2['UUID'];
        comparisonUuid?: IGameResultsPairV2['uuid'];
    };
    makeCall?: IBid;
    makePlay?: ICard;
    prevNext?: 'prev' | 'next';
    pollVote?: { pollId: string; index: number };
    undo?: boolean;
}

export const initialState: IOutputState = {
    approveClaim: undefined,
    approveUndo: undefined,
    chatEntry: undefined,
    claim: undefined,
    clickedBid: undefined,
    closeResults: undefined,
    conventionCardUrl: undefined,
    dealCard: undefined,
    director: undefined,
    invite: undefined,
    loadBoardReview: undefined,
    makeCall: undefined,
    makePlay: undefined,
    prevNext: undefined,
    pollVote: undefined,
    undo: undefined
};

// Reducer

export default (state: IOutputState = initialState, action: any): IOutputState => {
    switch (action.type) {
        case ACTION_TYPES.APPROVE_CLAIM: {
            const { reaction }: { reaction: IOutputState['approveClaim'] } = action.payload;
            return {
                ...initialState,
                approveClaim: reaction
            };
        }

        case ACTION_TYPES.APPROVE_UNDO: {
            const { reaction }: { reaction: IOutputState['approveUndo'] } = action.payload;
            return {
                ...initialState,
                approveUndo: reaction
            };
        }

        case ACTION_TYPES.CHANGE_PLAY: {
            const { changePlay }: { changePlay: IOutputState['changePlay'] } = action.payload;
            return {
                ...initialState,
                changePlay
            };
        }

        case ACTION_TYPES.CLICK_BID: {
            const { index }: { index: IOutputState['clickedBid'] } = action.payload;
            //    console.log('oldState', initialState);
            const newState = {
                ...initialState,
                clickedBid: index
            };
            //    console.log('newState', newState);
            return newState;
        }

        case ACTION_TYPES.CLOSE_RESULTS: {
            return {
                ...initialState,
                closeResults: true
            };
        }

        case ACTION_TYPES.DEAL_CARD: {
            const { dealCard }: { dealCard: IOutputState['dealCard'] } = action.payload;
            return {
                ...initialState,
                dealCard
            };
        }

        case ACTION_TYPES.DIRECTOR: {
            return {
                ...initialState,
                director: action.payload
            };
        }

        case ACTION_TYPES.INVITE: {
            const invite: IOutputState['invite'] = action.payload;
            console.log('action', action);
            return {
                ...initialState,
                invite
            };
        }

        case ACTION_TYPES.LOAD_BOARD_REVIEW: {
            const loadBoardReview: IOutputState['loadBoardReview'] = action.payload;
            return {
                ...initialState,
                loadBoardReview
            };
        }

        case ACTION_TYPES.MAKE_CALL: {
            const { makeCall }: { makeCall: IOutputState['makeCall'] } = action.payload;
            return {
                ...initialState,
                makeCall
            };
        }

        case ACTION_TYPES.MAKE_CLAIM: {
            const { claim }: { claim: IOutputState['claim'] } = action.payload;
            return {
                ...initialState,
                claim
            };
        }

        case ACTION_TYPES.MAKE_PLAY: {
            const { makePlay }: { makePlay: IOutputState['makePlay'] } = action.payload;
            return {
                ...initialState,
                makePlay
            };
        }

        case ACTION_TYPES.REQUEST_UNDO: {
            const { undo }: { undo: IOutputState['undo'] } = action.payload;
            return {
                ...initialState,
                undo
            };
        }

        case ACTION_TYPES.POLL_VOTE: {
            const { pollVote }: { pollVote: IOutputState['pollVote'] } = action.payload;
            return {
                ...initialState,
                pollVote
            };
        }

        case ACTION_TYPES.PREV_NEXT: {
            const { prevNext }: { prevNext: IOutputState['prevNext'] } = action.payload;
            return {
                ...initialState,
                prevNext
            };
        }

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };

        case ACTION_TYPES.SEND_CHAT_MESSAGE: {
            const { chatEntry }: { chatEntry: ChatEntry } = action.payload;
            return {
                ...initialState,
                chatEntry
            };
        }

        case ACTION_TYPES.SEND_CONVENTION_CARD_URL: {
            const { conventionCardUrl }: { conventionCardUrl: IOutputState['conventionCardUrl'] } = action.payload;
            return {
                ...initialState,
                conventionCardUrl
            };
        }

        default:
            return state;
    }
};

// Actions

const approveClaim = (reaction: IOutputState['approveClaim']) => ({
    type: ACTION_TYPES.APPROVE_CLAIM,
    payload: { reaction }
});

const approveUndo = (reaction: IOutputState['approveUndo']) => ({
    type: ACTION_TYPES.APPROVE_UNDO,
    payload: { reaction }
});

const dealCard = (suit: Suit, rank: Rank, seat?: BridgePosition) => ({
    type: ACTION_TYPES.DEAL_CARD,
    payload: {
        dealCard: {
            suit,
            rank,
            seat
        }
    }
});

const changePlay = (changePlay: IOutputState['changePlay']) => ({
    type: ACTION_TYPES.CHANGE_PLAY,
    payload: { changePlay }
});

const clickBid = (index: number) => ({
    type: ACTION_TYPES.CLICK_BID,
    payload: { index }
});

const closeResults = () => ({
    type: ACTION_TYPES.CLOSE_RESULTS
});

const director = (director: string) => ({
    type: ACTION_TYPES.DIRECTOR,
    payload: director
});

const makePlay = (makePlay: IOutputState['makePlay']) => ({
    type: ACTION_TYPES.MAKE_PLAY,
    payload: { makePlay }
});

const invite = (invite: IOutputState['invite']) => ({
    type: ACTION_TYPES.INVITE,
    payload: invite
});

const loadBoardReview = (loadBoardReview: IOutputState['loadBoardReview']) => ({
    type: ACTION_TYPES.LOAD_BOARD_REVIEW,
    payload: loadBoardReview
});

const makeCall = (makeCall: IOutputState['makeCall']) => ({
    type: ACTION_TYPES.MAKE_CALL,
    payload: { makeCall }
});

const makeClaim = (claim: IOutputState['claim']) => ({
    type: ACTION_TYPES.MAKE_CLAIM,
    payload: { claim }
});

const requestUndo = (undo: IOutputState['undo']) => ({
    type: ACTION_TYPES.REQUEST_UNDO,
    payload: { undo }
});

const pollVote = (pollId: string, index: number) => ({
    type: ACTION_TYPES.POLL_VOTE,
    payload: {
        pollVote: { pollId, index }
    }
});

const prevNext = (prevNext: IOutputState['prevNext']) => ({
    type: ACTION_TYPES.PREV_NEXT,
    payload: { prevNext }
});

const resetOutput = () => ({
    type: ACTION_TYPES.RESET
});

const sendChatMessage = (chatEntry: ChatEntry) => ({
    type: ACTION_TYPES.SEND_CHAT_MESSAGE,
    payload: { chatEntry }
});

const sendConventionCardUrl = (conventionCardUrl: IOutputState['conventionCardUrl']) => ({
    type: ACTION_TYPES.SEND_CONVENTION_CARD_URL,
    payload: { conventionCardUrl }
});

export {
    approveClaim,
    approveUndo,
    changePlay,
    clickBid,
    closeResults,
    dealCard,
    director,
    invite,
    loadBoardReview,
    makeCall,
    makePlay,
    makeClaim,
    pollVote,
    prevNext,
    requestUndo,
    resetOutput,
    sendChatMessage,
    sendConventionCardUrl
};
