import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface ITranslateProps {
    contentKey: string;
    interpolate?: {
        [key: string]: string;
    };
}

export const Translate: FunctionComponent<ITranslateProps> = props => {
    const { t } = useTranslation();
    return t(props.contentKey, props.interpolate);
};
