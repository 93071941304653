import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IGameResultsPair, IGameResultsTraveler, IGameResultsPairV2, IGameResultsTravelerV2 } from '../../_models/models';
import { IRootState } from '../../store/_root.reducer';

import './game-results.scss';

export interface IGameResultsProps extends StateProps, DispatchProps {
    test?: string;
}

const GameResults: FunctionComponent<IGameResultsProps> = props => {
    const { t } = useTranslation();
    const { game, children } = props;
    const { gameResults } = game;
    const [showScores, setShowScores] = useState<boolean>(true);
    const [showPair, setShowPair] = useState<IGameResultsPair | undefined>(undefined);
    const [showBoard, setShowBoard] = useState<IGameResultsTraveler['boardnumber'] | undefined>(undefined);
    // for going back
    const [lastPair, setLastPair] = useState<IGameResultsPair | undefined>(undefined);

    const handleShowPair = (pairId: IGameResultsPair['pairId']) => () => {
        //console.log('pairId', pairId);
        if (!gameResults) {
            return null;
        }

        const { Pairs } = gameResults;
        setShowScores(false);
        setShowBoard(undefined);

        const selectedPair = Pairs != undefined ? Pairs.find(pair => pair.pairId === pairId) : undefined;

        if (selectedPair) {
            setShowPair(selectedPair);
        }
    };

    const handleShowBoard = (boardNumber: IGameResultsTraveler['boardnumber']) => () => {
        setLastPair(showPair);
        setShowPair(undefined);
        setShowBoard(boardNumber);
    };

    const goBackToScores = () => {
        setShowScores(true);
        setShowBoard(undefined);
        setShowPair(undefined);
        setLastPair(undefined);
    };

    const goBackToPair = () => {
        setShowScores(false);
        setShowBoard(undefined);
        setShowPair(lastPair);
        setLastPair(undefined);
    };

    const formatValues = (key: keyof IGameResultsPair | keyof IGameResultsTraveler, value: any) => {
        //sconsole.log(key);
        switch (key) {
            case 'declarer':
                return t(`gameResults.board.declarer.${value || 'empty'}`);
            case 'runningMP': {
                return (value as number).toFixed(2) + '%';
            }
            case 'resultMP': {
                return (value as number).toFixed(2) + '%';
            }
            case 'runningIMP':
                return (value as number).toFixed(0);
            case 'resultIMP':
                return (value as number).toFixed(0);
            default:
                return value;
        }
    };

    const getSuit = (suit: string) => {
        const suits: { [key: string]: string } = {
            s: 'spades',
            d: 'diamonds',
            c: 'clubs',
            h: 'hearts'
        };

        if (suits[suit]) {
            return <span className={`suit ${suits[suit]}`} />;
        }
        return suit;
    };

    const renderTraveler = (key: keyof IGameResultsTraveler, traveler: any) => {
        switch (key) {
            case 'vs':
                return (
                    <span className="link" onClick={handleShowPair(traveler.vsPairId)}>
                        {traveler.vs}
                    </span>
                );
            case 'pair':
                return (
                    <span className="link" onClick={handleShowPair(traveler.vsPairId)}>
                        {traveler.p1 + ' / ' + traveler.p2}
                    </span>
                );
            case 'contract':
                return (
                    <span>
                        {traveler.contract?.substr(0, 1)}
                        {getSuit(traveler.contract?.substr(1))}
                    </span>
                );
            case 'lead':
                return (
                    <span>
                        {getSuit(traveler.lead?.substr(0, 1))}
                        {traveler.lead?.substr(1)}
                    </span>
                );
            default:
                return formatValues(key, traveler[key]);
        }
    };

    const sortByScoring = (a: IGameResultsPair, b: IGameResultsPair) => {
        if (!gameResults) {
            return 0;
        }
        const { Scoring, ShowRanks, myPairId } = gameResults;
        if (!ShowRanks) {
            return a.pairId === myPairId || b.pairId === myPairId ? 1 : 0;
        }
        if (Scoring === 'IMP') {
            return b.runningIMP - a.runningIMP;
        }
        return b.runningMP - a.runningMP;
    };

    const renderGameResultsScores = () => {
        if (!gameResults) {
            return null;
        }

        const pairKeys: Array<keyof IGameResultsPair> = ['runningMP', 'runningIMP'];
        const { Pairs, Scoring, Movement, ShowRanks, myPairId } = gameResults;

        const pairs: IGameResultsPair[][] =
            Movement === 'Mitchell' ? [Pairs.filter(pair => pair.dir === 'ns'), Pairs.filter(pair => pair.dir === 'ew')] : [Pairs];

        return pairs.map((_pairs, index) => (
            <div key={`_pairs-${index}`} className="gameResultsScores">
                {Movement === 'Mitchell' && <h2>{_pairs[0]?.dir}</h2>}
                <table>
                    <thead>
                        <tr>
                            <th className={ShowRanks ? '' : 'hide'}>{t(`gameResults.scores.thead.rank`)}</th>
                            <th>{t(`gameResults.scores.thead.pair`)}</th>
                            {pairKeys.map((key, thIndex) => (
                                <th key={`th-${thIndex}`}>{t(`gameResults.scores.thead.${key}`)}</th>
                            ))}
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {_pairs.sort(sortByScoring).map((pair: IGameResultsPair, trIndex) => (
                            <tr key={`tr-${trIndex}`} className={pair.pairId === myPairId ? 'isMe' : 'isNotMe'}>
                                <td className={ShowRanks ? '' : 'hide'}>{Scoring === 'IMP' ? pair.rankIMP : pair.rankMP}</td>
                                <td className="pair">{t(`gameResults.scores.pair`, { p1: pair.p1, p2: pair.p2 })}</td>
                                {pairKeys.map((key, tdIndex) => (
                                    <td key={`td-${tdIndex}`} className={key}>
                                        {formatValues(key, pair[key])}
                                    </td>
                                ))}
                                <td className="action">
                                    <span className="link" onClick={handleShowPair(pair.pairId)}>
                                        {t('gameResults.scores.showPair')}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ));
    };

    const renderPairThead = (travelerKeys: Array<keyof IGameResultsTraveler>, table: 'pair' | 'board') => {
        return (
            <thead>
                <tr>
                    {travelerKeys.map((key, thIndex) => {
                        switch (key) {
                            case 'resultMP':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={2}>
                                        {t(`gameResults.pair.thead.result`)}
                                    </th>
                                );
                            case 'runningMP':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={2}>
                                        {t(`gameResults.pair.thead.running`)}
                                    </th>
                                );
                            case 'contract':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={4}>
                                        {t(`gameResults.pair.thead.summary`)}
                                    </th>
                                );
                            default:
                                if (!['declarer', 'lead', 'tricksResult', 'resultIMP', 'runningIMP'].includes(key)) {
                                    return <th key={`th-${thIndex}-1`} />;
                                }
                                return null;
                        }
                    })}
                </tr>
                <tr>
                    {travelerKeys.map((key, thIndex) => (
                        <th key={`th-${thIndex}-2`}>{t(`gameResults.${table}.thead.${key}`)}</th>
                    ))}
                </tr>
            </thead>
        );
    };

    const RenderGameResultsPair = () => {
        if (!showPair) {
            return null;
        }

        const travelerKeys: Array<keyof IGameResultsTraveler> = [
            'boardnumber',
            'dir',
            'vs',
            'contract',
            'declarer',
            'lead',
            'tricksResult',
            'score',
            'resultMP',
            'resultIMP',
            'runningMP',
            'runningIMP'
        ];

        return (
            <>
                <div className="header">
                    <button onClick={goBackToScores} />
                    <h2>{t(`gameResults.pair.headline`, { p1: showPair.p1, p2: showPair.p2 })}</h2>
                </div>
                <table className="gameResultsPair">
                    {renderPairThead(travelerKeys, 'pair')}
                    <tbody>
                        {(showPair?.traveler || [])
                            .sort((a, b) => {
                                return a.boardnumber - b.boardnumber;
                            })
                            .map((traveler, trIndex) => (
                                <tr key={`tr-${trIndex}`} className={traveler.vsPairId === showPair?.pairId ? 'isMe' : 'isNotMe'}>
                                    {travelerKeys.map((key, tdIndex) => (
                                        <td key={`td-${tdIndex}`} className={key}>
                                            {renderTraveler(key, { ...traveler, p1: showPair.p1, p2: showPair.p2 })}
                                        </td>
                                    ))}
                                    <td className="action">
                                        <span className="link" onClick={handleShowBoard(traveler.boardnumber)}>
                                            {t('gameResults.pair.showBoard')} {traveler.boardnumber}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </>
        );
    };

    const RenderGameResultsBoard = () => {
        if (!gameResults) {
            return null;
        }

        const { Pairs } = gameResults;
        const travelerKeys: Array<keyof IGameResultsTraveler> = [
            'dir',
            'pair',
            'contract',
            'declarer',
            'lead',
            'tricksResult',
            'score',
            'resultMP',
            'resultIMP',
            'runningMP',
            'runningIMP'
        ];

        const travelers: any[] = Pairs.reduce((prev: IGameResultsTraveler[], next) => {
            const { traveler, p1, p2 } = next;

            return [
                ...prev,
                ...traveler
                    .filter(travelerData => travelerData.boardnumber === showBoard)
                    .map(travelerData => ({ ...travelerData, p1, p2 }))
            ];
        }, []);

        return (
            <>
                <div className="header">
                    <button onClick={goBackToPair} />
                    <h2>{t(`gameResults.board.headline`, { boardNumber: showBoard })}</h2>
                </div>
                <div>
                    {['ns', 'ew'].map(dir => (
                        <table key={dir} className="gameResultsBoards">
                            {renderPairThead(travelerKeys, 'board')}
                            <tbody>
                                {travelers
                                    .filter(traveler => traveler.dir === dir)
                                    .sort(sortByScoring)
                                    .map((traveler, trIndex) => (
                                        <tr key={`tr-${trIndex}`} className={traveler.vsPairId === showPair?.pairId ? 'isMe' : 'isNotMe'}>
                                            {travelerKeys.map((key, tdIndex) => (
                                                <td key={`td-${trIndex}`} className={key}>
                                                    {renderTraveler(key, traveler)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    ))}
                </div>
            </>
        );
    };

    return (
        <section className="GameResults">
            {showScores && renderGameResultsScores()}
            {!!showPair && <RenderGameResultsPair />}
            {!!showBoard && <RenderGameResultsBoard />}
            {children}
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GameResults);
