import React, { FunctionComponent } from 'react';

import { ICard } from '../../_models/models';
import { IAppState } from '../../store/app.reducer';
import { IGameState } from '../../store/game.reducer';
import { Card } from '../card/card';

import './trick.scss';

export interface ITrickProps {
    trick: ICard[];
    set: IAppState['layout']['selectedCardSet'];
    closed?: boolean;
    seatData: IGameState['seatData'];
}

const Trick: FunctionComponent<ITrickProps> = props => {
    const { trick, set, closed, seatData } = props;
    return (
        <section className={`Trick ${closed ? 'closed' : ''}`}>
            {trick.map(card => {
                const { suit, rank, bridgePosition } = card;
                return (
                    bridgePosition && (
                        <div key={`trick-${card.suit}-${card.rank}`} className={`cardLane ${seatData[bridgePosition].displayPosition}`}>
                            <Card {...{ suit, rank, set, isVisible: true, isReallyVisible: true }} />
                        </div>
                    )
                );
            })}
        </section>
    );
};

export default Trick;
