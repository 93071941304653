import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { SoundFiles, SoundTypes } from '../_basics/audioPlayer';
import { BridgePosition, GeneralLayout, HandLayout, IModal, IPoll } from '../_models/models';
import { PossibleCurrentPlayerHighlight, PossibleInteraction, tableBackgrounds, trayBackgrounds } from '../settings/options';

export const ACTION_TYPES = {
    ADD_MODAL: 'app/ADD_MODAL',
    REMOVE_MODAL: 'app/REMOVE_MODAL',
    REQUEST_LOGIN: 'app/REQUEST_LOGIN',
    REQUEST_LOGOUT: 'app/REQUEST_LOGOUT',
    RESET: 'app/RESET',
    SET_DUMMY_MODE: 'app/SET_DUMMY_MODE',
    SET_HAS_OVERSIZE: 'app/SET_HAS_OVERSIZE',
    SET_LAYOUT: 'app/SET_LAYOUT',
    SET_LOGGED_IN: 'app/SET_LOGGED_IN',
    SET_LOGIN_ERROR: 'app/SET_LOGIN_ERROR',
    SET_CONNECTION_STATUS: 'app/SET_CONNECTION_STATUS',
    SET_SETTINGS: 'app/SET_SETTINGS',
    SET_SOUND_PLAYING: 'app/SET_SOUND_PLAYING',
    SET_URL_PARAMS: 'app/SET_URL_PARAMS',
    SET_UUID: 'app/SET_UUID',
    SET_POLL: 'app/SET_POLL',
    UNSET_F2F: 'app/UNSET_F2F'
};

export interface IAppState {
    apptoken: string;
    bridgePosition?: BridgePosition;
    connection: {
        established: boolean;
        requested: boolean;
    };
    hasOverSize: boolean;
    isDummyMode: boolean;
    isLoggedIn: boolean;
    layout: {
        cardBack: number;
        colorSets: {
            suits: number;
            background: number;
        };
        generalLayout: GeneralLayout;
        handLayout: HandLayout;
        selectedCardSet: number;
        suitOrder: number;
        tableBackground: number;
    };
    loginError?: string;
    modals: IModal[];
    partner?: string;
    poll?: IPoll;
    rememberMe: boolean;
    requestLogin: boolean;
    requestLogout: boolean;
    settings: {
        animations: boolean;
        currentPlayerHighlight: PossibleCurrentPlayerHighlight[];
        interactionBid: PossibleInteraction;
        interactionCard: PossibleInteraction;
        selectedCurrentPlayerBackground: string;
        showMock: boolean;
        showTableSlotBorders: boolean;
        showVideo: boolean;
        silent8x8: boolean;
        sounds: {
            [key in SoundTypes]: boolean;
        };
        tableBackgrounds: {
            default: string;
            blockedByTeacher: string;
        };
        visible: boolean;
    };
    soundPlaying?: keyof SoundFiles;
    tableNumber?: number;
    token?: string;
    urlParams: {
        [key: string]: string;
    };
    user?: string;
    uuid?: string;
}

export const initialState: IAppState = {
    apptoken: 'q9wfpprecaxqs07w13qn',
    bridgePosition: undefined,
    connection: {
        established: false,
        requested: false
    },
    hasOverSize: false,
    isLoggedIn: false,
    isDummyMode: false,
    layout: {
        cardBack: 0,
        colorSets: {
            suits: 0,
            background: 0
        },
        generalLayout: 'default',
        handLayout: 'straight',
        selectedCardSet: 1,
        suitOrder: 0,
        tableBackground: 0
    },
    loginError: undefined,
    modals: [],
    partner: undefined,
    rememberMe: false,
    requestLogin: false,
    requestLogout: false,
    settings: {
        animations: true,
        currentPlayerHighlight: ['background'],
        interactionCard: isMobile ? 'confirm' : 'instant',
        interactionBid: isMobile ? 'confirm' : 'instant',
        selectedCurrentPlayerBackground: trayBackgrounds[1],
        showMock: false,
        showTableSlotBorders: false,
        showVideo: true,
        silent8x8: false,
        sounds: {
            tournamentStart: false,
            yourTurn: false
        },
        tableBackgrounds: {
            default: tableBackgrounds[0],
            blockedByTeacher: tableBackgrounds[1]
        },
        visible: false
    },
    soundPlaying: undefined,
    tableNumber: undefined,
    token: undefined,
    urlParams: {},
    user: undefined,
    uuid: undefined
};

// Reducer

export default (state: IAppState = initialState, action: any): IAppState => {
    switch (action.type) {
        case ACTION_TYPES.ADD_MODAL: {
            const { modal }: { modal: IModal } = action.payload;
            return {
                ...state,
                modals: [...state.modals, modal]
            };
        }

        case ACTION_TYPES.REMOVE_MODAL: {
            const { id }: { id: IModal['id'] } = action.payload || {};
            const modals = [...state.modals];

            if (id) {
                const modalIndex = modals.findIndex(modal => modal.id === id);
                if (modalIndex !== -1) {
                    modals.splice(modalIndex, 1);
                }
            } else {
                modals.pop();
            }

            return {
                ...state,
                modals
            };
        }

        case ACTION_TYPES.REQUEST_LOGIN: {
            const { user, token, uuid, rememberMe, partner, tableNumber, bridgePosition } = action.payload;

            //  console.log('action.payload', action.payload);

            //   console.log('bridgePosition', bridgePosition);

            return {
                ...state,
                token,
                user,
                uuid,
                rememberMe,
                partner,
                tableNumber,
                bridgePosition,
                requestLogout: false,
                requestLogin: true
            };
        }

        case ACTION_TYPES.REQUEST_LOGOUT: {
            return {
                ...state,
                requestLogin: false,
                requestLogout: true,
                user: initialState.user,
                token: initialState.token,
                isLoggedIn: initialState.isLoggedIn
            };
        }

        case ACTION_TYPES.RESET: {
            return {
                ...initialState
            };
        }

        case ACTION_TYPES.SET_CONNECTION_STATUS: {
            const { status } = action.payload;
            return {
                ...state,
                connection: {
                    ...state.connection,
                    ...status
                }
            };
        }

        case ACTION_TYPES.SET_DUMMY_MODE: {
            return {
                ...state,
                isDummyMode: true
            };
        }

        case ACTION_TYPES.SET_HAS_OVERSIZE: {
            return {
                ...state,
                hasOverSize: action.payload
            };
        }

        case ACTION_TYPES.SET_LAYOUT: {
            const { layout }: IAppState = action.payload;
            return {
                ...state,
                layout: {
                    ...state.layout,
                    ...layout,
                    generalLayout: 'default'
                }
            };
        }

        case ACTION_TYPES.SET_LOGGED_IN: {
            return {
                ...state,
                requestLogout: false,
                requestLogin: false,
                isLoggedIn: true
            };
        }

        case ACTION_TYPES.SET_LOGIN_ERROR: {
            return {
                ...state,
                loginError: action.payload,
                requestLogin: false
            };
        }

        case ACTION_TYPES.SET_SETTINGS: {
            const { settings }: { settings: IAppState['settings'] } = action.payload || {};
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...settings
                }
            };
        }

        case ACTION_TYPES.SET_POLL: {
            const { poll }: { poll: IAppState['poll'] } = action.payload || {};
            return {
                ...state,
                poll
            };
        }

        case ACTION_TYPES.SET_SOUND_PLAYING: {
            return {
                ...state,
                soundPlaying: action.payload
            };
        }

        case ACTION_TYPES.SET_URL_PARAMS: {
            const { urlParams }: { urlParams: IAppState['urlParams'] } = action.payload;
            return {
                ...state,
                urlParams
            };
        }

        case ACTION_TYPES.SET_UUID: {
            const { uuid }: { uuid: IAppState['uuid'] } = action.payload;
            return {
                ...state,
                uuid
            };
        }

        case ACTION_TYPES.UNSET_F2F: {
            return {
                ...state,
                tableNumber: undefined,
                bridgePosition: undefined
            };
        }

        default:
            return state;
    }
};

// Actions

const addModal = (modal: IModal) => {
    return {
        type: ACTION_TYPES.ADD_MODAL,
        payload: {
            modal: {
                id: uuidv4(),
                ...modal
            }
        }
    };
};

const unsetF2F = () => ({
    type: ACTION_TYPES.UNSET_F2F
});

const removeCurrentModal = () => ({
    type: ACTION_TYPES.REMOVE_MODAL
});

const removeModal = (id: IModal['id']) => ({
    type: ACTION_TYPES.REMOVE_MODAL,
    payload: { id }
});

const requestLogin = (loginData: {
    user: IAppState['user'];
    token: IAppState['token'];
    uuid: IAppState['uuid'];
    rememberMe: IAppState['rememberMe'];
    partner?: IAppState['partner'];
    tableNumber?: IAppState['tableNumber'];
    bridePosition?: IAppState['bridgePosition'];
}) => {
    return {
        type: ACTION_TYPES.REQUEST_LOGIN,
        payload: loginData
    };
};

const requestLogout = () => ({
    type: ACTION_TYPES.REQUEST_LOGOUT
});

const resetApp = () => ({
    type: ACTION_TYPES.RESET
});

const setConnectionStatus = (status: IAppState['connection']) => ({
    type: ACTION_TYPES.SET_CONNECTION_STATUS,
    payload: { status }
});

const setDummyMode = () => ({
    type: ACTION_TYPES.SET_DUMMY_MODE
});

const setHasOverSize = (hasOversize: IAppState['hasOverSize']) => ({
    type: ACTION_TYPES.SET_HAS_OVERSIZE,
    payload: hasOversize
});

const setLayout = (layout: IAppState['layout']) => ({
    type: ACTION_TYPES.SET_LAYOUT,
    payload: { layout }
});

const setLoggedIn = () => ({
    type: ACTION_TYPES.SET_LOGGED_IN
});

const setLogInError = (error: IAppState['loginError']) => ({
    type: ACTION_TYPES.SET_LOGIN_ERROR,
    payload: error
});

const setPoll = (poll?: IAppState['poll']) => ({
    type: ACTION_TYPES.SET_POLL,
    payload: { poll }
});

const setSettings = (settings: Partial<IAppState['settings']>) => ({
    type: ACTION_TYPES.SET_SETTINGS,
    payload: { settings }
});

const setSoundPlaying = (sound?: keyof SoundFiles) => ({
    type: ACTION_TYPES.SET_SOUND_PLAYING,
    payload: sound
});

const setUrlParams = (urlParams: IAppState['urlParams']) => ({
    type: ACTION_TYPES.SET_URL_PARAMS,
    payload: { urlParams }
});

const setUuid = (uuid: IAppState['uuid']) => ({
    type: ACTION_TYPES.SET_UUID,
    payload: { uuid }
});

export {
    addModal,
    removeCurrentModal,
    removeModal,
    requestLogin,
    requestLogout,
    resetApp,
    setConnectionStatus,
    setDummyMode,
    setHasOverSize,
    setLayout,
    setLoggedIn,
    setLogInError,
    setPoll,
    setSettings,
    setSoundPlaying,
    setUrlParams,
    setUuid,
    unsetF2F
};
