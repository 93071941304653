import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BridgePosition, DisplayPosition } from '../../_models/models';
import {
    getBridgePositionFromDisplayPosition,
    getDeclarerBridgePosition,
    getLeftOfBridgePosition,
    getMySeatData,
    getPartnerBridgePosition
} from '../../_utils/helper';

import './trick-history.scss';
import { ForeignBoardReviewData, ForeignBoardReviewPlayerData } from '../../store/game.reducer';

const displayPositions = [DisplayPosition.top, DisplayPosition.right, DisplayPosition.bottom, DisplayPosition.left];

export interface ITrickHistoryProps {
    playerData: ForeignBoardReviewPlayerData;
    seatData: ForeignBoardReviewData['seatData'];
}

const TrickHistory: FunctionComponent<ITrickHistoryProps> = ({ playerData, seatData }) => {
    const { t } = useTranslation();

    const { tricks, winner } = playerData;

    const declarer = getDeclarerBridgePosition(seatData);

    if (!declarer) {
        return null;
    }

    const firstLeader = getLeftOfBridgePosition(playerData.declarer ?? declarer, seatData);

    if (!firstLeader) {
        return null;
    }

    return (
        <section className={`TrickHistory`}>
            <div className="tricks">
                <div className="bridgePositions">
                    {displayPositions.map(displayPosition => (
                        <div key={displayPosition} className={displayPosition}>
                            {(getBridgePositionFromDisplayPosition(displayPosition, seatData) || '').substr(0, 1)}
                        </div>
                    ))}
                </div>
                {tricks.map((trick, index) => {
                    const leader = index === 0 ? firstLeader : winner[index - 1];
                    const myBridgePosition = (getMySeatData(seatData) || {}).bridgePosition;
                    const partnerBridgePosition = myBridgePosition ? getPartnerBridgePosition(myBridgePosition) : undefined;
                    const weWon = (winner[index] && winner[index] === myBridgePosition) || winner[index] === partnerBridgePosition;
                    const winnerDisplayPosition = seatData[winner[index]]?.displayPosition;
                    return (
                        <div key={`trick-${index}`} className={`trick ${weWon ? 'winner' : ''} winner-${winnerDisplayPosition}`}>
                            <div className="trick-index" />
                            {trick.map(
                                card =>
                                    card.bridgePosition && (
                                        <div
                                            key={`trick-${card.suit}-${card.rank}-${index}`}
                                            className={`card ${seatData[card.bridgePosition].displayPosition} ${card.suit} ${card.rank} ${
                                                leader === card.bridgePosition ? 'leader' : ''
                                            } ${winner[index] === card.bridgePosition ? 'winner' : ''}`}
                                        >
                                            <span>{t(`rank.short.${card.rank}`)}</span>
                                        </div>
                                    )
                            )}
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default TrickHistory;
