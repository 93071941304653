import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Hand from '../hand/hand';
import { getBridgePositionFromDisplayPosition, getCardsOfSeat } from '../../_utils/helper';
import { IAppState } from '../../store/app.reducer';
import { ForeignBoardReviewData } from '../../store/game.reducer';
import { DisplayPosition, GamePhase, HandLayout, ISeatData } from '../../_models/models';

import './short-table.scss';

export interface IShortTableProps {
    cards: ForeignBoardReviewData['cards'];
    seatData: ForeignBoardReviewData['seatData'];
    contract: ForeignBoardReviewData['contract'];
    gamePhase: ForeignBoardReviewData['gamePhase'];
    layout: IAppState['layout'];
    scale?: number;
    forceShowAll?: boolean;
}

export const ShortTable: FunctionComponent<IShortTableProps> = ({
    cards,
    seatData,
    contract,
    gamePhase,
    layout,
    scale = 1,
    forceShowAll
}) => {
    const { t } = useTranslation();

    const renderHand = (position: DisplayPosition): JSX.Element | undefined => {
        const seat = getBridgePositionFromDisplayPosition(position, seatData);
        if (seat) {
            const currentSeatData = seatData[seat] as ISeatData;
            const { bridgePosition } = currentSeatData;
            const hand = getCardsOfSeat(cards, bridgePosition, 'hand', layout.suitOrder, contract);
            const forceHandLayout: HandLayout = 'stacked';
            const handProps = {
                currentSeatData,
                hand,
                userVisible: false,
                forceHandLayout
            };
            return (
                <div className={`displayPosition ${position}`}>
                    <h3>{t(`bridgePosition.${bridgePosition}`)}</h3>
                    {(currentSeatData.isVisible || currentSeatData.isMe || forceShowAll) && <Hand {...handProps} />}
                </div>
            );

            // if (gamePhase === GamePhase.END) {
            //     return (
            //         <div className={`displayPosition ${position}`}>
            //             <h3>{t(`bridgePosition.${bridgePosition}`)}</h3>
            //             {
            //                 currentSeatData.isVisible && <Hand {...handProps} />
            //             }
            //         </div>
            //     );
            // } else {
            //     return (
            //         <div className={`displayPosition ${position}`}>
            //             <h3>{t(`bridgePosition.${bridgePosition}`)}</h3>
            //         </div>
            //     );
            // }
        }
    };

    return (
        <section className={`ShortTable`} style={{ '--scale': scale } as CSSProperties}>
            {renderHand(DisplayPosition.top)}
            <div className="middle">
                {renderHand(DisplayPosition.left)}
                {renderHand(DisplayPosition.right)}
            </div>
            {renderHand(DisplayPosition.bottom)}
        </section>
    );
};

export default ShortTable;
