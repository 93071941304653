import { TFunction } from 'i18next';
import moment from 'moment';
import React, { CSSProperties } from 'react';
import { Translate } from '../_basics/translate';
import GameResults from '../_elements/game-results/game-results';
import {
    BridgePosition,
    DisplayPosition,
    IBid,
    ICard,
    ICardPosition,
    ICards,
    IModal,
    ISeatData,
    ITableResult,
    levels,
    Rank,
    strains,
    Suit,
    Vulnerability
} from '../_models/models';
import { possibleSuitColorSets, possibleSuitOrders } from '../settings/options';
import { removeModal } from '../store/app.reducer';
import { IGameState, initialGameState } from '../store/game.reducer';
import { approveClaim, approveUndo } from '../store/output.reducer';
import store from '../store/store';
import { enumToArray } from './initialHelper';
import GameResultsV2 from '../_elements/game-results/game-results-v2';

const suitOrders = {
    SHCD: [Suit.spades, Suit.hearts, Suit.clubs, Suit.diamonds],
    SHDC: [Suit.spades, Suit.hearts, Suit.diamonds, Suit.clubs],
    HSDC: [Suit.hearts, Suit.spades, Suit.diamonds, Suit.clubs],
    DSHC: [Suit.diamonds, Suit.spades, Suit.hearts, Suit.clubs],
    CHSD: [Suit.clubs, Suit.hearts, Suit.spades, Suit.diamonds],
    trump: undefined
};

const ranks = enumToArray(Rank);

const sortCards = (suitOrder?: Suit[]) => (cardA: ICard, cardB: ICard): number => {
    const useSuitOrder = suitOrder || suitOrders[possibleSuitOrders[1]] || [];
    const indexA = useSuitOrder.indexOf(cardA.suit);
    const indexB = useSuitOrder.indexOf(cardB.suit);
    if (indexA < indexB) {
        return -1;
    } else if (indexA > indexB) {
        return 1;
    } else {
        return ranks.indexOf(cardA.rank) - ranks.indexOf(cardB.rank);
    }
};

export const getCardsOfSeat = (
    cards: ICards,
    bridgePosition: BridgePosition,
    position: ICardPosition,
    suitOrderIndex: number,
    trump?: IBid
): ICard[] => {
    let suitOrder: Suit[] | undefined;
    if (suitOrderIndex === 0) {
        if (!trump || trump.strain === Suit.spades) {
            suitOrder = suitOrders.SHCD;
        } else if (trump.strain === Suit.hearts) {
            suitOrder = suitOrders.HSDC;
        } else if (trump.strain === Suit.diamonds) {
            suitOrder = suitOrders.DSHC;
        } else if (trump.strain === Suit.clubs) {
            suitOrder = suitOrders.CHSD;
        }
    } else {
        suitOrder = suitOrders[possibleSuitOrders[suitOrderIndex]];
    }

    return Object.keys(cards)
        .filter(key => cards[key].bridgePosition === bridgePosition && cards[key].position === position)
        .map(key => cards[key])
        .sort(sortCards(suitOrder));
};

interface ITableRotations {
    [myBridgePosition: string]: {
        [BridgePosition.north]: DisplayPosition;
        [BridgePosition.east]: DisplayPosition;
        [BridgePosition.south]: DisplayPosition;
        [BridgePosition.west]: DisplayPosition;
    };
}

const tableRotations: ITableRotations = {
    [BridgePosition.south]: {
        [BridgePosition.north]: DisplayPosition.top,
        [BridgePosition.east]: DisplayPosition.right,
        [BridgePosition.south]: DisplayPosition.bottom,
        [BridgePosition.west]: DisplayPosition.left
    },
    [BridgePosition.west]: {
        [BridgePosition.north]: DisplayPosition.left,
        [BridgePosition.east]: DisplayPosition.top,
        [BridgePosition.south]: DisplayPosition.right,
        [BridgePosition.west]: DisplayPosition.bottom
    },
    [BridgePosition.north]: {
        [BridgePosition.north]: DisplayPosition.bottom,
        [BridgePosition.east]: DisplayPosition.left,
        [BridgePosition.south]: DisplayPosition.top,
        [BridgePosition.west]: DisplayPosition.right
    },
    [BridgePosition.east]: {
        [BridgePosition.north]: DisplayPosition.right,
        [BridgePosition.east]: DisplayPosition.bottom,
        [BridgePosition.south]: DisplayPosition.left,
        [BridgePosition.west]: DisplayPosition.top
    }
};

export const getRotatedSeatData = (myBridgePosition: BridgePosition, seatsBridgePosition: BridgePosition): Partial<ISeatData> => {
    return {
        isMe: myBridgePosition === seatsBridgePosition,
        displayPosition: tableRotations[myBridgePosition][seatsBridgePosition]
    };
};

export const getMySeatData = (seatData: IGameState['seatData']) => {
    if (!seatData) {
        return null;
    }
    const myBridgePosition = Object.keys(seatData).find(bridgePosition => seatData[bridgePosition].isMe);
    return myBridgePosition ? { ...seatData[myBridgePosition] } : null;
};

export const getCallValue = (call: IBid) => {
    return strains.indexOf(call.strain) + 10 * levels.indexOf(call.level);
};

export const getHighestOfCalls = (calls: IBid[]) => {
    return [...calls].reduce((previousCall, currentCall) => {
        if (getCallValue(currentCall) > getCallValue(previousCall)) {
            return currentCall;
        } else {
            return previousCall;
        }
    }, calls[0]);
};

export const getDeclarerBridgePosition = (seatData: IGameState['seatData']): BridgePosition | undefined => {
    const seatDataKey = Object.keys(seatData).find(seat => seatData[seat].isDeclarer);
    return seatDataKey ? seatData[seatDataKey].bridgePosition : undefined;
};

export const getDealerBridgePosition = (seatData: IGameState['seatData']): BridgePosition | undefined => {
    const seatDataKey = Object.keys(seatData).find(seat => seatData[seat].isDealer);
    return seatDataKey ? seatData[seatDataKey].bridgePosition : undefined;
};

export const getDummyBridgePosition = (seatData: IGameState['seatData']): BridgePosition | undefined => {
    const declarer = getDeclarerBridgePosition(seatData);
    if (!declarer) {
        return undefined;
    }
    return getPartnerBridgePosition(declarer);
};

export const getPartnerBridgePosition = (bridgePosition: BridgePosition): BridgePosition => {
    return {
        [BridgePosition.north]: BridgePosition.south,
        [BridgePosition.east]: BridgePosition.west,
        [BridgePosition.south]: BridgePosition.north,
        [BridgePosition.west]: BridgePosition.east
    }[bridgePosition];
};

const getPartialInitialSeatData = (attributes: Partial<ISeatData>, bridgePosition: keyof IGameState['seatData']) => {
    return Object.keys(attributes).reduce((partialInitialSeatData, currentValue: string) => {
        return {
            ...partialInitialSeatData,
            // @ts-ignore
            [currentValue]: initialGameState.seatData[bridgePosition][currentValue]
        };
    }, {} as Partial<ISeatData>);
};

export const setUniqueSeatDataAttribute = (
    seatData: IGameState['seatData'],
    attributes: Partial<ISeatData>,
    seat?: BridgePosition
): IGameState['seatData'] => {
    return {
        [BridgePosition.north]: {
            ...seatData[BridgePosition.north],
            ...getPartialInitialSeatData(attributes, BridgePosition.north)
        },
        [BridgePosition.east]: {
            ...seatData[BridgePosition.east],
            ...getPartialInitialSeatData(attributes, BridgePosition.north)
        },
        [BridgePosition.south]: {
            ...seatData[BridgePosition.south],
            ...getPartialInitialSeatData(attributes, BridgePosition.north)
        },
        [BridgePosition.west]: {
            ...seatData[BridgePosition.west],
            ...getPartialInitialSeatData(attributes, BridgePosition.north)
        },
        ...(seat
            ? {
                  [seat]: {
                      ...seatData[seat],
                      ...attributes
                  }
              }
            : {})
    };
};

export const getCssVarsForSuitColorSet = (suitColorSet: number): CSSProperties => {
    return {
        '--card-color-diamonds': possibleSuitColorSets[suitColorSet].diamonds,
        '--card-color-hearts': possibleSuitColorSets[suitColorSet].hearts,
        '--card-color-spades': possibleSuitColorSets[suitColorSet].spades,
        '--card-color-clubs': possibleSuitColorSets[suitColorSet].clubs
    } as React.CSSProperties;
};

export const getClaimNotificationConfig = (bridgePosition: BridgePosition, claim: number) => {
    const id = 'claimNotification';
    return {
        id,
        noClickOutside: true,
        header: (
            <Translate
                contentKey="modal.claim.notification.header"
                interpolate={{
                    bridgePosition: `${bridgePosition[0].toUpperCase() + bridgePosition.substr(1).toLowerCase()}`,
                    claim: `${claim}`
                }}
            />
        ),
        body: [
            <div>
                <Translate contentKey="modal.claim.notification.body" interpolate={{ bridgePosition, claim: `${claim}` }} />
            </div>
        ],
        cancelButtonLabel: <Translate contentKey="modal.reject" />,
        onCancel: () => store.dispatch(approveClaim('reject')),
        buttons: [
            {
                label: <Translate contentKey="modal.approve" />,
                primary: true,
                onClick: () => {
                    store.dispatch(approveClaim('approve'));
                    store.dispatch(removeModal(id));
                }
            }
        ],
        className: id
    };
};

export const getUndoNotificationConfig = (bridgePosition: BridgePosition) => {
    const id = 'undoNotification';
    return {
        id,
        header: <Translate contentKey="modal.undo.notification.header" interpolate={{ bridgePosition }} />,
        body: [
            <div>
                <Translate contentKey="modal.undo.notification.body" interpolate={{ bridgePosition }} />
            </div>
        ],
        cancelButtonLabel: <Translate contentKey="modal.reject" />,
        onCancel: () => store.dispatch(approveUndo('reject')),
        buttons: [
            {
                label: <Translate contentKey="modal.approve" />,
                primary: true,
                onClick: () => {
                    store.dispatch(approveUndo('approve'));
                    store.dispatch(removeModal(id));
                }
            }
        ],
        className: id
    };
};

export const getConnectionLostConfig = (actions: { cancel: () => void; retry: () => void }) => {
    const id = 'connectionLost';
    return {
        id,
        noClickOutside: true,
        noHeaderClose: true,
        header: <Translate contentKey="modal.connectionLost.header" />,
        body: [
            <div>
                <Translate contentKey="modal.connectionLost.body" />
            </div>
        ],
        cancelButtonLabel: <Translate contentKey="modal.connectionLost.cancel" />,
        onCancel: () => actions.cancel(),
        buttons: [
            {
                label: <Translate contentKey="modal.connectionLost.retry" />,
                primary: true,
                onClick: () => {
                    actions.retry();
                    store.dispatch(removeModal(id));
                }
            }
        ],
        className: id
    };
};

export const getInvalidLoginConfig = (actions: { cancel: () => void; retry: () => void }) => {
    const id = 'connectionLost';
    return {
        id,
        noClickOutside: true,
        noHeaderClose: true,
        header: 'Invalid classroom link!',
        body: [<div>Contact your bridge teacher.</div>],
        cancelButtonLabel: <Translate contentKey="modal.connectionLost.cancel" />,
        onCancel: () => actions.cancel(),
        buttons: [
            {
                label: <Translate contentKey="modal.connectionLost.retry" />,
                primary: true,
                onClick: () => {
                    actions.retry();
                    store.dispatch(removeModal(id));
                }
            }
        ],
        className: id
    };
};

export const getSocketErrorConfig = (actions: { cancel: () => void; retry: () => void }) => {
    const id = 'connectionLost';
    return {
        id,
        noClickOutside: true,
        noHeaderClose: true,
        header: 'Network Error!',
        body: [<div>Try to Reconnect, if the problem persists, contact your teacher.</div>],
        cancelButtonLabel: <Translate contentKey="modal.connectionLost.cancel" />,
        onCancel: () => actions.cancel(),
        buttons: [
            {
                label: <Translate contentKey="modal.connectionLost.retry" />,
                primary: true,
                onClick: () => {
                    actions.retry();
                    store.dispatch(removeModal(id));
                }
            }
        ],
        className: id
    };
};

export const getAlert = (body: IModal['body']) => {
    return {
        body,
        cancelButtonLabel: <Translate contentKey="modal.ok" />
    };
};

export const getSplash = (body: IModal['body'], header: IModal['header'], blocking: boolean) => {
    return {
        id: 'NonBlockingSpalash',
        body,
        header,
        isBlockUI: blocking
    };
};

export const getLeftOfBridgePosition = (bridgePosition: BridgePosition, seatData: IGameState['seatData']): BridgePosition | undefined => {
    const leftDisplayPosition = {
        [DisplayPosition.top]: DisplayPosition.right,
        [DisplayPosition.right]: DisplayPosition.bottom,
        [DisplayPosition.bottom]: DisplayPosition.left,
        [DisplayPosition.left]: DisplayPosition.top
    }[seatData[bridgePosition].displayPosition];

    return getBridgePositionFromDisplayPosition(leftDisplayPosition, seatData);
};

export const getBridgePositionFromDisplayPosition = (
    displayPosition: DisplayPosition,
    seatData: IGameState['seatData']
): BridgePosition | undefined => {
    return Object.keys(seatData).find(bridgePosition => seatData[bridgePosition].displayPosition === displayPosition) as BridgePosition;
};

export const isVulnerable = (bridgePosition: BridgePosition, vulnerability: IGameState['vulnerability']): boolean => {
    const bridgePositions = {
        [Vulnerability.none]: [],
        [Vulnerability.ew]: [BridgePosition.east, BridgePosition.west],
        [Vulnerability.ns]: [BridgePosition.north, BridgePosition.south],
        [Vulnerability.both]: [BridgePosition.east, BridgePosition.west, BridgePosition.north, BridgePosition.south]
    };
    const vulnerableBridgePositions: BridgePosition[] = bridgePositions[vulnerability];
    return vulnerableBridgePositions.indexOf(bridgePosition) !== -1;
};

const getKeyValue = (key: string, value: string | number, t: TFunction) => {
    return (
        <div>
            <span className="key">{t(`table.metaData.${key}.label`)}: </span>
            <span
                className="value"
                dangerouslySetInnerHTML={{ __html: insertSuits(`${value === 'default' ? t(`table.metaData.${key}.default`) : value}`) }}
            />
        </div>
    );
};

const arrayToList = (array: Array<any>, t: TFunction, key?: string) => {
    return (
        <div className={`array ${key}`}>
            {array
                .filter(item => item)
                .map(item => {
                    if (!item) {
                        return undefined;
                    } else if (Array.isArray(item)) {
                        return arrayToList(item, t);
                    } else if (typeof item === 'object') {
                        return objectToList(item, t);
                    } else {
                        return <div className="item">{item}</div>;
                    }
                })}
        </div>
    );
};

const objectToList = (object: any, t: TFunction): any[] => {
    const keys: string[] = Object.keys(object);
    return keys
        .filter(key => object[key])
        .map(key => {
            const value = object[key];
            if (Array.isArray(value)) {
                return arrayToList(value, t, key);
            } else if (typeof value === 'object') {
                return objectToList(value, t);
            } else {
                return getKeyValue(key, value as string, t);
            }
        });
};

const iCardIdToShort = (cardId: ICard['id'], t: TFunction) => {
    const array = cardId.split('.');
    return <div className={`card ${array[0]}`}>{t(`rank.short.${array[1]}`)}</div>;
};

export const getMetaDataToList = (metaData: IGameState['metaData'], t: TFunction) => {
    const { eventName, hostName, round, resultUrl, tableResults, welcomeMessage, comment } = metaData;
    const tableResultCols: Array<keyof ITableResult> = ['boardNumber', 'contract', 'declarer', 'lead', 'result', 'nsPoints', 'ewPoints'];
    return [
        ...(welcomeMessage ? [<div className="welcomeMessage" dangerouslySetInnerHTML={{ __html: welcomeMessage }} />] : []),
        ...(comment ? [getKeyValue('comment', comment, t)] : []),
        ...(eventName ? [getKeyValue('eventName', eventName, t)] : []),
        ...(hostName ? [getKeyValue('hostName', hostName, t)] : []),
        ...(round ? [getKeyValue('round', round, t)] : []),
        // ...(resultUrl ? [<a href={resultUrl} target="_blank">{t(`table.metaData.resultUrl.label`)}</a>] : []),
        ...(tableResults && tableResults.length
            ? [
                  <div className="tableResults">
                      <div className="label">{t(`table.metaData.tableResults.label`)}</div>
                      <table>
                          <thead>
                              <tr>
                                  {tableResultCols.map(col => (
                                      <th>{t(`table.metaData.${col}.label`)}</th>
                                  ))}
                              </tr>
                          </thead>
                          <tbody>
                              {tableResults.map(tableResult => (
                                  <tr>
                                      {tableResultCols.map(col => (
                                          <td>
                                              {col === 'lead' && tableResult.lead ? iCardIdToShort(tableResult.lead, t) : tableResult[col]}
                                          </td>
                                      ))}
                                  </tr>
                              ))}
                          </tbody>
                      </table>
                  </div>
              ]
            : [])
    ];
};

export const getGameResultModalConfig = (
    gameResults: IGameState['gameResults'] | IGameState['gameResultsV2'],
    t: TFunction,
    isV2?: boolean
): IModal => {
    if (!gameResults) {
        return {};
    }
    return {
        id: 'gameResultsModal',
        cancelButtonLabel: t('table.metaData.ok'),
        header: (
            <span>
                <span className="base">{gameResults.EventName ? gameResults.EventName : t('gameResults.header')}</span>
                <span className="base">{gameResults.HostName ? '/ ' + gameResults.HostName + ' /' : ''}</span>
                <span className="timeStamp">
                    {gameResults?.Movement} - {gameResults?.Scoring} &nbsp;&nbsp;&nbsp;
                </span>
                <span className="timeStamp">
                    <Translate contentKey={'gameResults.updatedOn'} />:{' '}
                    {moment
                        .utc(moment.unix(gameResults.TimeStamp - 120))
                        .local()
                        .format('dddd, MMMM Do YYYY HH:mm (UTC Z)')}
                </span>
            </span>
        ),
        body: [isV2 ? <GameResultsV2 /> : <GameResults />]
    };
};

export const getGameResultBlockingModalConfig = (
    gameResults: IGameState['gameResults'] | IGameState['gameResultsV2'],
    isV2: boolean
): IModal => {
    if (!gameResults) {
        return {};
    }
    return {
        ...getGameResultModalConfig(gameResults, () => {}, isV2),
        isBlockUI: true
    };
};

export const getDummyDisplayPosition = (seatData: IGameState['seatData']): DisplayPosition | undefined => {
    const dummyBridgePosition = Object.keys(seatData).find(bridgePosition => seatData[bridgePosition].isDummy);
    return dummyBridgePosition ? seatData[dummyBridgePosition].displayPosition : undefined;
};

export const insertSuits = (message: string): string => {
    try {
        let output = message;
        output = output.replace(/[\/|\\]S/gim, '<span class="spade">&#9824;</span>');
        output = output.replace(/[\/|\\]H/gim, '<span class="heart">&#9829;</span>');
        output = output.replace(/[\/|\\]C/gim, '<span class="club">&#9827;</span>');
        output = output.replace(/[\/|\\]D/gim, '<span class="diamond">&#9830;</span>');
        output = output.replace(/[\/|\\]n/gim, '<br />');
        return output;
    } catch (exception) {
        return message;
    }
};

export function classNames(...classes: (false | null | undefined | string)[]): string {
    return classes.filter(Boolean).join(' ');
}
