import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { IBid } from '../../_models/models';
import { IAppState } from '../../store/app.reducer';
import { Sets } from '../card/sets';

import './call.scss';

export interface IBiddingBoxProps {
    call: IBid;
    selectedCardSet: IAppState['layout']['selectedCardSet'];
}

export const Call: FunctionComponent<IBiddingBoxProps> = props => {
    const { t } = useTranslation();
    const { call, selectedCardSet } = props;
    const { level, strain, alertMessage } = call;

    return (
        <section className={`Call ${level} ${strain} ${alertMessage ? 'withAlert' : ''}`}>
            {['double', 'redouble', 'pass', 'auctionAlert', 'auctionStop'].indexOf(strain) !== -1 ? (
                <div>{t(`strain.${strain}`)}</div>
            ) : (
                Sets[selectedCardSet].biddings
            )}
        </section>
    );
};
