import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Svg } from '../../../assets/images/table.svg';
import { IRootState } from '../../store/_root.reducer';

import './table-symbol.scss';
import { BridgePosition, ISeatData } from '../../_models/models';
import { classNames, getDealerBridgePosition, isVulnerable } from '../../_utils/helper';

export interface ITableSymbolProps extends StateProps, DispatchProps {}

const TableSymbol: FunctionComponent<ITableSymbolProps> = props => {
    const { t } = useTranslation();
    const svgRef = useRef(null);
    const [dealer, setDealer] = useState<ISeatData['displayPosition'] | undefined>(undefined);
    const [vulnerabilities, setVulnerabilities] = useState<ISeatData['displayPosition'][]>([]);
    const { game } = props;
    const { seatData, board, vulnerability } = game;

    const setSymbolLabels = () => {
        if (!svgRef) {
            return;
        }
        let tmpVuln: ISeatData['displayPosition'][] = [];
        [BridgePosition.north, BridgePosition.east, BridgePosition.south, BridgePosition.west].forEach(bridgePosition => {
            const label = document.getElementById(`${seatData[bridgePosition].displayPosition}-label`);
            if (label) {
                label.textContent = t('tableSymbol.dealer');
            }
            if (isVulnerable(bridgePosition, vulnerability)) {
                tmpVuln = [...tmpVuln, seatData[bridgePosition].displayPosition];
            }
        });
        setVulnerabilities(tmpVuln);
        const dealerBridgePosition = getDealerBridgePosition(seatData);
        if (dealerBridgePosition) {
            setDealer(seatData[dealerBridgePosition].displayPosition);
        }
        const centerLabel = document.getElementById('center-label');
        if (centerLabel) {
            centerLabel.textContent = `${board?.boardNumber || ''}`;
        }
    };

    useEffect(() => {
        if (svgRef) {
            setSymbolLabels();
        }
    }, [svgRef, seatData]);

    return (
        <section
            className={classNames(
                'TableSymbol',
                `d-${dealer}`,
                ...vulnerabilities.map(v => `v-${v}`),
                `${board?.boardNumber}`.length === 3 && 'digit-3',
                `${board?.boardNumber}`.length > 3 && 'digit-4'
            )}
        >
            <Svg ref={svgRef} />
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TableSymbol);
