import { BridgePosition, IBid, levels, Rank, Strain, strains, Suit, suitLookup, suits, Vulnerability } from '../app/_models/models';
import moment from 'moment/moment';
import { SFSObject, SFSRoom } from 'sfs2x-api';
import { SFSVAR } from './sfsVar';
export const convertSuitRank = (sfsCode: string): string => {
    const translations: { [sfsCode: string]: string } = {
        SA: `${Suit.spades}.${Rank.ace}`,
        SK: `${Suit.spades}.${Rank.king}`,
        SQ: `${Suit.spades}.${Rank.queen}`,
        SJ: `${Suit.spades}.${Rank.jack}`,
        ST: `${Suit.spades}.${Rank.ten}`,
        S9: `${Suit.spades}.${Rank.nine}`,
        S8: `${Suit.spades}.${Rank.eight}`,
        S7: `${Suit.spades}.${Rank.seven}`,
        S6: `${Suit.spades}.${Rank.six}`,
        S5: `${Suit.spades}.${Rank.five}`,
        S4: `${Suit.spades}.${Rank.four}`,
        S3: `${Suit.spades}.${Rank.three}`,
        S2: `${Suit.spades}.${Rank.two}`,
        HA: `${Suit.hearts}.${Rank.ace}`,
        HK: `${Suit.hearts}.${Rank.king}`,
        HQ: `${Suit.hearts}.${Rank.queen}`,
        HJ: `${Suit.hearts}.${Rank.jack}`,
        HT: `${Suit.hearts}.${Rank.ten}`,
        H9: `${Suit.hearts}.${Rank.nine}`,
        H8: `${Suit.hearts}.${Rank.eight}`,
        H7: `${Suit.hearts}.${Rank.seven}`,
        H6: `${Suit.hearts}.${Rank.six}`,
        H5: `${Suit.hearts}.${Rank.five}`,
        H4: `${Suit.hearts}.${Rank.four}`,
        H3: `${Suit.hearts}.${Rank.three}`,
        H2: `${Suit.hearts}.${Rank.two}`,
        DA: `${Suit.diamonds}.${Rank.ace}`,
        DK: `${Suit.diamonds}.${Rank.king}`,
        DQ: `${Suit.diamonds}.${Rank.queen}`,
        DJ: `${Suit.diamonds}.${Rank.jack}`,
        DT: `${Suit.diamonds}.${Rank.ten}`,
        D9: `${Suit.diamonds}.${Rank.nine}`,
        D8: `${Suit.diamonds}.${Rank.eight}`,
        D7: `${Suit.diamonds}.${Rank.seven}`,
        D6: `${Suit.diamonds}.${Rank.six}`,
        D5: `${Suit.diamonds}.${Rank.five}`,
        D4: `${Suit.diamonds}.${Rank.four}`,
        D3: `${Suit.diamonds}.${Rank.three}`,
        D2: `${Suit.diamonds}.${Rank.two}`,
        CA: `${Suit.clubs}.${Rank.ace}`,
        CK: `${Suit.clubs}.${Rank.king}`,
        CQ: `${Suit.clubs}.${Rank.queen}`,
        CJ: `${Suit.clubs}.${Rank.jack}`,
        CT: `${Suit.clubs}.${Rank.ten}`,
        C9: `${Suit.clubs}.${Rank.nine}`,
        C8: `${Suit.clubs}.${Rank.eight}`,
        C7: `${Suit.clubs}.${Rank.seven}`,
        C6: `${Suit.clubs}.${Rank.six}`,
        C5: `${Suit.clubs}.${Rank.five}`,
        C4: `${Suit.clubs}.${Rank.four}`,
        C3: `${Suit.clubs}.${Rank.three}`,
        C2: `${Suit.clubs}.${Rank.two}`
    };
    return translations[sfsCode.toUpperCase()];
};

export const suitArray = [
    'SA',
    'SK',
    'SQ',
    'SJ',
    'ST',
    'S9',
    'S8',
    'S7',
    'S6',
    'S5',
    'S4',
    'S3',
    'S2',
    'HA',
    'HK',
    'HQ',
    'HJ',
    'HT',
    'H9',
    'H8',
    'H7',
    'H6',
    'H5',
    'H4',
    'H3',
    'H2',
    'DA',
    'DK',
    'DQ',
    'DJ',
    'DT',
    'D9',
    'D8',
    'D7',
    'D6',
    'D5',
    'D4',
    'D3',
    'D2',
    'CA',
    'CK',
    'CQ',
    'CJ',
    'CT',
    'C9',
    'C8',
    'C7',
    'C6',
    'C5',
    'C4',
    'C3',
    'C2'
];

export const convertToPbn = (cardId: string): string => {
    const pbnStrings: { [cardId: string]: string } = {
        [`${Suit.spades}.${Rank.ace}`]: 'SA',
        [`${Suit.spades}.${Rank.king}`]: 'SK',
        [`${Suit.spades}.${Rank.queen}`]: 'SQ',
        [`${Suit.spades}.${Rank.jack}`]: 'SJ',
        [`${Suit.spades}.${Rank.ten}`]: 'ST',
        [`${Suit.spades}.${Rank.nine}`]: 'S9',
        [`${Suit.spades}.${Rank.eight}`]: 'S8',
        [`${Suit.spades}.${Rank.seven}`]: 'S7',
        [`${Suit.spades}.${Rank.six}`]: 'S6',
        [`${Suit.spades}.${Rank.five}`]: 'S5',
        [`${Suit.spades}.${Rank.four}`]: 'S4',
        [`${Suit.spades}.${Rank.three}`]: 'S3',
        [`${Suit.spades}.${Rank.two}`]: 'S2',
        [`${Suit.hearts}.${Rank.ace}`]: 'HA',
        [`${Suit.hearts}.${Rank.king}`]: 'HK',
        [`${Suit.hearts}.${Rank.queen}`]: 'HQ',
        [`${Suit.hearts}.${Rank.jack}`]: 'HJ',
        [`${Suit.hearts}.${Rank.ten}`]: 'HT',
        [`${Suit.hearts}.${Rank.nine}`]: 'H9',
        [`${Suit.hearts}.${Rank.eight}`]: 'H8',
        [`${Suit.hearts}.${Rank.seven}`]: 'H7',
        [`${Suit.hearts}.${Rank.six}`]: 'H6',
        [`${Suit.hearts}.${Rank.five}`]: 'H5',
        [`${Suit.hearts}.${Rank.four}`]: 'H4',
        [`${Suit.hearts}.${Rank.three}`]: 'H3',
        [`${Suit.hearts}.${Rank.two}`]: 'H2',
        [`${Suit.diamonds}.${Rank.ace}`]: 'DA',
        [`${Suit.diamonds}.${Rank.king}`]: 'DK',
        [`${Suit.diamonds}.${Rank.queen}`]: 'DQ',
        [`${Suit.diamonds}.${Rank.jack}`]: 'DJ',
        [`${Suit.diamonds}.${Rank.ten}`]: 'DT',
        [`${Suit.diamonds}.${Rank.nine}`]: 'D9',
        [`${Suit.diamonds}.${Rank.eight}`]: 'D8',
        [`${Suit.diamonds}.${Rank.seven}`]: 'D7',
        [`${Suit.diamonds}.${Rank.six}`]: 'D6',
        [`${Suit.diamonds}.${Rank.five}`]: 'D5',
        [`${Suit.diamonds}.${Rank.four}`]: 'D4',
        [`${Suit.diamonds}.${Rank.three}`]: 'D3',
        [`${Suit.diamonds}.${Rank.two}`]: 'D2',
        [`${Suit.clubs}.${Rank.ace}`]: 'CA',
        [`${Suit.clubs}.${Rank.king}`]: 'CK',
        [`${Suit.clubs}.${Rank.queen}`]: 'CQ',
        [`${Suit.clubs}.${Rank.jack}`]: 'CJ',
        [`${Suit.clubs}.${Rank.ten}`]: 'CT',
        [`${Suit.clubs}.${Rank.nine}`]: 'C9',
        [`${Suit.clubs}.${Rank.eight}`]: 'C8',
        [`${Suit.clubs}.${Rank.seven}`]: 'C7',
        [`${Suit.clubs}.${Rank.six}`]: 'C6',
        [`${Suit.clubs}.${Rank.five}`]: 'C5',
        [`${Suit.clubs}.${Rank.four}`]: 'C4',
        [`${Suit.clubs}.${Rank.three}`]: 'C3',
        [`${Suit.clubs}.${Rank.two}`]: 'C2'
    };
    return pbnStrings[cardId];
};

export const convertBidToInt = (bid: IBid): number => {
    return levels.indexOf(bid.level) * 10 + strains.indexOf(bid.strain);
};

export const convertIntToBid = (callId: number, expl: {} | undefined): IBid => {
    // 43
    const levelIndex = Math.floor(callId / 10); // 4
    const strainIndex = callId % 10; // 3

    return {
        level: levels[levelIndex],
        strain: strains[strainIndex],
        explanation: expl
    };
};

export const convertStringToBid = (callStr: string): IBid => {
    if (callStr.toLowerCase() === 'pass' || callStr.toLowerCase() === 'pp')
        return {
            strain: 'pass',
            level: 'zero'
        };

    if (callStr.toLowerCase() === 'x' || callStr.toLowerCase() === 'sx')
        return {
            strain: 'double',
            level: 'zero'
        };

    if (callStr.toLowerCase() === 'xx')
        return {
            strain: 'redouble',
            level: 'zero'
        };

    const levelIndex: number = parseInt(callStr.substring(0, 1)); // 4
    const suitsIndex = suitLookup[callStr.toLowerCase().substring(1, 2)]; // 3
    return {
        level: levels[levelIndex],
        strain: strains[suitsIndex]
    };
};

export const convertIntToBridgePosition = (pos: number): BridgePosition => {
    switch (pos + 1) {
        case 1:
            return BridgePosition.north;
        case 2:
            return BridgePosition.east;
        case 3:
            return BridgePosition.south;
        case 4:
            return BridgePosition.west;
    }
    return BridgePosition.south;
};

export const convertPlayerToBridgePosition = (pos: number): BridgePosition => {
    switch (true) {
        case (pos & 1) !== 0:
            return BridgePosition.north;
        case (pos & 2) !== 0:
            return BridgePosition.east;
        case (pos & 4) !== 0:
            return BridgePosition.south;
        case (pos & 8) !== 0:
            return BridgePosition.west;
    }
    return BridgePosition.south;
};

export const convertPlayerToIntBridgePosition = (pos: number): number => {
    switch (true) {
        case (pos & 1) !== 0:
            return 0;
        case (pos & 2) !== 0:
            return 1;
        case (pos & 4) !== 0:
            return 2;
        case (pos & 8) !== 0:
            return 3;
    }
    return -1;
};

export const convertBridgePositionToInt = (pos: BridgePosition): number => {
    switch (pos) {
        case BridgePosition.north:
            return 0;
        case BridgePosition.east:
            return 1;
        case BridgePosition.south:
            return 2;
        case BridgePosition.west:
            return 3;
    }
    return -1;
};

export const convertBridgePositionToStr = (pos: BridgePosition): string => {
    switch (pos) {
        case BridgePosition.north:
            return 'n';
        case BridgePosition.east:
            return 'e';
        case BridgePosition.south:
            return 's';
        case BridgePosition.west:
            return 'w';
    }
    return 'n';
};

export const convertDummyToDeclarer = (pos: BridgePosition): BridgePosition => {
    switch (pos) {
        case BridgePosition.north:
            return BridgePosition.south;
        case BridgePosition.east:
            return BridgePosition.west;
        case BridgePosition.south:
            return BridgePosition.north;
        case BridgePosition.west:
            return BridgePosition.east;
    }
    return BridgePosition.south;
};

export const convertIntToVulnerabilty = (vuln: number): Vulnerability => {
    switch (vuln) {
        case 0:
            return Vulnerability.none;
        case 1:
            return Vulnerability.ns;
        case 2:
            return Vulnerability.ew;
        case 3:
            return Vulnerability.both;
    }
    return Vulnerability.none;
};

export const convertIntToSuitStr = (suit: number): String => {
    switch (suit) {
        case 0:
            return 'C';
        case 1:
            return 'D';
        case 2:
            return 'H';
        case 3:
            return 'S';
    }
    return '';
};

export const sfsVarStr = (sfs: any, varName: string, defValue: string): String => {
    return sfs.lastJoinedRoom.containsVariable(varName) ? sfs.lastJoinedRoom.getVariable(varName).value : defValue;
};

export const sfsInt = (sfsObject: SFSObject, varName: string, defValue: number | undefined = 0): number | undefined => {
    return sfsObject.containsKey(varName) ? sfsObject.getInt(varName) : defValue;
};
