import React, { FunctionComponent, useState } from 'react';

import { Rank, Suit } from '../../_models/models';
import { Sets } from './sets';

import './card.scss';
import useLongPress from '../../_utils/useLongPress';

export interface ICardProps {
    set: number;
    suit?: Suit;
    rank?: Rank;
    selected?: boolean;
    isVisible: boolean;
    isReallyVisible: boolean;
    privateHighlighted?: boolean;
    onClick?: (suit: Suit, rank: Rank) => void;
    onPrivateHighlight?: () => void;
    raised?: boolean;
}

export const Card: FunctionComponent<ICardProps> = props => {
    const { set, suit, rank, privateHighlighted, onClick, onPrivateHighlight, isReallyVisible, selected, raised } = props;

    const classes = [
        'Card',
        ...(isReallyVisible || raised ? [suit, rank] : []),
        `set-${set}`,
        !!onClick ? 'active' : 'inactive',
        isReallyVisible ? 'show' : 'hide',
        selected ? `selected` : '',
        raised ? `raised` : 'unraised',
        privateHighlighted ? 'privateHighlighted' : ''
    ];

    const handleLongPress = () => {
        // console.log('handleLongPress');
        !!onPrivateHighlight && !!suit && !!rank && onPrivateHighlight();
    };

    const handleClick = () => {
        !!onClick && !!suit && !!rank && onClick(suit, rank);
    };

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500
    };
    const longPressEvent = useLongPress(handleLongPress, handleClick, defaultOptions);

    return (
        <section className={classes.join(' ')}>
            {!!suit && !!rank ? (
                <div className="innerCard" {...longPressEvent}>
                    {Sets[set].ranks}
                    {Sets[set][suit].suits}
                    {[Rank.king, Rank.queen, Rank.jack].indexOf(rank) !== -1 && (
                        // @ts-ignore
                        <img src={Sets[set][suit][rank]} className="face" alt="" />
                    )}
                </div>
            ) : (
                <div
                    className="innerCard"
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleClick();
                    }}
                >
                    {Sets[set].ranks}
                </div>
            )}
        </section>
    );
};
