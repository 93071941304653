import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useLocalStorage from 'react-use-localstorage';
import { LabeledInput } from '../../_basics/labeled-input/labeled-input';

import { BridgePosition, GamePhase, ISeatData } from '../../_models/models';
import { getGameResultModalConfig, getMetaDataToList, insertSuits } from '../../_utils/helper';
import { IRootState } from '../../store/_root.reducer';
import { addModal, removeCurrentModal, removeModal, setSettings } from '../../store/app.reducer';
import {
    IGameState,
    setActiveSeat,
    setConventionCard,
    setConventionCardUrl,
    setDirectorIssue,
    setDirectorIssueDone,
    setVideo
} from '../../store/game.reducer';
import { changePlay, closeResults, director, invite, IOutputState, prevNext, sendConventionCardUrl } from '../../store/output.reducer';
import { Countdown } from '../countdown/countdown';

import './tray.scss';
import BoardReview from '../board-review/board-review';
import { Translate } from '../../_basics/translate';
import store from '../../store/store';
import { SFSVAR } from '../../../game-engine-interface/sfsVar';
import { SFSObject } from 'sfs2x-api';

export interface ITrayProps extends StateProps, DispatchProps {
    currentSeatData: ISeatData;
    onToggleShow?: () => void;
    onClaim: () => void;
    onUndo: () => void;
    tableId?: string;
    isSpectator?: IGameState['isSpectator'];
    onSetMyBridgePosition?: (bridgePosition: BridgePosition) => void;
}

const Tray: FunctionComponent<ITrayProps> = props => {
    const { app, game, currentSeatData, onToggleShow, onClaim, onUndo, onSetMyBridgePosition, tableId, closeResults } = props;
    const { bridgePosition, isMe, player, hcp, isDummy, isVisible, isActive, isHighlighted } = currentSeatData;
    const {
        board,
        bpnRecord,
        buttons,
        conventionCards,
        conventionCardUrlSet,
        directorIssue,
        directorIssueDone,
        gamePhase,
        gameResults,
        gameResultsV2,
        isSpectator,
        jitsi,
        metaData,
        showInvites,
        timeToGameEnd,
        twitch,
        video
    } = game;
    const { settings, urlParams } = app;
    const [storedConventionCardUrl, setStoredConventionCardUrl] = useLocalStorage('conventionCardUrl', undefined);
    const [storedEmails, setStoredEmails] = useLocalStorage('emails', undefined);
    const [conventionCardUrl, setConventionCardUrl] = useState<string>('');
    const [inviteMail, setInviteMail] = useState<string>('');
    const [showInvite, setShowInvite] = useState(false);
    const [emailArray, setEmailArray] = useState<string[]>([]);
    const { t } = useTranslation();
    useEffect(() => {
        if (isMe && conventionCardUrlSet) {
            props.setConventionCardUrl(false);
            props.sendConventionCardUrl(conventionCards.url);
        }
    }, [conventionCardUrlSet]);
    useEffect(() => {
        if (isMe && directorIssueDone) {
            props.director(directorIssue);
            props.setDirectorIssue('');
            props.setDirectorIssueDone(false);
        }
    }, [directorIssueDone]);
    const showFlipCardsButton = isVisible && !isMe && !isDummy;
    const classes = [
        'Tray',
        isActive ? 'isActive' : '',
        isHighlighted ? 'isHighlighted' : '',
        ...settings.currentPlayerHighlight.map(currentPlayerDisplay => `currentPlayerDisplay-${currentPlayerDisplay}`)
    ];

    useEffect(() => {
        try {
            setEmailArray(storedEmails.split('|'));
        } catch (e) {
            setEmailArray([]);
        }
    }, [storedEmails]);

    const style = {
        ...(isHighlighted && settings.currentPlayerHighlight.indexOf('background') !== -1
            ? { background: settings.selectedCurrentPlayerBackground }
            : {})
    };

    const handleClaim = () => {
        if (buttons.indexOf('claim') !== -1) {
            onClaim();
        }
    };

    const handleUndo = () => {
        if (buttons.indexOf('undo') !== -1) {
            onUndo();
        }
    };

    const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob(['12345678'], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${urlParams.sfsteacher.replace(/[^-\d\w]/, '')}-${moment(Date.now()).format(t('base.dateFormat'))}.bpn`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
    };

    const handleSetMyBridgePosition = () => {
        if (isSpectator && onSetMyBridgePosition) {
            onSetMyBridgePosition(currentSeatData.bridgePosition);
        }
    };

    const handleTrayClick = () => {
        if (gamePhase === GamePhase.DEAL) {
            props.setActiveSeat(currentSeatData.bridgePosition);
        }
    };

    const handleToggleVideoAttribute = (attribute: keyof IGameState['video']) => () => {
        props.setVideo({ [attribute]: !video[attribute] });
    };

    const hasMetaData = () => {
        const { round, hostName, eventName, comment } = metaData;
        const keys = Object.keys(metaData).map(key => key as keyof IGameState['metaData']);
        keys.filter(key => metaData[key]);

        return !!keys.filter(key => metaData[key]);
    };

    const handleChangeConventionCardUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setConventionCard('url', e.target.value);
    };

    const handleSubmitChangeConventionCardUrl = () => {
        props.setConventionCardUrl(true);
    };

    const handleSubmitDirectorIssue = () => {
        props.removeModal('contactDirectorModal');
        props.setDirectorIssueDone(true);
    };

    const handleInvite = () => {
        const mailCheck = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (mailCheck.test(inviteMail)) {
            props.invite({
                email: inviteMail,
                bridgePosition
            });
            setShowInvite(false);
            if (!emailArray.includes(inviteMail)) {
                setStoredEmails([...(storedEmails ? storedEmails.split('|') : []), inviteMail].join('|'));
            }
        } else {
            console.log('Invalid Email Address');
        }
    };

    const showConventionCardModal = (partnership: keyof IGameState['conventionCards']) => () => {
        const url = conventionCards[partnership];
        if (url) {
            props.removeModal('metaDataModal');
            props.addModal({
                id: 'conventionCardModal',
                noCancel: true,
                header: t('table.conventionCardModal.header', { partnership: partnership.toUpperCase() }),
                body: [<iframe src={url} />]
            });
        }
    };

    const showContactDirectorModal = () => {
        props.removeModal('metaDataModal');
        props.addModal({
            id: 'contactDirectorModal',
            header: t('table.contactDirectorModal.header'),
            body: [
                <div className="submitIssue">
                    <LabeledInput
                        label={t(`table.contactDirectorModal.label`)}
                        type="text"
                        value={directorIssue}
                        onChange={e => props.setDirectorIssue(e.target.value)}
                    />
                </div>
            ],
            buttons: [{ label: t(`table.metaData.conventionCard.submit.button`), onClick: handleSubmitDirectorIssue }],
            onCancel: () => {
                props.setDirectorIssue('');
            }
        });
    };

    const showConventionCard = (partnership: keyof IGameState['conventionCards']) => () => {
        const url = conventionCards[partnership];
        window.open(url, '_blank');
    };

    const renderConventionCards = () => {
        return (
            <div className="conventionCards">
                <div className="conventionCard">
                    <span>{t(`table.metaData.conventionCard.ns.label`)}</span>
                    <button onClick={showConventionCard('ns')} disabled={!conventionCards.ns}>
                        {t(`table.metaData.conventionCard.ns.button`)}
                    </button>
                </div>
                <div className="conventionCard">
                    <span>{t(`table.metaData.conventionCard.ew.label`)}</span>
                    <button onClick={showConventionCard('ew')} disabled={!conventionCards.ew}>
                        {t(`table.metaData.conventionCard.ew.button`)}
                    </button>
                </div>
                <div className="submitUrl">
                    <LabeledInput
                        label={t(`table.metaData.conventionCard.submit.placeholder`)}
                        type="text"
                        value={conventionCardUrl}
                        onChange={handleChangeConventionCardUrl}
                    />
                    <button onClick={handleSubmitChangeConventionCardUrl}>{t(`table.metaData.conventionCard.submit.button`)}</button>
                </div>
            </div>
        );
    };

    const showMetaData = () => {
        const { welcomeMessage, resultUrl, director, isSupervised } = metaData;
        props.removeCurrentModal();
        props.addModal({
            id: 'metaDataModal',
            cancelButtonLabel: t('table.metaData.ok'),
            // header: welcomeMessage ? welcomeMessage : undefined,
            body: [...getMetaDataToList(metaData, t), renderConventionCards()],
            noCancel: true,
            buttons: [
                ...(director && isSupervised ? [{ label: director, onClick: () => showContactDirectorModal() }] : []),
                ...(resultUrl ? [{ label: t(`table.metaData.resultUrl.label`), onClick: showResultsUrlModal }] : []),
                ...(gameResults || gameResultsV2 ? [{ label: t(`table.metaData.gameResults.label`), onClick: showGameResultsModal }] : []),
                {
                    label: t('table.metaData.boardReview'),
                    onClick: () => {
                        metaData.isBiddingQuiz ? showAuctionReviewModal() : showBoardReviewModal();
                        props.removeModal('metaDataModal');
                    }
                },
                { label: t('table.metaData.ok'), onClick: () => props.removeModal('metaDataModal') }
            ]
        });
    };

    const showGameResultsModal = () => {
        props.removeModal('metaDataModal');
        props.addModal({
            ...getGameResultModalConfig(gameResultsV2 ?? gameResults, t, !!gameResultsV2),
            onCancel: closeResults
        });
    };

    const showResultsUrlModal = () => {
        const { resultUrl } = metaData;
        props.removeModal('metaDataModal');
        props.addModal({
            id: 'gameResultsUrlModal',
            cancelButtonLabel: t('table.metaData.ok'),
            header: <span>GameResults</span>,
            body: [<iframe src={resultUrl} />]
        });
    };

    const showBoardReviewModal = () => {
        props.addModal({
            id: 'showBoardReviewModal',
            body: [<BoardReview />],
            noCancel: true,
            buttons: [{ label: t('table.boardReview.ok'), onClick: () => props.removeModal('showBoardReviewModal') }]
        });
    };

    const showAuctionReviewModal = () => {
        const id = 'nextBoard';
        const { comment } = metaData;

        props.addModal({
            id,
            noClickOutside: true,
            noHeaderClose: true,
            body: [<div dangerouslySetInnerHTML={{ __html: insertSuits(comment ?? '') }} />],
            cancelButtonLabel: <Translate contentKey="modal.ok" />,
            onCancel: () => store.dispatch(removeModal(id)),
            buttons: [],
            className: id,
            showForeignBoardReviewData: true,
            fullSize: true
        });
    };

    const handleChangePlay = (type: IOutputState['changePlay']) => () => {
        props.changePlay(type);
    };

    const handlePrevNext = (type: 'next' | 'prev') => () => {
        props.prevNext(type);
    };

    const videoOptions = ['micro', 'camera'];

    const handleSelectEmail = (email: string) => {
        console.log('email', email);
        setInviteMail(email);
    };

    const invite = !isMe && !isSpectator && showInvites && (
        <div className="invite">
            {showInvite && (
                <div className="input">
                    <div className="storedEmails">
                        {emailArray
                            .filter(email => email.startsWith(inviteMail) && email !== inviteMail)
                            .map(email => (
                                <div className="storedEmail" onClick={() => handleSelectEmail(email)}>
                                    {email}
                                </div>
                            ))}
                    </div>
                    <input autoComplete="off" type="text" name="invite" value={inviteMail} onChange={e => setInviteMail(e.target.value)} />
                </div>
            )}
            {showInvite && <button onClick={handleInvite} className="sendInvite" />}
            {showInvite && <button onClick={() => setShowInvite(false)} className="closeInvite" />}
            {!showInvite && (
                <button onClick={() => setShowInvite(true)} className="showInvite">
                    @
                </button>
            )}
        </div>
    );

    return (
        <section className={classes.join(' ')} style={style} onClick={handleTrayClick}>
            <div className="general">
                <div className="bridgePosition">{t(`tray.bridgePosition.${bridgePosition}`)}</div>
                {!isMe && hcp && <div className="hcp">{hcp} HCP</div>}
                <div className="player">
                    <div className="name">{player}</div>
                    {isMe && (
                        <div className="isMe">
                            ({t('tray.isMe')}
                            {tableId && ` ${tableId}`})
                        </div>
                    )}
                    {isDummy && <div className="isDummy">({t('tray.isDummy')})</div>}
                </div>
                <div className="actions">
                    {!isMe && isSpectator && <button className="rotateTable" onClick={handleSetMyBridgePosition} />}
                    {showFlipCardsButton && <button className="toggleShowCards" onClick={onToggleShow} />}
                    {isActive && <div className="isActive" />}
                    {invite}
                </div>
            </div>
            {gamePhase !== GamePhase.DEAL && isMe && (
                <div className={`justMe ${buttons.join(' ')}`}>
                    {timeToGameEnd != null && (
                        <div className="info">
                            <div className="timeToGameEnd">
                                <Countdown startTime={timeToGameEnd} />
                            </div>
                        </div>
                    )}
                    <div className="buttonsCenter">
                        {buttons.includes('prev') && <button className="prev" onClick={handlePrevNext('prev')} />}
                        {buttons.includes('next') && <button className="next" onClick={handlePrevNext('next')} />}
                        {buttons.includes('claim') && (
                            <button className="claim" onClick={handleClaim}>
                                {t('tray.claim')}
                            </button>
                        )}
                        {buttons.includes('undo') && (
                            <button className="undo" onClick={handleUndo}>
                                {t('tray.undo')}
                            </button>
                        )}
                        {buttons.includes('download') && !!bpnRecord && (
                            <button className="download" onClick={handleDownload}>
                                {t('tray.download')}
                            </button>
                        )}
                        {buttons.includes('startPlay') && (
                            <button className="startPlay" onClick={handleChangePlay('startPlay')}>
                                {t('tray.startPlay')}
                            </button>
                        )}
                        {buttons.includes('stopPlay') && (
                            <button className="stopPlay" onClick={handleChangePlay('stopPlay')}>
                                {t('tray.stopPlay')}
                            </button>
                        )}
                        {buttons.includes('replay') && (
                            <button className="replay" onClick={handleChangePlay('replay')}>
                                {t('tray.replay')}
                            </button>
                        )}
                    </div>
                    <div className="buttonsRight">
                        {!!jitsi.roomName &&
                            videoOptions.map(key => {
                                const attribute = key as keyof IGameState['video'];
                                return (
                                    <button
                                        onClick={handleToggleVideoAttribute(attribute)}
                                        className={`video ${attribute} ${video[attribute]}`}
                                    />
                                );
                            })}
                        {hasMetaData() && <button onClick={showMetaData} className="showMetaData" />}
                        <button className="showSettings" onClick={() => props.setSettings({ visible: true })} />
                    </div>
                </div>
            )}
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {
    addModal,
    removeCurrentModal,
    changePlay,
    closeResults,
    director,
    invite,
    prevNext,
    removeModal,
    sendConventionCardUrl,
    setActiveSeat,
    setConventionCard,
    setConventionCardUrl,
    setDirectorIssue,
    setDirectorIssueDone,
    setSettings,
    setVideo
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Tray);
