import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';

import { ChatEntry } from '../../_models/models';
import { IRootState } from '../../store/_root.reducer';
import { clearChat, clearSystemChat } from '../../store/game.reducer';
import { sendChatMessage } from '../../store/output.reducer';

import './chat.scss';
import { insertSuits } from '../../_utils/helper';

export interface IChatProps extends StateProps, DispatchProps {}

export const Chat: FunctionComponent<IChatProps> = props => {
    const { t } = useTranslation();
    const chatContainer = useRef<HTMLDivElement>(null);
    const [message, setMessage] = useState('');
    const [isDirectorMessage, setIsDirectorMessage] = useState(false);
    const [lastChatEntry, setLastChatEntry] = useState<ChatEntry | undefined>(undefined);
    const { app, game } = props;
    const { chat } = game;

    useEffect(() => {
        const _lastCHatEntry = game.chat[game.chat.length - 1];
        if (_lastCHatEntry !== lastChatEntry) {
            setLastChatEntry(_lastCHatEntry);
            if (chatContainer.current) {
                chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
            }
        }
        // setTimeout(() => {
        //     props.clearSystemChat();
        // }, 30000);
    }, [game.chat]);

    const sendMessage = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (message) {
            const chatEntry: ChatEntry = {
                sender: app.user || 'unknown',
                timestamp: moment.utc().format(),
                message
            };
            props.sendChatMessage({ ...chatEntry, isDirectorMessage });
            setMessage('');
            setIsDirectorMessage(false);
        }
    };

    return (
        <section className={`Chat`}>
            <div className="chat" ref={chatContainer}>
                {chat.map((chatEntry, index) => {
                    return (
                        !!chatEntry && (
                            <div
                                key={`${chatEntry.sender}-${chatEntry.timestamp}-${index}`}
                                className={`chatEntry ${chatEntry.sender === app.user ? 'isMe' : ''}`}
                            >
                                <header>
                                    <div className="sender">{chatEntry.sender}</div>
                                    <div className="time">{moment(chatEntry.timestamp).format('HH:mm')}</div>
                                </header>
                                <div className="text" dangerouslySetInnerHTML={{ __html: insertSuits(chatEntry.message) }} />
                            </div>
                        )
                    );
                })}
            </div>
            {game.showChatSendForm && (
                <form onSubmit={sendMessage} className="sendMessage">
                    <div className="input">
                        <input
                            type="text"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            placeholder={t(`chat.${isDirectorMessage ? 'typeDirectorMessage' : 'typeMessage'}`)}
                        />
                    </div>
                    {message ? <button type="submit" /> : <button type="button" onClick={() => props.clearChat()} />}
                    {message ? (
                        <button
                            type="submit"
                            className={`toggleDirectorMessage`}
                            onClick={() => setIsDirectorMessage(!isDirectorMessage)}
                        />
                    ) : (
                        <button
                            type="button"
                            className={`toggleDirectorMessage ${isDirectorMessage && 'isDirectorMessage'}`}
                            onClick={() => setIsDirectorMessage(!isDirectorMessage)}
                        />
                    )}
                </form>
            )}
        </section>
    );
};

const mapStateToProps = ({ app, game }: IRootState) => ({
    app,
    game
});

const mapDispatchToProps = {
    sendChatMessage,
    clearChat,
    clearSystemChat
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
