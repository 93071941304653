import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GamePhase, IModalButton } from '../_models/models';

import { IRootState } from '../store/_root.reducer';
import { removeModal } from '../store/app.reducer';

import './modal.scss';
import AuctionBox from '../_elements/auction-box/auction-box';
import { classNames } from '../_utils/helper';
import ShortTable from '../_elements/short-table/short-table';

export interface IModalProps extends StateProps, DispatchProps {}

const Modal: FunctionComponent<IModalProps> = props => {
    const { t } = useTranslation();
    const { modal, game, app } = props;
    const { foreignBoardReviewData } = game;

    if (!modal) {
        return null;
    }

    const {
        body,
        buttons,
        cancelButtonLabel,
        className,
        header,
        id,
        isBlockUI,
        noClickOutside,
        noHeaderClose,
        noCancel,
        onCancel,
        showForeignBoardReviewData,
        fullSize
    } = modal;

    const handleCancel = () => {
        if (isBlockUI) {
            return;
        }
        props.removeModal(id);
        !!onCancel && onCancel();
    };

    const handleClickOutside = () => {
        if (!noClickOutside) {
            handleCancel();
        }
    };

    const handleClickHeaderClose = () => {
        if (!noHeaderClose) {
            handleCancel();
        }
    };

    const handleButtonClick = (onClick: IModalButton['onClick']) => () => {
        if (isBlockUI) {
            return;
        }
        if (onClick) {
            onClick(id);
        }
    };

    return (
        <section className={classNames('Modal', id, className, fullSize && 'fullSize')}>
            <div className="backdrop" onClick={handleClickOutside} />
            <div className="modal">
                {header && (
                    <header className={`${!isBlockUI ? 'hasCancel' : ''}`}>
                        {header}
                        {!isBlockUI && !noHeaderClose && (
                            <button className="cancel" onClick={handleCancel}>
                                {t('modal.close')}
                            </button>
                        )}
                    </header>
                )}
                <div className="body">
                    {showForeignBoardReviewData && foreignBoardReviewData ? (
                        <div className="auction-board-review">
                            <ShortTable
                                seatData={game.seatData}
                                contract={game.contract}
                                gamePhase={game.gamePhase}
                                cards={game.cards}
                                layout={app.layout}
                                scale={1.2}
                            />
                            <div className="auctions">
                                {foreignBoardReviewData.playerData.map((playerData, index) => (
                                    <div>
                                        <h3>{index === 0 ? 'Your Auction' : 'Recommended'}</h3>
                                        <AuctionBox
                                            foreignBoardData={{
                                                auction: playerData.auction,
                                                seatData: foreignBoardReviewData.seatData,
                                                showAuctionBox: foreignBoardReviewData.showAuctionBox,
                                                vulnerability: foreignBoardReviewData.vulnerability
                                            }}
                                        />
                                    </div>
                                ))}
                                <div className="text">
                                    {body?.map((bodyPart, index) => (
                                        <React.Fragment key={`bodyPart-${index}`}>{bodyPart}</React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {body?.map((bodyPart, index) => (
                                <React.Fragment key={`bodyPart-${index}`}>{bodyPart}</React.Fragment>
                            ))}
                        </>
                    )}
                </div>
                {!isBlockUI && !(!buttons && noCancel) && (
                    <footer className={!buttons || !buttons.length ? 'singleButton' : ''}>
                        {!noCancel && (
                            <button type="button" className="cancel" onClick={handleCancel}>
                                {cancelButtonLabel ? cancelButtonLabel : t('modal.cancel')}
                            </button>
                        )}
                        {(buttons || []).map((button, index) => {
                            const { className: buttonClassName, label, onClick, primary, to, externalTo, target } = button;
                            const buttonProps = {
                                key: `${id}-button-${index}`,
                                label,
                                onClick: handleButtonClick(onClick),
                                className: `${buttonClassName ? buttonClassName : ''} ${primary ? 'primary' : ''}`
                            };
                            if (externalTo) {
                                return (
                                    <a href={externalTo} {...buttonProps} {...(target ? { target } : {})}>
                                        {button.label}
                                    </a>
                                );
                            } else if (to) {
                                return (
                                    <Link to={to} {...buttonProps}>
                                        {button.label}
                                    </Link>
                                );
                            } else {
                                return (
                                    <button type="button" {...buttonProps}>
                                        {button.label}
                                    </button>
                                );
                            }
                        })}
                    </footer>
                )}
            </div>
        </section>
    );
};

const mapStateToProps = ({ app, game }: IRootState) => ({
    modal: app.modals[0],
    app,
    game
});

const mapDispatchToProps = {
    removeModal
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
