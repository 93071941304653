import React, { Suspense } from 'react';
import { MemoryRouter, Route, Switch, Redirect } from 'react-router-dom';

import './App.scss';
import AudioPlayer from './app/_basics/audioPlayer';
import Login from './app/_elements/login/login';
import Root from './app/root/root';
import Table from './app/table/table';

function App() {
    return (
        <Suspense fallback={null}>
            <section className={`App`}>
                <MemoryRouter>
                    <Root>
                        <AudioPlayer />
                        <Switch>
                            <Route path="/table">
                                <Table />
                            </Route>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/register">
                                <Login />
                            </Route>
                            <Redirect to="/login" />
                        </Switch>
                    </Root>
                </MemoryRouter>
            </section>
        </Suspense>
    );
}

export default App;
