import cardBack0 from '../../assets/images/cardBacks/0.png';
import { GeneralLayout, HandLayout } from '../_models/models';

export const possibleSuitOrders: Array<'trump' | 'SHCD' | 'SHDC'> = ['trump', 'SHCD', 'SHDC'];

export const possibleSuitColorSets: Array<{ [suit: string]: string }> = [
    {
        diamonds: '#c36630',
        spades: '#1d4685',
        hearts: '#b42a3d',
        clubs: '#2d6732'
    },
    {
        diamonds: '#b42a3d',
        spades: '#000000',
        hearts: '#b42a3d',
        clubs: '#000000'
    }
];

export const possibleCardBackgrounds: string[] = ['#ffffff', '#f4efd5'];

export const possibleCardBacks: string[] = [cardBack0];

export const cardSymbols = ['🂮', '🂦', '🂸', '🃋', '🃑'];

export type PossibleCurrentPlayerHighlight = 'border' | 'background' | 'animation';
export const trayBackgrounds: string[] = ['#c36630', '#9a9e28', '#00a600', '#007f00', '#2d621d'];
export const tableBackgrounds: string[] = ['#0c0', '#070', '#00f', '#fff'];

export const possibleCurrentPlayerHighlights: PossibleCurrentPlayerHighlight[] = ['border', 'background', 'animation'];

export type PossibleButton = 'claim' | 'undo' | 'download' | 'startPlay' | 'stopPlay' | 'replay' | 'prev' | 'next';
export const possibleButtons: PossibleButton[] = ['claim', 'undo', 'download', 'startPlay', 'stopPlay', 'replay', 'prev', 'next'];

export type PossibleInteraction = 'instant' | 'confirm';
export const possibleInteractions: PossibleInteraction[] = ['instant', 'confirm'];

export const possibleGeneralLayouts: GeneralLayout[] = ['default', 'v_impaired'];
export const possibleHandLayouts: HandLayout[] = ['straight', 'fan', 'stacked'];
