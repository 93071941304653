import { createStore, applyMiddleware } from 'redux';
import createDebounce from 'redux-debounced';
import thunkMiddleware from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './_root.reducer';

const defaultMiddlewares = [createDebounce(), thunkMiddleware, loadingBarMiddleware()];

const composedMiddlewares = (middlewares: any) => composeWithDevTools(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState?: any, middlewares = []) => {
    return createStore(rootReducer, initialState, composedMiddlewares(middlewares));
};

export const store = initialize();

export default store;
