export const ACTION_TYPES = {
    SHOW: 'example/SHOW',
    HIDE: 'example/HIDE',
    RESET: 'example/RESET'
};

export interface IExampleState {
    show: boolean;
}

export const initialState: IExampleState = {
    show: false
};

// Reducer

export default (state: IExampleState = initialState, action: any): IExampleState => {
    switch (action.type) {
        case ACTION_TYPES.SHOW:
            return {
                ...state,
                show: true
            };
        case ACTION_TYPES.HIDE:
            return {
                ...state,
                show: false
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

// Actions

const showExample = () => ({
    type: ACTION_TYPES.SHOW
});

const hideExample = () => ({
    type: ACTION_TYPES.HIDE
});

const resetExample = () => ({
    type: ACTION_TYPES.RESET
});

export { showExample, hideExample, resetExample };
