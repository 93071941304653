import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { isMobile } from 'react-device-detect';
import { getCssVarsForSuitColorSet } from '../_utils/helper';
import Mock from '../_utils/mock/mock';
import Modal from '../modals/modal';
import { possibleCardBackgrounds } from '../settings/options';
import Settings from '../settings/settings';
import { IRootState } from '../store/_root.reducer';
import {
    addModal,
    removeModal,
    requestLogin,
    requestLogout,
    setHasOverSize,
    setLoggedIn,
    setUrlParams,
    setUuid
} from '../store/app.reducer';

import './root.scss';

export interface IRootProps extends StateProps, DispatchProps, RouteComponentProps {}

const Root: FunctionComponent<IRootProps> = props => {
    const { t } = useTranslation();
    const { children, colorSets, isLoggedIn, user, location, tableBackground, urlParams, settings, isBlockedByTeacher } = props;
    const [storedUrlParamsString, setStoredUrlParamsString] = useLocalStorage('urlParamsString', undefined);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    const urlHasParams = window.location.href.indexOf('?') !== -1;

    let urlParamsString = urlHasParams ? window.location.href.substr(window.location.href.indexOf('?') + 1) : '';
    urlParamsString = urlParamsString ? urlParamsString : storedUrlParamsString;

    const setLocalUrlParams = () => {
        const searchParams = new URLSearchParams(urlParamsString);

        let _urlParams = {};
        // @ts-ignore
        for (let pair of searchParams) {
            _urlParams = {
                ..._urlParams,
                [pair[0]]: pair[1]
            };
        }

        if (!Object.keys(urlParams).length && Object.keys(_urlParams).length) {
            props.setUrlParams(_urlParams);
        }
    };

    const hasOverSize = () => {
        return false;
        return window.innerWidth / window.innerHeight > 1.99;
    };

    useEffect(() => {
        if (storedUrlParamsString !== urlParamsString) {
            setStoredUrlParamsString(urlParamsString);
        }
        window.addEventListener('resize', () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
            props.setHasOverSize(hasOverSize());
        });
        setLocalUrlParams();
        props.setHasOverSize(hasOverSize());
    }, []);

    useEffect(() => {
        setLocalUrlParams();
    }, [urlParams]);

    if (!storedUrlParamsString && !urlParamsString) {
        props.addModal({
            header: t('modal.cannotConnect.header'),
            body: [<div>{t('modal.cannotConnect.body')}</div>],
            isBlockUI: true
        });
    }

    if (!isLoggedIn && location.pathname !== '/login' && location.pathname !== '/register') {
        return <Redirect to="/login" />;
    }

    if (isLoggedIn && location.pathname === '/login') {
        return <Redirect to="./table" />;
    }

    const baseWidth = 1400;
    const baseHeight = (baseWidth * 1330) / 2048;

    const scaleH = dimensions.width / baseWidth;
    const scaleV = dimensions.height / baseHeight;

    const style = {
        ...getCssVarsForSuitColorSet(colorSets.suits),
        '--card-color-background': possibleCardBackgrounds[colorSets.background],
        '--app-background': isBlockedByTeacher ? settings.tableBackgrounds.blockedByTeacher : settings.tableBackgrounds.default,
        '--vh': `${dimensions.height}px`,
        '--scale-video': Math.min(scaleH, scaleV) * 0.78,
        ...(settings.animations ? {} : { '--transition-time': 0 })
    } as React.CSSProperties;

    const classes = ['Root', isBlockedByTeacher ? 'isBlockedByTeacher' : '', isMobile ? 'isMobile' : 'isNotMobile'];

    return (
        <section className={classes.join(' ')} style={style}>
            {children}
            {settings.showMock && window.location.hostname === 'localhost' && <Mock />}
            <Settings />
            <Modal />
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    colorSets: app.layout.colorSets,
    tableBackground: app.layout.tableBackground,
    settings: app.settings,
    isLoggedIn: app.isLoggedIn,
    isBlockedByTeacher: game.isBlockedByTeacher,
    user: app.user,
    token: app.token,
    urlParams: app.urlParams,
    rememberMe: app.rememberMe
});

const mapDispatchToProps = {
    requestLogin,
    requestLogout,
    setLoggedIn,
    setUrlParams,
    addModal,
    removeModal,
    setHasOverSize
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Root));
