// in this file no reducer files can be imported

import { ICards, Rank, Suit } from '../_models/models';

export const getInitialCards = () => {
    const suits = enumToArray(Suit);
    const ranks = enumToArray(Rank);

    const cards: ICards = {};

    suits.forEach(suit => {
        ranks.forEach(rank => {
            cards[`${suit}.${rank}`] = {
                id: `${suit}.${rank}`,
                suit: suit as Suit,
                rank: rank as Rank,
                position: 'deck',
                raised: false
            };
        });
    });

    return cards;
};

export const enumToArray = (E: any) => {
    const keys = Object.keys(E).filter(k => typeof E[k as any] === 'number');
    return !keys.length ? Object.keys(E) : keys;
};
