import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../store/_root.reducer';

import './table-slot.scss';

export interface IClientSize {
    h: number;
    w: number;
}

export interface ITableSlotProps extends StateProps, DispatchProps {
    row: 'top' | 'middle' | 'bottom';
    col: 'left' | 'center' | 'right';
    clientSize?: IClientSize;
    children?: JSX.Element | false;
}

export const TableSlot: FunctionComponent<ITableSlotProps> = props => {
    const { children, col, row } = props;
    const tableSlot = useRef(null);
    const inner = useRef(null);

    const classes = ['TableSlot', `${row}-${col}`];

    return (
        <section className={classes.join(' ')} ref={tableSlot}>
            <div className="innerTableSlot" ref={inner}>
                {children}
            </div>
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TableSlot);
