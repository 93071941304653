import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BridgePosition, levels } from '../../_models/models';
import { getDeclarerBridgePosition } from '../../_utils/helper';

import { IRootState } from '../../store/_root.reducer';
import { IGameState } from '../../store/game.reducer';
import { Call } from '../call/call';

import './trick-count.scss';

export interface ITrickCountProps extends StateProps, DispatchProps {}

const TrickCount: FunctionComponent<ITrickCountProps> = props => {
    const { app, game } = props;
    const { layout, urlParams } = app;
    const { board, trickCount, seatData, contract, stake } = game;
    const declarer = getDeclarerBridgePosition(seatData);
    const { ns, ew } = trickCount;
    const { t } = useTranslation();

    const getOfTranslation = (partner: keyof IGameState['trickCount']) => {
        if (!contract || !contract.level) {
            return '';
        }
        const of = urlParams.objective ?? levels.indexOf(contract.level) + 6;
        if (partner === 'ns') {
            return declarer === BridgePosition.north || declarer === BridgePosition.south ? t('trickCount.of', { of }) : '';
        } else if (partner === 'ew') {
            return declarer === BridgePosition.east || declarer === BridgePosition.west ? t('trickCount.of', { of }) : '';
        } else {
            return '';
        }
    };

    return (
        <section className={`TrickCount`}>
            <div className="contractStake">
                <div className="board">
                    {!!board && [
                        ...(board.label
                            ? [
                                  <div key="boardLabel" className="boardLabel">
                                      {board.label}
                                  </div>
                              ]
                            : []),
                        ...(board.value
                            ? [
                                  <div key="boardValue" className="boardValue">
                                      {board.value}
                                  </div>
                              ]
                            : [])
                    ]}
                </div>
                <div>
                    {!urlParams.objective && !!contract && (
                        <div className="contract">
                            <div className="declarer">{declarer && t(`bridgePosition.${declarer}`).substr(0, 1)}</div>
                            <Call key={`${contract.level}-${contract.strain}`} call={contract} selectedCardSet={layout.selectedCardSet} />
                        </div>
                    )}
                    {!urlParams.objective && !!stake && (stake.strain === 'double' || stake.strain === 'redouble') && (
                        <div className="stake">
                            <Call key={`${stake.level}-${stake.strain}`} call={stake} selectedCardSet={layout.selectedCardSet} />
                        </div>
                    )}
                    {urlParams.objective && <div>BridgeWar</div>}
                </div>
            </div>
            <div className="trickCount">
                <span>
                    {t(`trickCount.label.ns`, {
                        nsCurr: ns,
                        nsOf: getOfTranslation('ns')
                    })}
                </span>
                <span>
                    {t(`trickCount.label.ew`, {
                        ewCurr: ew,
                        ewOf: getOfTranslation('ew')
                    })}
                </span>
            </div>
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TrickCount);
