import React, { FunctionComponent } from 'react';

import './loading.scss';
import { renderSuits } from '../../settings/settings';

interface ILoadingProps {}

export const Loading: FunctionComponent<ILoadingProps> = props => {
    return <section className="Loading">{renderSuits(1)}</section>;
};
