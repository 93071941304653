import axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Countdown } from '../_elements/countdown/countdown';
import { Loading } from '../_elements/loading/loading';
import Seat from '../_elements/seat/seat';
import { DisplayPosition, GamePhase, IGameResultsV2 } from '../_models/models';
import { getBridgePositionFromDisplayPosition, getDummyDisplayPosition, getGameResultModalConfig } from '../_utils/helper';
import { IRootState } from '../store/_root.reducer';
import { addModal, removeModal } from '../store/app.reducer';
import { IGameState, setGameResults, setGameResultsV2 } from '../store/game.reducer';

import './table.scss';
import TableSlot, { IClientSize } from './table-slot';
import Tricks from '../_elements/tricks/tricks';
import BiddingBox from '../_elements/bidding-box/bidding-box';
import AuctionBox from '../_elements/auction-box/auction-box';
import ManualDeal from '../_elements/manual-deal/manual-deal';
import TrickCount from '../_elements/trick-count/trick-count';
import Chat from '../_elements/chat/chat';
import Jitsi from '../_elements/video/jitsi';
import TableSymbol from '../_elements/table-symbol/table-symbol';
import Poll from '../_elements/poll/poll';

export interface ITableProps extends StateProps, DispatchProps {}

const Table: FunctionComponent<ITableProps> = props => {
    const { t } = useTranslation();
    const { game, app } = props;
    const { isDummyMode, layout, urlParams, hasOverSize, settings, poll } = app;
    const {
        auction,
        gamePhase,
        showTrickHistory,
        activeBridgePosition,
        iframe,
        jitsi,
        timeToTournament,
        metaData,
        seatData,
        gameResultsV2
    } = game;
    const { tableBackground, generalLayout, handLayout } = layout;
    const { showTableSlotBorders } = settings;
    const [showTimeToTournament, setShowTimeToTournament] = useState<boolean>(!!timeToTournament);
    const [clientSize, setClientSize] = useState<IClientSize | undefined>(undefined);

    const storeClientSize = () => {
        setClientSize({ w: window.innerWidth, h: window.innerHeight });
    };
    const handleBackButton = (event: PopStateEvent) => {
        console.log('Back button');
        event.preventDefault(); // Prevent the default behavior of the back button press
        event.stopPropagation();
        // Your custom code here
    };

    useEffect(() => {
        if (isDummyMode) {
            if (urlParams.gameResults) {
                /* const resulturl : string = urlParams.gameResultsV2
                                           ? `https://duplicate-games.s3.us-east-2.amazonaws.com/${urlParams.gameResultsV2}/game.json`
                                           : `https://api.emabridge.com/gameresult.php?uuid=${urlParams.gameResults}`;*/
                const resulturl: string = `https://duplicate-games.s3.us-east-2.amazonaws.com/${urlParams.gameResults}/game.json`;
                axios
                    .get(resulturl)
                    .then(response => {
                        const { data } = response;
                        //  console.log('Response ', data);
                        props.setGameResultsV2(data);
                        props.addModal({
                            ...getGameResultModalConfig(data, t, true),
                            isBlockUI: true
                        });
                    })
                    .catch(e => {
                        //S3 bucket failed, must be V1 game result , lets try it.
                        //console.log('S3 bucket failed Error', e, resulturl);
                        if (e.response.status === 403) {
                            axios
                                .get(`https://api.emabridge.com/gameresult.php?uuid=${urlParams.gameResults}`)
                                .then(response => {
                                    const { data } = response;
                                    //console.log('Response ', data);

                                    //Can you find the top name in the pairs data and set a flag top
                                    //Same for pn ( payer name ) and set 'isMe' flag to true.
                                    //those two pairs will become the default for the board review,
                                    // I'm checking to Gavin we might put them straight into board review.

                                    props.setGameResults(data);
                                    props.addModal({
                                        ...getGameResultModalConfig(data, t, false),
                                        isBlockUI: true
                                    });
                                })
                                .catch(e => {
                                    //V1 game result failed Forward to contact us.
                                    //console.log("Result URl Error", e, resulturl);
                                    window.location.href = 'https://www.thesharkbridgecompany.com/pages/contact-us';
                                });
                        }

                        //     window.location.href = 'https://thesharkbridgecompany.com';
                    });
            }
        }
        storeClientSize();
        window.addEventListener('resize', storeClientSize);
        window.addEventListener('popstate', handleBackButton);
    }, []);

    useEffect(() => {
        setShowTimeToTournament(!!timeToTournament);
    }, [timeToTournament]);

    useEffect(() => {
        if (showTimeToTournament) {
            showTimeToTournamentModal();
        } else {
            props.removeModal('timeToTournamentModal');
        }
    }, [showTimeToTournament]);

    const showTimeToTournamentModal = () => {
        const metaDataKeys: Array<keyof IGameState['metaData']> = ['eventName', 'hostName', 'round'];
        props.addModal({
            id: 'timeToTournamentModal',
            isBlockUI: true,
            header: (
                <>
                    {t('table.timeToTournament')}
                    <Countdown startTime={timeToTournament} onCountdownEnd={() => setShowTimeToTournament(false)} />
                </>
            ),
            body: metaDataKeys
                .filter((key: keyof IGameState['metaData']) => metaData[key])
                .map(key => (
                    <div key={`metaData-${key}`}>
                        <span>{t(`table.metaData.${key}.label`)}: </span>
                        <span>{metaData[key] === 'default' ? t(`table.metaData.${key}.default`) : metaData[key]}</span>
                    </div>
                ))
        });
    };

    const dummyDisplayPosition = getDummyDisplayPosition(seatData);

    const classes = [
        'Table',
        `useBackground-${tableBackground}`,
        `gamePhase-${gamePhase}`,
        `generalLayout-${generalLayout}`,
        `handLayout-${hasOverSize ? 'straight' : handLayout}`,
        dummyDisplayPosition ? `dummyIs-${dummyDisplayPosition}` : '',
        showTableSlotBorders && window.location.hostname === 'localhost' ? `showTableSlotBorders` : ''
    ];

    switch (true) {
        case isDummyMode:
            return <section className={classes.join(' ')} />;
        case gamePhase === GamePhase.PRE:
            return (
                <section className={classes.join(' ')}>
                    <div className="pleaseWait">
                        <Loading />
                        <div>{t('table.pleaseWait')}</div>
                    </div>
                </section>
            );
        default:
            return (
                <section className={classes.join(' ')}>
                    {gamePhase === GamePhase.DEAL && <ManualDeal seat={activeBridgePosition} />}
                    <TableSlot row="top" col="left" clientSize={clientSize}>
                        <>
                            {gamePhase === GamePhase.PLAY && <AuctionBox />}
                            {gamePhase === GamePhase.BID && <TableSymbol />}
                        </>
                    </TableSlot>
                    <TableSlot row="top" col="center" clientSize={clientSize}>
                        <Seat seat={getBridgePositionFromDisplayPosition(DisplayPosition.top, seatData)} />
                    </TableSlot>
                    <TableSlot row="top" col="right" clientSize={clientSize}>
                        <>
                            {iframe && <iframe className="customContent" src={iframe} />}
                            {jitsi.roomName && <Jitsi />}
                            {/*<Twitch />*/}
                        </>
                    </TableSlot>

                    <TableSlot row="middle" col="left" clientSize={clientSize}>
                        <Seat seat={getBridgePositionFromDisplayPosition(DisplayPosition.left, seatData)} />
                    </TableSlot>
                    <TableSlot row="middle" col="center" clientSize={clientSize}>
                        <>
                            {gamePhase === GamePhase.PLAY && <Tricks />}
                            {(gamePhase === GamePhase.BID || gamePhase === GamePhase.END) && <AuctionBox />}
                            {gamePhase === GamePhase.DEAL && auction && auction.length && <AuctionBox />}
                        </>
                    </TableSlot>
                    <TableSlot row="middle" col="right" clientSize={clientSize}>
                        <Seat seat={getBridgePositionFromDisplayPosition(DisplayPosition.right, seatData)} />
                    </TableSlot>

                    <TableSlot row="bottom" col="left" clientSize={clientSize}>
                        {poll ? <Poll poll={poll} /> : urlParams.screencast !== '' && <Chat />}
                    </TableSlot>
                    <TableSlot row="bottom" col="center" clientSize={clientSize}>
                        <Seat seat={getBridgePositionFromDisplayPosition(DisplayPosition.bottom, seatData)} />
                    </TableSlot>
                    <TableSlot row="bottom" col="right" clientSize={clientSize}>
                        <>
                            {gamePhase === GamePhase.BID && urlParams.screencast !== '' && <BiddingBox />}
                            {(gamePhase === GamePhase.PLAY || gamePhase === GamePhase.END) && <TrickCount />}
                        </>
                    </TableSlot>
                </section>
            );
    }
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {
    addModal,
    removeModal,
    setGameResults,
    setGameResultsV2
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
