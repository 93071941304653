import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { IRootState } from '../../store/_root.reducer';
import Trick from './trick';

import './tricks.scss';

export interface ITricksProps extends StateProps, DispatchProps {}

const Tricks: FunctionComponent<ITricksProps> = props => {
    const { game, app } = props;
    const { layout } = app;
    const { tricks, winner, showClosedTricks } = game;

    return (
        <section className={`Tricks ${showClosedTricks ? 'showClosedTricks' : 'hideClosedTricks'}`}>
            {tricks.map((trick, index) => (
                <Trick
                    key={`trick-${index}`}
                    trick={trick}
                    set={layout.selectedCardSet}
                    closed={!!winner[index]}
                    seatData={game.seatData}
                />
            ))}
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Tricks);
