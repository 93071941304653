import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../store/_root.reducer';
import { setSoundPlaying } from '../store/app.reducer';
//@ts-ignore
import tournamentStart from '../../assets/sounds/tournament-start-2.mp3';
//@ts-ignore
import yourTurn from '../../assets/sounds/your-turn-2.mp3';

export type SoundTypes = 'tournamentStart' | 'yourTurn';

export const soundTypes: Array<keyof SoundFiles> = ['yourTurn', 'tournamentStart'];

export type SoundFiles = {
    [key in SoundTypes]: string;
};

type Audios = {
    [key in SoundTypes]?: {
        url: string;
        audio: HTMLAudioElement;
    };
};

const soundFiles: SoundFiles = {
    tournamentStart,
    yourTurn
};

export interface IAudioPlayerProps extends StateProps, DispatchProps {}

const AudioPlayer: FunctionComponent<IAudioPlayerProps> = props => {
    const { app } = props;
    const { soundPlaying, settings } = app;
    const [audios, setAudios] = useState<Audios | undefined>(undefined);

    const getSoundFileKeysArray = (): Array<keyof SoundFiles> => {
        return Object.keys(soundFiles).map(_key => {
            return _key as keyof SoundFiles;
        });
    };

    useEffect(() => {
        const _audios: Audios = {};
        getSoundFileKeysArray().forEach(key => {
            const url = soundFiles[key];
            const audio = new Audio(url);
            audio.addEventListener('ended', () => {
                setSoundPlaying();
            });
            _audios[key] = { url, audio };
        });
        setAudios(_audios);
    }, []);

    useEffect(() => {
        if (!audios) {
            return;
        }
        getSoundFileKeysArray().forEach(key => {
            if (!audios[key]) {
                return;
            }
            //@ts-ignore
            audios[key].audio.pause();
            //@ts-ignore
            audios[key].audio.currentTime = 0;
        });
        if (soundPlaying && settings.sounds[soundPlaying]) {
            audios[soundPlaying]?.audio.play();
        }
    }, [soundPlaying]);

    return null;
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    app,
    game
});

const mapDispatchToProps = {
    setSoundPlaying
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);
