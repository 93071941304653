import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BridgePosition } from '../../_models/models';
import { getCardsOfSeat, getMySeatData } from '../../_utils/helper';
import { IRootState } from '../../store/_root.reducer';
import { addModal, removeModal } from '../../store/app.reducer';
import { setMyBridgePosition } from '../../store/game.reducer';
import { makeClaim, requestUndo } from '../../store/output.reducer';
import Hand from '../hand/hand';
import { Loading } from '../loading/loading';
import Tray from '../tray/tray';

import './seat.scss';

export interface ISeatProps extends StateProps, DispatchProps {
    seat?: BridgePosition;
}

const Seat: FunctionComponent<ISeatProps> = props => {
    const { t } = useTranslation();
    const [userVisible, setUserVisible] = useState(true);
    const { seat, game, app } = props;
    const { layout, settings } = app;
    const { cards, seatData, contract, isSpectator, tableId } = game;
    const { handLayout } = layout;

    if (!seatData || !seat) {
        return null;
    }

    const myBridgePosition = (getMySeatData(seatData) || {}).bridgePosition;

    if (!myBridgePosition) {
        return null;
    }

    const handleToggleShow = () => {
        setUserVisible(!userVisible);
    };

    const handleClaim = () => {
        const claims = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
        claims.splice(claims.length - props.game.winner.length);
        const modalId = 'makeClaimModal';
        props.addModal({
            id: modalId,
            noClickOutside: true,
            header: t('modal.claim.header'),
            body: [
                <p key="makeClaimModal-body">{t('modal.claim.body')}</p>,
                <div key="makeClaimModal-claims" className="claims">
                    {claims.map(claim => (
                        <button
                            key={`claim-${claim}`}
                            type="button"
                            onClick={() => {
                                props.makeClaim(claim);
                                // props.removeModal('makeClaimModal');
                                // props.addModal({
                                //   id: 'awaitClaimApproval',
                                //   header: t('modal.claim.awaitApproval.header', { claim }),
                                //   body: [<p>{t('modal.claim.awaitApproval.body')}</p>, <Loading />]
                                // });
                            }}
                        >
                            {claim}
                        </button>
                    ))}
                </div>
            ],
            className: 'makeClaimModal'
        });
    };

    const handleUndo = () => {
        const modalId = 'requestUndoModal';
        props.addModal({
            id: modalId,
            header: t('modal.undo.header'),
            body: [<div key="requestUndoModal-body">{t('modal.undo.body')}</div>],
            cancelButtonLabel: t('modal.no'),
            buttons: [
                {
                    label: t('modal.yes'),
                    primary: true,
                    onClick: () => {
                        props.requestUndo(true);
                        props.removeModal('requestUndoModal');
                        props.addModal({
                            id: 'awaitUndoApproval',
                            header: t('modal.undo.awaitApproval.header'),
                            body: [<p>{t('modal.undo.awaitApproval.body')}</p>, <Loading />],
                            isBlockUI: true
                        });
                    }
                }
            ],
            className: 'requestUndoModal'
        });
    };

    const currentSeatData = seatData[seat];
    const mySeatData = getMySeatData(seatData);
    if (!mySeatData) {
        return null;
    }
    const { bridgePosition, displayPosition } = currentSeatData;
    const hand = getCardsOfSeat(cards, bridgePosition, 'hand', layout.suitOrder, contract);
    const trayProps = {
        currentSeatData,
        isSpectator,
        tableId,
        onSetMyBridgePosition: props.setMyBridgePosition,
        onClaim: handleClaim,
        onUndo: handleUndo,
        ...(seatData ? { onToggleShow: handleToggleShow } : {})
    };
    const handProps = { hand, currentSeatData, game, userVisible };

    const classes = [
        'Seat',
        bridgePosition,
        displayPosition,
        seatData[bridgePosition].isDummy ? 'isDummy' : '',
        `handLayout-${handLayout}`
    ];

    return (
        <section className={classes.join(' ')}>
            <Hand {...handProps} />
            <Tray {...trayProps} onToggleShow={handleToggleShow} />
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {
    addModal,
    removeModal,
    makeClaim,
    requestUndo,
    setMyBridgePosition
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Seat);
