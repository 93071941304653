import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BridgePosition, Rank, ranks, Suit } from '../../_models/models';
import { getCardsOfSeat } from '../../_utils/helper';

import { IRootState } from '../../store/_root.reducer';

import './manual-deal.scss';
import { dealCard } from '../../store/output.reducer';
import { Card } from '../card/card';

interface IManualDealProps extends StateProps, DispatchProps {
    seat?: BridgePosition;
}

export const ManualDeal: FunctionComponent<IManualDealProps> = props => {
    const { app, game, seat } = props;
    const { layout } = app;
    const { cards } = game;
    const { selectedCardSet } = layout;

    const currentHandSize = seat ? getCardsOfSeat(cards, seat, 'hand', 0).length : undefined;

    const dealCard = (suit: Suit, rank: Rank) => () => {
        if (!seat || currentHandSize === undefined || currentHandSize >= 13 || cards[`${suit}.${rank}`].position !== 'deck') {
            return;
        }
        props.dealCard(suit, rank, seat);
    };

    const renderSuits = (suits: Suit[]) => {
        return (
            <div className="suits">
                {suits.map(suit => (
                    <div key={`${suit}`} className={`suit ${suit}`}>
                        {ranks.map(rank => {
                            return (
                                <div
                                    key={`${suit}-${rank}`}
                                    className={`card ${cards[`${suit}.${rank}`].bridgePosition}`}
                                    data-seat={`${cards[`${suit}.${rank}`].bridgePosition}`.substr(0, 1).toUpperCase()}
                                    onClick={dealCard(suit, rank)}
                                >
                                    {cards[`${suit}.${rank}`].position === 'deck' && (
                                        <Card isReallyVisible isVisible set={selectedCardSet} suit={suit} rank={rank} />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <section className={`ManualDeal ${seat} has-${currentHandSize}`}>
            {renderSuits([Suit.spades, Suit.hearts])}
            {renderSuits([Suit.diamonds, Suit.clubs])}
        </section>
    );
};

const mapStateToProps = ({ app, game }: IRootState) => ({
    app,
    game
});

const mapDispatchToProps = {
    dealCard
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManualDeal);
