import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import {pollVote} from '../../store/output.reducer';
import {IPoll} from '../../_models/models';

import './poll.scss';
import {setPoll} from '../../store/app.reducer';

export interface IChatProps extends DispatchProps {
    poll: IPoll
}

export const Poll: FunctionComponent<IChatProps> = props => {
    const { poll } = props;
    const { id, question, answers } = poll;

    const sendPoll = (index: number) => {
        props.pollVote(id, index);
        props.setPoll();
    }

    return (
        <section className={`Poll`}>
            <div className="question">{question}</div>
            <div className="answers">
                {
                    answers.map((answer, index) => (
                      <button className="answer" onClick={() => sendPoll(index)}>{answer}</button>
                    ))
                }
            </div>
        </section>
    );
};

const mapDispatchToProps = {
    pollVote,
    setPoll
};

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(Poll);
