import React from 'react';

import { ISet } from '../../../../_models/models';
import { ReactComponent as Biddings } from '../2/biddings.svg';
import ClubsJack from './clubs-jack.png';
import ClubsKing from './clubs-king.png';
import ClubsQueen from './clubs-queen.png';
import { ReactComponent as Clubs } from './clubs.svg';
import DiamondsJack from './diamonds-jack.png';
import DiamondsKing from './diamonds-king.png';
import DiamondsQueen from './diamonds-queen.png';
import { ReactComponent as Diamonds } from './diamonds.svg';
import HeartsJack from './hearts-jack.png';
import HeartsKing from './hearts-king.png';
import HeartsQueen from './hearts-queen.png';
import { ReactComponent as Hearts } from './hearts.svg';
import { ReactComponent as Ranks } from './ranks.svg';
import SpadesJack from './spades-jack.png';
import SpadesKing from './spades-king.png';
import SpadesQueen from './spades-queen.png';
import { ReactComponent as Spades } from './spades.svg';

export const set: ISet = {
    ranks: <Ranks />,
    biddings: <Biddings />,
    diamonds: {
        suits: <Diamonds />,
        king: DiamondsKing,
        queen: DiamondsQueen,
        jack: DiamondsJack
    },
    hearts: {
        suits: <Hearts />,
        king: HeartsKing,
        queen: HeartsQueen,
        jack: HeartsJack
    },
    spades: {
        suits: <Spades />,
        king: SpadesKing,
        queen: SpadesQueen,
        jack: SpadesJack
    },
    clubs: {
        suits: <Clubs />,
        king: ClubsKing,
        queen: ClubsQueen,
        jack: ClubsJack
    }
};
