import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';

import { GamePhase, HandLayout, ICard, ISeatData, Suit } from '../../_models/models';
import { possibleCardBacks } from '../../settings/options';
import { IRootState } from '../../store/_root.reducer';
import { setMyHighlightedCards } from '../../store/game.reducer';
import { dealCard, makePlay } from '../../store/output.reducer';
import { Card } from '../card/card';

import './hand.scss';
import { useTranslation } from 'react-i18next';

const dummyHand = [
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard',
    'dummyCard'
];

export interface IHandProps extends StateProps, DispatchProps {
    currentSeatData: ISeatData;
    hand: ICard[];
    userVisible: boolean;
    forceHandLayout?: HandLayout;
}

const Hand: FunctionComponent<IHandProps> = props => {
    const { t } = useTranslation();
    const [selectedCard, setSelectedCard] = useState<ICard | undefined>(undefined);
    const { app, currentSeatData, hand, game, userVisible } = props;
    const { layout, settings, hasOverSize } = app;
    const { activeBridgePosition, gamePhase, forceHideMe, myHighlightedCards } = game;
    const { isMe, isDummy, isVisible, dummyCards } = currentSeatData;
    const { selectedCardSet, handLayout, cardBack } = layout;

    const isReallyVisible = !(isMe && forceHideMe) && (gamePhase === GamePhase.DEAL || isMe || isDummy || (isVisible && userVisible));

    const handleClickCard = (card: ICard) => () => {
        if (gamePhase === GamePhase.DEAL) {
            props.dealCard(card.suit, card.rank, undefined);
        } else if (settings.interactionCard === 'confirm' && selectedCard !== card) {
            setSelectedCard(card);
        } else if (settings.interactionCard === 'instant' || selectedCard === card) {
            props.makePlay(card);
        }
    };

    const handlePrivateHighlight = (cardId: ICard['id']) => () => {
        props.setMyHighlightedCards(cardId);
    };

    const getHandLayoutClass = () => {
        const { forceHandLayout } = props;
        if (forceHandLayout) {
            return forceHandLayout;
        }
        if (hasOverSize) {
            return 'straight';
        }
        if (handLayout === 'stacked' && !isReallyVisible) {
            return 'fan';
        }
        return handLayout;
    };

    const classes = ['Hand', `set-${selectedCardSet}`, getHandLayoutClass(), `has-${hand.length}`];

    const renderHandWithCards = () => {
        return (
            <>
                {hand.map(card => {
                    const { rank, suit, id, raised } = card;
                    if (!rank || !suit) {
                        return null;
                    }
                    const cardProps = {
                        key: id,
                        set: selectedCardSet,
                        suit,
                        rank,
                        selected: selectedCard && selectedCard.id === card.id,
                        ...(activeBridgePosition === currentSeatData.bridgePosition ? { onClick: handleClickCard(card) } : {}),
                        onPrivateHighlight: handlePrivateHighlight(card.id),
                        isVisible,
                        isReallyVisible,
                        privateHighlighted: !!myHighlightedCards.find(_cardId => _cardId === card.id),
                        raised
                    };
                    return <Card {...cardProps} />;
                })}
                {!!dummyCards &&
                    dummyHand.slice(0, dummyCards).map((dummy, index) => {
                        const cardProps = {
                            key: `${dummy}-${index}`,
                            set: selectedCardSet,
                            suit: undefined,
                            rank: undefined,
                            selected: false,
                            isVisible: false,
                            isReallyVisible: false
                        };
                        return <Card {...cardProps} />;
                    })}
            </>
        );
    };

    const renderStackedHand = () => {
        return [Suit.spades, Suit.hearts, Suit.diamonds, Suit.clubs].map(suit => (
            <div key={suit} className={`suit ${suit}`}>
                {/*{[...hand, ...hand, ...hand, ...hand, ]*/}
                {hand
                    // .filter((card, index) => index < 13)
                    .filter(card => card.suit === suit)
                    .map((card, index) => {
                        const { rank, suit } = card;
                        if (!rank || !suit) {
                            return null;
                        }
                        // return <div onClick={handleClickCard(card)}>{index + 1}</div>;
                        return (
                            <div key={`${suit}-${card.id}`} className={card.raised ? 'raised' : ''} onClick={handleClickCard(card)}>
                                {t(`rank.short.${rank}`)}
                            </div>
                        );
                    })}
            </div>
        ));
    };

    const renderHandLayout = () => {
        const { forceHandLayout } = props;
        switch (true) {
            case forceHandLayout === 'stacked':
                return renderStackedHand();
            case forceHandLayout === 'fan':
            case forceHandLayout === 'straight':
                return renderHandWithCards();
            case forceHandLayout === 'columns':
                return null; // todo: columns still not layouted
            case handLayout === 'fan':
            case handLayout === 'straight':
            case !isReallyVisible:
                return renderHandWithCards();
            case handLayout === 'stacked':
                return renderStackedHand();
            case handLayout === 'columns':
                return null; // todo: columns still not layouted
            default:
                return null;
        }
    };

    return (
        <section className={classes.join(' ')} style={{ backgroundImage: `url(${possibleCardBacks[cardBack]})` }}>
            <div className="innerHand">{renderHandLayout()}</div>
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {
    makePlay,
    dealCard,
    setMyHighlightedCards
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Hand);
