import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Level, Strain, Suit } from '../../_models/models';
import { getCallValue, getHighestOfCalls, getMySeatData } from '../../_utils/helper';
import { IRootState } from '../../store/_root.reducer';
import { makeCall } from '../../store/output.reducer';
import { Call } from '../call/call';

import './bidding-box.scss';

export interface IBiddingBoxProps extends StateProps, DispatchProps {}

export const BiddingBox: FunctionComponent<IBiddingBoxProps> = props => {
    const { t } = useTranslation();
    const [selectedBid, setSelectedBid] = useState<string | undefined>(undefined);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
    const { game, app } = props;
    const { layout, settings } = app;
    const { biddings, auction, activeBridgePosition, seatData, doubleValid } = game;
    const { selectedCardSet } = layout;
    const { disabled } = biddings;
    const levels: Level[] = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];
    const strains: Strain[] = ['noTrump', Suit.spades, Suit.hearts, Suit.diamonds, Suit.clubs];
    const actions: Strain[] = ['double', 'redouble', 'pass', 'auctionAlert'];
    const myBridgePosition = (getMySeatData(seatData) || {}).bridgePosition;

    const makeCall = (level: Level, strain: Strain) => () => {
        if (strain === 'auctionAlert') {
            setAlert(!alert);
        } else if ((alert || settings.interactionBid === 'confirm') && selectedBid !== `${level}-${strain}`) {
            setSelectedBid(`${level}-${strain}`);
        } else if (settings.interactionBid === 'instant' || selectedBid === `${level}-${strain}`) {
            props.makeCall({ level, strain, alertMessage });
            setSelectedBid(undefined);
            setAlertMessage(undefined);
            setAlert(false);
        }
    };

    const handleCancel = () => {
        setSelectedBid(undefined);
        setAlertMessage(undefined);
        setAlert(false);
    };

    const highestCall = getHighestOfCalls(auction);
    const globalDisabled = disabled || activeBridgePosition !== myBridgePosition;
    return (
        <section className="BiddingBox">
            <div className="bids">
                {levels.map(level => (
                    <div key={`biddingRow-${level}`} className={`biddingRow ${level}`}>
                        {strains.map(strain => {
                            const disabledCell =
                                globalDisabled || (highestCall && getCallValue(highestCall) >= getCallValue({ level, strain }));
                            const props = {
                                key: `biddingCell-${level}-${strain}`,
                                className: [
                                    `biddingCell`,
                                    disabledCell ? 'disabled' : '',
                                    selectedBid === `${level}-${strain}` ? 'selected' : ''
                                ].join(' '),
                                ...(disabledCell ? {} : { onClick: makeCall(level, strain) })
                            };
                            return (
                                <div {...props}>
                                    <Call {...{ call: { level, strain }, selectedCardSet }} />
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
            <div className={`biddingActions doubleValid-${doubleValid}`}>
                <div>
                    {actions.map(strain => {
                        const actionDisabled = globalDisabled || (strain === 'double' && doubleValid === 1);
                        const props = {
                            key: `biddingCell-${strain}`,
                            className: [
                                `biddingCell`,
                                actionDisabled ? 'disabled' : '',
                                strain,
                                selectedBid === `zero-${strain}` ? 'selected' : '',
                                strain === 'auctionAlert' && alert ? 'highlighted' : ''
                            ].join(' '),
                            ...(actionDisabled ? {} : { onClick: makeCall('zero', strain) })
                        };
                        return (
                            <div {...props}>
                                <Call {...{ call: { level: 'zero', strain }, selectedCardSet }} />
                            </div>
                        );
                    })}
                </div>
            </div>
            {(alert || settings.interactionBid === 'confirm') && !!selectedBid && (
                <div className="selectedBid">
                    <Call
                        {...{
                            call: { level: selectedBid.split('-')[0] as Level, strain: selectedBid.split('-')[1] as Strain },
                            selectedCardSet
                        }}
                    />
                    <div className="confirmCancel">
                        <button className="cancel" onClick={handleCancel} />
                        <button
                            className="confirm"
                            onClick={makeCall(selectedBid.split('-')[0] as Level, selectedBid.split('-')[1] as Strain)}
                        />
                    </div>
                    {alert && (
                        <div className="alertMessage">
                            <label>{t('biddingBox.alertMessage')}</label>
                            <input type="text" value={alertMessage} onChange={e => setAlertMessage(e.target.value)} />
                        </div>
                    )}
                </div>
            )}
        </section>
    );
};

const mapStateToProps = ({ game, app }: IRootState) => ({
    game,
    app
});

const mapDispatchToProps = {
    makeCall
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BiddingBox);
