import { combineReducers } from 'redux';

import example, { IExampleState } from './example.reducer';
import game, { IGameState } from './game.reducer';
import app, { IAppState } from './app.reducer';
import output, { IOutputState } from './output.reducer';

export interface IRootState {
    readonly example: IExampleState;
    readonly game: IGameState;
    readonly app: IAppState;
    readonly output: IOutputState;
}

const _rootReducer = combineReducers<IRootState>({
    example,
    game,
    app,
    output
});

const rootReducer = (state: any, action: any) => {
    return _rootReducer(state, action);
};

export default rootReducer;
