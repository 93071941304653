import {
    BridgePosition,
    CardId,
    DisplayPosition,
    GamePhase,
    ICard,
    ICards,
    initialBaseSeatData,
    Rank,
    Suit,
    Vulnerability
} from '../app/_models/models';
import { ForeignBoardReviewData, ForeignBoardReviewPlayerData } from '../app/store/game.reducer';
import {
    convertIntToBid,
    convertIntToBridgePosition,
    convertIntToVulnerabilty,
    convertStringToBid,
    convertSuitRank
} from './game-engine-helper';
import { getInitialCards } from '../app/_utils/initialHelper';

interface IS3Player {
    pn: string;
}

interface IS3TrickCard {
    p: number;
    c: CardId;
    w: boolean;
}

interface IS3Board {
    auction: string;
    dealer: number;
    eh: string[];
    ewtr: number;
    nh: string[];
    nstr: number;
    play: IS3TrickCard[];
    sh: string[];
    vuln: number;
    wh: string[];
    traveler: {
        c: number;
        d: number;
    };
}

interface IS3Boards {
    [key: string]: IS3Board;
}

interface IS3MyResults {
    boards: IS3Boards;
    p1: IS3Player;
    p2: IS3Player;
    ruMP: number;
    rMP: number;
    ruIMP: number;
    rIMP: number;
    username: string;
    uuid: string;
}

export interface IS3BoardData {
    myresults: IS3MyResults;
}

export const convertS3toForeignBoardReviewData = (s3data: IS3BoardData, bn: number, index: number): ForeignBoardReviewData => {
    //   console.log("S3 Data: ", s3data)
    const cards: ICards = getInitialCards();
    const s3board: IS3Board = s3data.myresults.boards[(bn - 1).toString()] as IS3Board;
    if (!s3board) {
        return {} as ForeignBoardReviewData;
    }
    //  console.log("S3 s3board: ", s3board)
    //  console.log("S3 Data North: ", s3board.nh)
    //Assign all cards from hands
    s3board.nh.forEach(card => {
        const c: ICard = cards[convertSuitRank(card)];
        c.bridgePosition = BridgePosition.north;
        c.position = 'hand';
    });
    s3board.eh.forEach(card => {
        const c: ICard = cards[convertSuitRank(card)];
        c.bridgePosition = BridgePosition.east;
        c.position = 'hand';
    });
    s3board.sh.forEach(card => {
        const c: ICard = cards[convertSuitRank(card)];
        c.bridgePosition = BridgePosition.south;
        c.position = 'hand';
    });
    s3board.wh.forEach(card => {
        const c: ICard = cards[convertSuitRank(card)];
        c.bridgePosition = BridgePosition.west;
        c.position = 'hand';
    });

    //Add one player data
    const playerData: ForeignBoardReviewPlayerData = convertS3toForeignBoardReviewPlayerData(s3board, cards, s3data.myresults.uuid);
    //Set the auction

    const result: ForeignBoardReviewData = {
        cards: cards,
        seatData: {
            north: {
                ...initialBaseSeatData,
                bridgePosition: BridgePosition.north,
                displayPosition: DisplayPosition.top,
                isDealer: s3board.dealer === 0,
                isDeclarer: s3board.traveler.d === 0,
                isMe: false,
                isVisible: true
            },
            east: {
                ...initialBaseSeatData,
                bridgePosition: BridgePosition.east,
                displayPosition: DisplayPosition.right,
                isDealer: s3board.dealer === 1,
                isDeclarer: s3board.traveler.d === 1,
                isMe: false,
                isVisible: true
            },
            south: {
                ...initialBaseSeatData,
                bridgePosition: BridgePosition.south,
                displayPosition: DisplayPosition.bottom,
                isDealer: s3board.dealer === 2,
                isDeclarer: s3board.traveler.d === 2,
                isMe: true,
                isVisible: true
            },
            west: {
                ...initialBaseSeatData,
                bridgePosition: BridgePosition.west,
                displayPosition: DisplayPosition.left,
                isDealer: s3board.dealer === 3,
                isDeclarer: s3board.traveler.d === 3,
                isMe: false,
                isVisible: true
            }
        },
        contract: convertStringToBid('1s'),
        gamePhase: GamePhase.END,
        vulnerability: convertIntToVulnerabilty(s3board.vuln),
        showAuctionBox: true,
        boardStats: [],
        myBoardStatKeys: {
            r: 0,
            s: 0,
            c: 0,
            d: 0,
            x: 0,
            l: 0
        },
        playerData: [playerData]
    };
    return result;
};

export const convertS3toForeignBoardReviewPlayerData = (s3board: IS3Board, cards: ICards, uuid: string): ForeignBoardReviewPlayerData => {
    //Add one player data
    const playerData: ForeignBoardReviewPlayerData = {
        auction: [],
        tricks: [],
        winner: [],
        uuid: uuid,
        declarer: undefined
    } as ForeignBoardReviewPlayerData;
    //Set the auction

    const auctionArray = s3board.auction.split(' ');
    auctionArray.forEach(call => {
        playerData.auction.push(convertStringToBid(call.toLowerCase()));
    });

    //Set the trick history
    const s3Tricks: IS3TrickCard[] = s3board.play;
    let playsCount: number = 0;
    let trickCount: number = 0;
    playerData.tricks = [];
    s3Tricks.forEach(card => {
        playsCount++;
        trickCount = Math.ceil(playsCount / 4) - 1;
        if (!playerData.tricks[trickCount]) playerData.tricks[trickCount] = [];
        playerData.tricks[trickCount].push(cards[convertSuitRank(card.c.toUpperCase())]);
        if (card.w) {
            playerData.winner.push(convertIntToBridgePosition(card.p));
        }
    });

    playerData.declarer = convertIntToBridgePosition(s3board.traveler.d);

    return playerData;
};
